import React, { useState, useEffect, useRef } from 'react';
import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { Calendar as SelCalendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import axios from 'axios'
import 'primeflex/primeflex.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useGlobalState, { g_user, API_Root } from "../globalState";
import moment from 'moment';
import {
   Calendar,
   momentLocalizer,
} from 'react-big-calendar';

export const PorterSchedule = () => {
   const [noSunday, setNoSunday] = useState();
   const [noMonday, setNoMonday] = useState();
   const [noTuesday, setNoTuesday] = useState();
   const [noWednesday, setNoWednesday] = useState();
   const [noThursday, setNoThursday] = useState();
   const [noFriday, setNoFriday] = useState();
   const [noSaturday, setNoSaturday] = useState();
   const [init, setInit] = useState(false);
   const [schedDate, setSchedDate] = useState();
   const [schedDates, setSchedDates] = useState();
   const [events, setEvents] = useState(undefined);
   const [calDate, setCalDate] = useState(new Date());
   const [showSingleDate, setShowSingleDate] = useState(false);
   const [showMultiDate, setShowMultiDate] = useState();

   const toast = useRef(null);
   const [user] = useGlobalState(g_user);
   const localizer = momentLocalizer(moment);

   useEffect(() => {
      if (!init) {
         setInit(true);
         getSettings();
         getPorterSchedule(new Date());
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getPorterSchedule = (date) => {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var apiUrl = `${API_Root}api/user/GetPorterScheduleForMonth?uid=${user.user.id}&year=${y}&month=${m}`;
      axios.get(apiUrl).then((values) => {
         console.log('events', values.data);
         setEvents(values.data);
      });
   }

   const addSingleDate = () => {
      setShowSingleDate(false);
      var apiUrl = `${API_Root}api/user/AddPorterSchedule?uid=${user.user.id}&startDate=${schedDate.toLocaleDateString()}&endDate=${schedDate.toLocaleDateString()}`;
      axios.get(apiUrl).then((values) => {
         console.log('AddPorterSchedule', values.data);
         getPorterSchedule(calDate);
      });
   }

   const addMultiDate = () => {
      setShowMultiDate(false);
      var apiUrl = `${API_Root}api/user/AddPorterSchedule?uid=${user.user.id}&startDate=${schedDates[0].toLocaleDateString()}&endDate=${schedDates[1].toLocaleDateString()}`;
      axios.get(apiUrl).then((values) => {
         console.log('AddPorterSchedule', values.data);
         getPorterSchedule(calDate);
      });
   }

   const deleteScheduleEvent = (id) => {
      var apiUrl = `${API_Root}api/user/DeletePorterSchedule?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('DeletePorterSchedule', values.data);
         getPorterSchedule(calDate);
      });
   }

   const getSettings = () => {
      var apiUrl = `${API_Root}api/user/GetPorterSettings?uid=${user.user.id}`;
      axios.get(apiUrl).then((values) => {
         console.log('GetPorterSettings', values.data);
         var settings = values.data;

         if (settings !== null) {
            setNoSunday(settings.noSunday);
            setNoMonday(settings.noMonday);
            setNoTuesday(settings.noTuesday);
            setNoWednesday(settings.noWednesday);
            setNoThursday(settings.noThursday);
            setNoFriday(settings.noFriday);
            setNoSaturday(settings.noSaturday);
         }
      });
   }

   const saveSettings = () => {
      var dto = {
         userId: user.user.id,
         noSunday: noSunday,
         noMonday: noMonday,
         noTuesday: noTuesday,
         noWednesday: noWednesday,
         noThursday: noThursday,
         noFriday: noFriday,
         noSaturday: noSaturday
      };

      var apiUrl = `${API_Root}api/user/SavePorterSettings`;
      axios.post(apiUrl, dto).then(() => {
         console.log('SavePorterSettings');
         getPorterSchedule(calDate);
      });

   }

   //Clicking an existing event allows you to remove it
   const onSelectEvent = (pEvent) => {
      if (pEvent.isOut) {
         const r = window.confirm("Would you like to remove this date to make it available to schedule?")
         if (r === true) {
            deleteScheduleEvent(pEvent.scheduleId);
         }
      }
   }

   return (
      <>
         <Toast ref={toast} />
         <div className="grid">
            <div className="col-7">
               <Panel header="Scheduling Rules" collapsed toggleable>
                  <div className="w-full">
                     Not available to be scheduled for trips on the following weekdays
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="sunday" onChange={e => setNoSunday(e.checked)} checked={noSunday} />
                     <label htmlFor="sunday" className="ml-2">Sunday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Monday" onChange={e => setNoMonday(e.checked)} checked={noMonday} />
                     <label htmlFor="Monday" className="ml-2">Monday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Tuesday" onChange={e => setNoTuesday(e.checked)} checked={noTuesday} />
                     <label htmlFor="Tuesday" className="ml-2">Tuesday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Wednesday" onChange={e => setNoWednesday(e.checked)} checked={noWednesday} />
                     <label htmlFor="Wednesday" className="ml-2">Wednesday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Thursday" onChange={e => setNoThursday(e.checked)} checked={noThursday} />
                     <label htmlFor="Thursday" className="ml-2">Thursday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Friday" onChange={e => setNoFriday(e.checked)} checked={noFriday} />
                     <label htmlFor="Friday" className="ml-2">Friday</label>
                  </div>
                  <div className="flex align-items-center">
                     <Checkbox inputId="Saturday" onChange={e => setNoSaturday(e.checked)} checked={noSaturday} />
                     <label htmlFor="Saturday" className="ml-2">Saturday</label>
                  </div>
                  <div className="w-full text-right">
                     <Button label="Save" icon="pi pi-save" onClick={() => saveSettings()} />
                  </div>
               </Panel>
            </div>
            <div className="col-5">
               <Panel header="Unavailable Dates" collapsed toggleable>
                  <div className="w-full">
                     Set specific dates that you are not available to be scheduled
                  </div>
                  <div className="w-full text-center mt-4">
                     <Button label="Add Single Date" icon="pi pi-calendar-plus" onClick={() => setShowSingleDate(true)} />
                  </div>
                  <div className="w-full text-center mt-4">
                     <Button label="Add Date Range" icon="pi pi-calendar-plus" onClick={() => setShowMultiDate(true)} />
                  </div>
              </Panel>
            </div>
         </div>
         <div className="h-30rem bg-white border-round-md">
            <Calendar
               defaultDate={calDate}
               events={events}
               localizer={localizer}
               onNavigate={(d) => { setCalDate(d); getPorterSchedule(d); }}
               step={60}
               views={['month']}
               eventPropGetter={(event) => {
                  const opacity = event.isBusy ? '.8' : event.isByRule ? '.5' : '.5';
                  const backgroundColor = event.isBusy ? 'MediumSeaGreen' : event.isByRule ? 'Gray' : 'LightCoral';
                  return {
                     style: { backgroundColor }, opacity: { opacity } }
               }}
               onSelectEvent={(event) => onSelectEvent(event)}
            />
         </div>
         <Dialog header="Add Single Unavailable Date" visible={showSingleDate} style={{ width: '40vw' }} onHide={() => setShowSingleDate(false)}>
            <div>Select a single date to add to your schedule as unavailable.</div>
            <div className="w-full"><SelCalendar className="w-full" value={schedDate} onChange={(e) => setSchedDate(e.value)} showButtonBar inline /></div>
            <div className="w-full text-right"><Button label="Save" icon="pi pi-save" onClick={() => addSingleDate()} /></div>
         </Dialog>
         <Dialog header="Add Unavailable Date Range" visible={showMultiDate} style={{ width: '40vw' }} onHide={() => setShowMultiDate(false)}>
            <div>Select the start and end of the date range to add to your schedule as unavailable.</div>
            <div className="w-full"><SelCalendar className="w-full" value={schedDates} onChange={(e) => setSchedDates(e.value)} showButtonBar inline selectionMode="range" /></div>
            <div className="w-full text-right"><Button label="Save" icon="pi pi-save" onClick={() => addMultiDate()} /></div>
         </Dialog>
      </>
   );
               //view='month'
}