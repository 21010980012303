import React from 'react';
import 'primeflex/primeflex.css';
import './PrintTrip.css';
import logo from '../logo.png';
import { API_Root } from "../globalState";
import axios from 'axios'
import { Card } from 'primereact/card';

export class PrintTrip extends React.PureComponent {

   constructor(props) {
      super(props);
      this.state = {
         charges: undefined,
         images: []
      }
      this.getCharges();
      this.getImages();
   }

   getCharges = () => {
      var apiUrl = `${API_Root}api/trip/GetTripCharges?tripId=${this.props.trip.id}`;
      axios.get(apiUrl).then((values) => {
         console.log('charges', values.data);
         this.setState({ charges: values.data });
      });
   }

   getImages = () => {
      var apiUrl = `${API_Root}api/trip/GetTripImagesByUser?tripId=${this.props.trip.id}`;
      axios.get(apiUrl).then((values) => {
         this.setState({ images: values.data });
      });
   }

   formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   }

   splitOnCR = (s) => {
      if (!s || (s && s.trim().length === 0)) {
         return [];
      }

      return s?.split(/\r?\n/);
   }

   splitVehicleNotes = (s) => {
      return this.splitOnCR(s)?.map((n, i) => {
         return (<div key={i} className="w-full"><span className="ml-4">{n}</span></div>);
      })
   }

   numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   getDriverInfo = (veh) => {
      console.log('veh', veh);

      let status = '';
      if (veh.actualMiles && veh.completedDateTime) {
         status = `Completed with ${this.numberWithCommas(veh.actualMiles)} miles at ${this.formatDate(veh.completedDateTime)} `;
      }
      else if (veh.actualDepartDateTime) {
         status = `Departed source location at ${this.formatDate(veh.actualDepartDateTime)}`;
      }
      else if (veh.confirmedDate) {
         status = `Confirmed the trip at ${this.formatDate(veh.confirmedDate)}`;
      }
      else {
         status = "Trip has not been confirmed yet";
      }

      if (veh.User) {
      return (<>
         <div className="w-full mt-1">
            {status}
         </div>
         <div className="grid mt-1">
            <div className="col-3 font-bold m-0 p-0">Driver:</div>
            <div className="col-5 m-0 p-0">{veh.User?.firstName} {veh.User?.lastName}</div>
            <div className="col-4 m-0 p-0">{veh.User?.phone}</div>
         </div>
         <div className="grid m-0 p-0">
            <div className="col-3 m-0 p-0"></div>
            <div className="col-5 m-0 p-0">{veh.User?.streetAddress}</div>
            <div className="col-4 m-0 p-0">{veh.User?.email}</div>
         </div>
         <div className="grid m-0 p-0">
            <div className="col-3 m-0 p-0"></div>
            <div className="col-5 m-0 p-0">{veh.User?.city} {veh.User?.stateAbbr} {veh.User?.zipCode}</div>
            <div className="col-4 m-0 p-0"></div>
         </div>
         </>);
      }
      else {
         return (<div className="w-full mt-1">
            No driver assigned
         </div>);
      }
   }

   getUserFiles = (uf, i) => {
      if (uf && uf.user && uf.files && uf.files.length > 0) {
         return (
            <div className="w-full" key={i}>
               <div className="w-full font-bold">
                  Files uploaded by {uf.user.firstName} {uf.user.lastName}
               </div>
               {uf.files.map((f, i) => {
                  let amt = f.DollarAmount > 0 ? <span className="font-bold mr-2">${f.DollarAmount}</span> : <></>;

                  return (
                     <div className="m-2" key={i}>
                        <div style={{ width: "600px", height: "600px", backgroundImage: "url('" + f.contents.replace(/(\r\n|\n|\r)/gm, "") + "')", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
                        <div className="w-full">
                           {amt} {f.fileDescription}
                        </div>
                     </div>);
               })}
            </div>
         );
      }
      else {
         return (<></>);
      }
   }

   formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   getChargeSection = () => {
      if (this.state.charges) {
         return (
            <>

               <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
                  Fees & Expenses
               </div>
                  {
                     this.state.charges.vehicles.map((v, i) => {
                        return (
                           <div className="w-full mt-2" key={i}>
                              {v.vehicles.map((veh, k) => {
                                 if (veh.vehicleVIN || veh.vehicleMake || veh.vehicleModel) {
                                    return (<p key={k}><span className="font-bold text-500 font-italic">{veh.vehicleVIN}</span> -
                                       <span className="text-500 font-italic">{veh.vehicleYear} {veh.vehicleMake} {veh.vehicleModel}</span></p>);
                                 }
                                 else {
                                    return (<p key={k}><span className="font-bold text-500 font-italic">Unspecified Vehicle</span></p>);
                                 }
                              })}
                              {v.charges.map((c, j) => {
                                 return (
                                    <div className="w-full grid" key={j}>
                                       <div className="col-6">
                                          {c.description}
                                       </div>
                                       <div className="col-6 text-left">
                                          {this.formatter.format(c.amount)}
                                       </div>
                                    </div>
                                    );
                              })}
                              <div className="w-full grid" key={i}>
                                 <div className="col-6 font-bold">
                                    Vehicle Total
                                 </div>
                                 <div className="col-6 text-left font-bold">
                                    {this.formatter.format(v.total)}
                                 </div>
                              </div>

                           </div>);
                     })}
               <div className="w-full grid mt-2">
                  <div className="col-6 font-bold text-lg">
                     Trip Total
                  </div>
                  <div className="col-6 text-left font-bold text-lg">
                     {this.formatter.format(this.state.charges.total)}
                  </div>
               </div>

            </>);
      }
      else {
         return (<></>);
      }
   }

   getUploadSection = () => {
      if (this.state.images && this.state.images.length > 0) {
         return (<>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Driver Uploads
            </div>
            <div className="w-full">
               {this.state.images.map((f, i) => {
                  return (this.getUserFiles(f, i));
               })};
            </div>
         </>);
      }
      else {
         return (<></>);
      }
   }

   getVehicleType = (v) => {
      let rtn = '';
      if (v.VehicleType) {
         rtn = `(${v.VehicleType.typeName})`;
      }
      return rtn;
   }

   render() {
      return (
         <div className="p-4">
            <div className="w-full mb-4 mt-4">
               <div className="grid">
                  <div className="col-3">
                     <img src={logo} className="h-3rem" style={{ objectFit: "scale-down" }} />
                  </div>
                  <div className="col-9">
                     <div className="w-full text-3xl text-center font-bold">
                        Trip Order
                     </div>
                     <div className="w-full text-2xl text-center font-semibold">
                        {this.props.trip.Dealership.dealershipName}
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Schedule
            </div>
            <div className="grid">
               <div className="col-12">
                  <div className="w-full">
                     <div className="w-full"><span className="font-semibold mr-2">Requested Delivery:</span>{this.formatDate(this.props.trip.requestedDeliveryDateTime)} - {this.formatDate(this.props.trip.requestedDeliveryDateTimeEnd)}</div>
                     <div className="w-full"><span className="font-semibold mr-2">Estimated Departure:</span>{this.formatDate(this.props.trip.departDateTimeStart)} - {this.formatDate(this.props.trip.departDateTimeEnd)}</div>
                  </div>
               </div>
            </div>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Vehicles
            </div>
            {this.props.trip.TripVehicles.map((v, i) => {
               if (v.vehicleVIN || v.vehicleMake || v.vehicleModel) {
                  return (<Card key={i} className="mt-2 pt-0"><div className=" mt-0 pt- 0 w-full">
                     <span className="font-bold">{v.vehicleVIN}</span> - {v.vehicleYear} {v.vehicleMake} {v.vehicleModel} {this.getVehicleType(v)}
                  </div>
                     { this.getDriverInfo(v)}
                  </Card>);
               }
               else {
                  return (<Card key={i} className="mt-2 pt-0"><div className=" mt-0 pt- 0 w-full">
                     <span className="font-bold">Unspecified Vehicle</span> - {this.getVehicleType(v)}
                  </div>
                     { this.getDriverInfo(v)}
                  </Card>);               }
            })}

            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Route
            </div>
            <div className="grid">
               <div className="col-5">
                  <div className="w-full">
                     <div className="w-full">{this.props.trip.Dealership2.dealershipName ?? "Private address"}</div>
                     <div className="w-full">{this.props.trip.Dealership2.streetAddress}</div>
                     <div className="w-full">{this.props.trip.Dealership2.city} {this.props.trip.Dealership2.stateAbbr} {this.props.trip.Dealership2.zipCode}</div>
                  </div>
               </div>
               <div className="col-2 text-center vertical-align-middle">
                  <div className="w-full">
                     <div className="text-center w-full"><i className="pi pi-arrow-right text-2xl" style={{ color: 'slateblue' }}></i></div>
                     <div className="text-center w-full">{this.props.trip.estimatedDistance}</div>
                     <div className="text-center w-full">{this.props.trip.estimatedDuration}</div>
                  </div>
               </div>
               <div className="col-5 pl-4">
                  <div className="w-full ml-4">
                     <div className="w-full">{this.props.trip.Dealership1.dealershipName ?? "Private address"}</div>
                     <div className="w-full">{this.props.trip.Dealership1.streetAddress}</div>
                     <div className="w-full">{this.props.trip.Dealership1.city} {this.props.trip.Dealership1.stateAbbr} {this.props.trip.Dealership1.zipCode}</div>
                  </div>
               </div>
            </div>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Porter Notes
            </div>
            <div className="grid">
               <div className="col-12">
                  <div className="w-full">
                     <ul>
                        {this.props.trip.TripPorterNotes?.map((n, i) => {
                           return (<li key={i}>{n.PorterNotesLookup.note}</li>);
                        })
                        }
                        {this.splitOnCR(this.props.trip.porterNotes)?.map((n, i) => {
                           return (<li key={"custom" + i}>{n}</li>);
                        })
                        }
                     </ul>
                  </div>
               </div>
            </div>
            {this.getChargeSection()}
            {this.getUploadSection()}

            <div className="mt-6 grid w-full">
               <div className="col-3 text-right pr-3">Dealership Representative:</div>
               <div className="col-5 border-solid border-bottom-1 border-top-none border-right-none border-left-none"></div>
               <div className="col-1 text-right pr-3">Date:</div>
               <div className="col-3 border-solid border-bottom-1 border-top-none border-right-none border-left-none"></div>
            </div>
            <div className="mt-6 grid w-full">
               <div className="col-3 text-right pr-3">Driver:</div>
               <div className="col-5 border-solid border-bottom-1 border-top-none border-right-none border-left-none"></div>
               <div className="col-1 text-right pr-3">Date:</div>
               <div className="col-3 border-solid border-bottom-1 border-top-none border-right-none border-left-none"></div>
            </div>
         </div>
      );
   }
}