import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenuBar } from './NavMenuBar';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenuBar />
        <Container className="mainContainer">
          {this.props.children}
        </Container>
      </div>
    );
  }
}
