import axios from 'axios';

const axiosInterceptor = axios.interceptors.request.use(function (config) {
   let timezoneOffset = (new Date()).getTimezoneOffset();

   config.headers.Authorization = 'c37aa337-212d-4ab3-adb4-c1c9e96af6dc';
   config.headers["tzo"] = timezoneOffset;

   return config;
});
export default axiosInterceptor;