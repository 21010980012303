import React, { Component } from 'react';
import './Home.css';
import img1 from '../home1.jpg';

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (<>
      <div className="main-frame">
       </div>

      <div className="content-frame">
          <div className="w-full ml-2 md:ml-8 text-3xl font-bold"><a href="mailto:info@mozologistics.com">Contact us</a> to find out how we can revolutionize your inventory transportation</div>

          <div className="w-full mt-3 md:mt-5 ml-2 md:ml-8 grid">
             <div className="hidden md:inline md:col-4 p-4">
                <img className="w-full hidden md:inline" alt="Deliver" src={img1} />
             </div>
             <div className="lg:col-8 md:col-12 text-2xl">
                <ul className="line-height-4">
                   <li>Save time with a simplified trip <span className="font-bold">order entry process</span></li>
                   <li>Stay engaged with your drivers using <span className="font-bold">mobile notifications</span></li>
                   <li>Save money and time with <span className="font-bold">paperless records</span></li>
                   <li><span className="font-bold">Consolidated billing</span> simplifies the payment process for everyone</li>
                   <li>The Mozo <span className="font-bold">mobile app</span> allows drivers to stay updated and organized (available on both Android and iOS)</li>
                   <li>Dynamic <span className="font-bold">cost calculation</span> increases transparency and reduces guesswork</li>
                </ul>
             </div>
          </div>

          <div className="w-full m-2 md:m-8 text-2xl text-center">
             <a href="mailto:info@mozologistics.com">Contact us</a> to find out more!
          </div>
       </div>
    </>
    );
  }
}
