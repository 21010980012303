import React, { useState, useRef } from 'react';
import { Card } from 'primereact/card';
import axios from 'axios'
import 'primeflex/primeflex.css';
import '../auth/Login.css';
import useGlobalState, { g_user, API_Root } from "../globalState";
import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInterceptor from '../AuthIntercept';
import { useHistory } from "react-router-dom";

export const UserConfirm = (props) => {
   const [init, setInit] = useState(false);
   const [user, setUser] = useGlobalState(g_user);
   const history = useHistory();

   if (!init) {
      setInit(true);
      const query = new URLSearchParams(props.location.search);
      const token = query.get('token');

      var apiUrl = `${API_Root}api/user/ConfirmUser?key=${token}`;

      axios.get(apiUrl).then((values) => {
         console.log('login', values.data);
         var success = values.data != null;
         if (success) {
            setUser(values.data);
            history.push('/');
         }
      });
   }

   return (<Card>
      <div className="grid">
         <div className="col-4"></div>
         <div className="col-4 w-full text-center mt-4">Confirming email address...</div>
         <div className="col-4 text-left"><ProgressSpinner /></div>
      </div>
   </Card>);

}