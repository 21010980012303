import React from 'react';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import Compressor from 'compressorjs';
import axios from 'axios'
import 'primeflex/primeflex.css';
import { API_Root } from "../../globalState";

export class TripFiles extends React.Component {

   constructor(props) {
      super(props);
      this.state = { images: [] }
      this.getImages(this.props.tripId, this.props.userId);
      this.fileupload = React.createRef(null);
   }

   getImages = (t, u) => {
      var apiUrl = `${API_Root}api/trip/GetTripImagesForUser?tripId=${t}&userId=${u}`;
      axios.get(apiUrl).then((values) => {
         console.log('images', values.data);
         var success = values.data != null;
         if (success) {
            this.setState({ images: values.data });
         }
      });
   }

   deleteImage = (i) => {
      var file = this.state.images[i];
      var apiUrl = `${API_Root}api/trip/DeleteTripImage?id=${file.id}`;
      axios.get(apiUrl).then((values) => {
         let newArr = [...this.state.images];
         newArr.splice(i, i);
         this.setState({ images: newArr });
      });
   }

   //const formatDate = (dt) => {
   //   var d = new Date(dt);
   //   return d.toLocaleDateString('en-US', {
   //      day: '2-digit',
   //      month: '2-digit',
   //      year: 'numeric',
   //      hour: '2-digit',
   //      minute: '2-digit',
   //      hour12: true
   //   });
   //};

   onImageUpload = async (event) => {
      const file = event.files[0];
      var uid = this.props.userId;
      var tid = this.props.tripId;
      var reload = this.getImages;

      new Compressor(file, {
         quality: 0.6,
         maxHeight: 400,
         success(result) {
            const reader = new FileReader();
            reader.onload = (e) => {
               let dto = {
                  userId: uid,
                  tripId: tid,
                  fName: file.name,
                  contents: reader.result
               };

               var apiUrl = `${API_Root}api/trip/SaveTripImage`;
               axios.post(apiUrl, dto).then((values) => {
                  reload(tid, uid);
               });
            };
            reader.readAsDataURL(result);
         },
         error(err) {
            console.log(err.message);
         },
      });

      this.fileupload.clear();
   }

   render() {
      return (<div className="mt-4">
         <FileUpload
            ref={ref => { this.fileupload = ref }}
            mode="basic"
            name="image"
            accept="image/*"
            customUpload
            auto
            uploadHandler={this.onImageUpload}
            chooseLabel="Upload File"
            maxFileSize={10000000} />
         <Divider />
         {this.state.images?.map((f, i) => {
            return (<div key={i} className="w-full flex mt-2">
               <div className="mr-2 relative"
                  style={{ width: "350px", height: "350px", backgroundImage: "url('" + f.contents.replace(/(\r\n|\n|\r)/gm, "") + "')", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                  <i className="pi pi-times-circle absolute right-0 top-0 mt-1 mr-1 text-red-600 cursor-pointer text-lg" onClick={() => this.deleteImage(i)}></i>
               </div>
            </div>);
         })}
      </div>);
   }
}