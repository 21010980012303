import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import axios from 'axios'
import 'primeflex/primeflex.css';
import './Login.css';
import { API_Root } from "../globalState";
import { ProgressSpinner } from 'primereact/progressspinner';

export const ForgotPassword = () => {
   const [email, setEmail] = useState('');
   const [feedback, setFeedback] = useState('');
   const [loading, setLoading] = useState(false);
   const toast = useRef(null);

   const doLogin = () => {
      var apiUrl = `${API_Root}api/user/ForgotPassword?email=${email}`;
      setLoading(true);

      axios.get(apiUrl).then((values) => {
         setFeedback(values.data);
         setLoading(false);
      });
   }

   const getButton = () => {
      if (loading) {
         return (<ProgressSpinner />);
      }
      else {
         return (<Button className="mr-4" label="Submit" link onClick={() => doLogin()}></Button>);
      }
   }

   return (
      <>
         <Toast ref={toast} />
         <Card className="logincard mt-4" title="Reset Password">
            <div className="field m-4">
               <label htmlFor="email">Email</label>
               <input id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
            </div>
            <div className="m-4">
               <Link to="/login" className="mr-4 text-sm">Return to login</Link>
            </div>
            <div className="m-4">
               Submit your email address and if it matches a user account in our system then you will receive a password reset email with instructions.
            </div>
            <div className="grid">
               <div className="col-6">
                  <div className="text-red-400">
                     {feedback}
                  </div>
               </div>
               <div className="col-6 text-right p-4">
                  {getButton()}
               </div>
            </div>
         </Card>
      </>
   );
}