import React from 'react';
import 'primeflex/primeflex.css';
import './paymentStub.css';
import logo from '../../logo.png';
import { API_Root } from "../../globalState";
import axios from 'axios'
import { Card } from 'primereact/card';

export class PaymentStub extends React.PureComponent {

   constructor(props) {
      super(props);
      this.state = {
         stub: undefined,
         images: []
      }
      this.getPayment();
   }

   getPayment = () => {
      var apiUrl = `${API_Root}api/invoice/GetPayment?paymentId=${this.props.paymentId}`;
      axios.get(apiUrl).then((values) => {
         console.log('stub', values.data);
         this.setState({ stub: values.data });
      });
   }

   formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric'
      });
   }

   formatLongDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: 'numeric',
         month: 'long',
         year: 'numeric'
      });
   }

   numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   chargesHeader = () => {
      return (<thead><tr>
         <th className="tcol1">Date</th>
         <th className="tcol2">Description</th>
         <th className="tcol3">Expenses</th>
         <th className="tcol4">Mileage</th>
         <th className="tcol5">Service Fee</th>
      </tr></thead>);
   }

   chargesTemplate = (date, desc, exp, mil, fee, i) => {
      return (<tr key={i}>
         <td className="tcol1">{date}</td>
         <td className="tcol2">{desc}</td>
         <td className="tcol3">{exp}</td>
         <td className="tcol4">{mil}</td>
         <td className="tcol5">{fee}</td>
      </tr>);
   }

   fillTemplateLines = () => {
      var numlines = this.state.stub?.TripVehicles?.length ?? 0;
      var rtn = [];
      for (var i = numlines; i < 15; i++) {
         rtn.push(this.chargesTemplate('', '', '', '', '', i));
      }
      return rtn;
   }

   getInvoiceVehicle = (id) => {
      if (this.state.stub) {
         this.state.stub?.TripVehicles.forEach((v, i) => {
            if (v.id == id) {
               return v;
            }
         });
      }
      return null;
   }

   getVehicleType = (v) => {
      let rtn = '';
      if (v.VehicleType) {
         rtn = `(${v.VehicleType.typeName})`;
      }
      return rtn;
   }

   render() {
      return (
         <div className="p-4">
            <div className="w-full mb-1 mt-4">
               <div className="grid">
                  <div className="col-3">
                     <img src={logo} className="h-3rem" style={{ objectFit: "scale-down" }} />
                  </div>
                  <div className="col-9">
                     <div className="w-full text-6xl text-right font-bold">
                        Payment Summary
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full mt-4">
               <div className="grid">
                  <div className="col-6 font-bold">
                     Paid To:
                  </div>
                  <div className="col-6 text-right font-semibold">
                  </div>
               </div>
            </div>
            <div className="w-full mb-3 p-0">
               <div className="grid">
                  <div className="col-6">
                     {this.state.stub?.User.firstName} {this.state.stub?.User.lastName}<br />
                     { this.state.stub?.User.streetAddress}<br />
                     { this.state.stub?.User.city }, { this.state.stub?.User.stateAbbr } {this.state.stub?.User.zipCode}
                  </div>
                  <div className="col-6 text-right font-semibold">
                     {this.formatLongDate(this.state.stub?.paymentDate)}
                  </div>
               </div>
            </div>
            <table>
               {this.chargesHeader()}
               <tbody>
                  {this.state.stub?.TripVehicles.map((v, i) => {
                     let desc = `${v.actualMiles} miles to ${v.Trip.Dealership1.city}, ${v.Trip.Dealership1.stateAbbr}`;
                     return this.chargesTemplate(this.formatDate(v.actualDepartDateTime), desc, this.formatter.format(v.ExpenseCost), this.formatter.format(v.MileageCost * 0.9), this.formatter.format(v.MileageCost * 0.1), i)
                  })}
                  {this.fillTemplateLines()}
               </tbody>
            </table>
            <div className="grid w-full text-right text-md">
               <div className="col-9 text-right font-bold">Expenses</div>
               <div className="col-3 text-right">{this.formatter.format(this.state.stub?.expenseAmount)}</div>
            </div>
            <div className="grid w-full text-right text-md">
               <div className="col-9 text-right font-bold">Mileage</div>
               <div className="col-3 text-right">{this.formatter.format(this.state.stub?.mileageAmount)}</div>
            </div>
            <div className="grid w-full text-right text-md">
               <div className="col-9 text-right font-bold">Service Fee</div>
               <div className="col-3 text-right">{this.formatter.format(this.state.stub?.serviceFee)}</div>
            </div>
            <div className="grid w-full text-right text-xl">
               <div className="col-9 text-right font-bold">Payment Total</div>
               <div className="col-3 text-right">{this.formatter.format(this.state.stub?.paymentTotal)}</div>
            </div>
         </div>
      );
   }
}