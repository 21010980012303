import React, { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../../globalState";
import axios from 'axios'
import axiosInterceptor from '../../AuthIntercept';
import { useRef } from 'react';
import { TripLocation } from './Location';
import { TripVehicle } from './Vehicle';
import { TripReview } from './TripReview';
import { AddVehicle } from './AddVehicle';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

export const TripWizard = (props) => {
   const [tripId, setTripId] = React.useState(0);
   const [dealership, setDealership] = React.useState();
   const [source, setSource] = React.useState();
   const [destination, setDestination] = React.useState();
   const [distance, setDistance] = React.useState();
   const [deliverytime, setDeliveryTime] = React.useState('');
   const [pickupDate, setPickupDate] = React.useState('');
   const [pickupTime, setPickupTime] = React.useState('');
   const [contactname, setContactName] = React.useState('');
   const [contactphone, setContactPhone] = React.useState('');
   const [sourceContactName, setSourceContactName] = React.useState('');
   const [sourceContactPhone, setSourceContactPhone] = React.useState('');
   const [dealNumber, setDealNumber] = React.useState('');
   const [roundTrip, setRoundTrip] = React.useState(false);
   const [masterTimes, setMasterTimes] = React.useState();
   const [fromTimes, setFromTimes] = React.useState();
   const [toTimes, setToTimes] = React.useState();
   const [pickupTimes, setPickupTimes] = React.useState();
   const [fromTime, setFromTime] = React.useState('');
   const [toTime, setToTime] = React.useState('');
   const [customNotes, setCustomNotes] = React.useState('');
   const [selNotes, setSelNotes] = React.useState([]);
   const [allNotes, setAllNotes] = React.useState([]);
   const [vehicles, setVehicles] = React.useState();
   const [showValidation, setShowValidation] = React.useState(false);
   const [messages, setMessages] = React.useState([]);
   const [tripToSave, setTripToSave] = React.useState({});
   const [showSaveConfirm, setShowSaveConfirm] = React.useState(false);
   const [vehicleTypes, setVehicleTypes] = React.useState([]);
   const [init, setInit] = React.useState(false);
   const [showAddVehicle, setShowAddVehicle] = React.useState(false);
   const [vehicleTypeToAdd, setVehicleTypeToAdd] = React.useState();

   const toast = useRef(null);
   const stepperRef = useRef(null);

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric'
      });
   }


   const getVehicleTypes = () => {
      var apiUrl = `${API_Root}api/trip/GetVehicleTypeList`;
      axios.get(apiUrl).then((values) => {
         console.log('vehicle types', values);
         setVehicleTypes(values.data);
      });
   }

   const checkDupVehicle = (veh) => {

      var apiUrl = `${API_Root}api/trip/GetDeliveryDateByVIN?dealershipId=${props.dealershipId}&vin=${veh.vehicleVIN}`;
      axios.get(apiUrl).then((values) => {
         var dt = values.data;
         if (dt) {
            confirmDialog({
               message: `This vehicle was already added to an order for ${formatDate(dt)}. Add it anyway?`,
               header: 'Confirmation',
               icon: 'pi pi-exclamation-triangle',
               accept: () => addVehicleSave(veh),
               reject: null
            });
         }
         else {
            addVehicleSave(veh);
         }
      });
   }

   const checkAvailableDrivers = () => {
      let requestedDeliveryDateTime = new Date(deliverytime.toDateString() + ' ' + (fromTime ?? ""));
      let requestedDeliveryDateTimeEnd = new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? ""));

      var apiUrl = `${API_Root}api/dealership/getAvailablePorters?dealershipId=${props.dealershipId}&tripDate=${requestedDeliveryDateTime.toJSON()}&tripEnd=${requestedDeliveryDateTimeEnd.toJSON()}`;
      axios.get(apiUrl).then((values) => {
         var drivers = [];
         drivers = values.data;
         let numVehs = vehicles.length;
         let numDrivers = (Math.floor(numVehs / 2)) + (numVehs % 2);
         let availCount = drivers.length;

         if (numDrivers > availCount) {
            let msg = 'no drivers are';
            if (availCount == 1) {
               msg = '1 driver is';
            } else {
               msg = `${availCount} drivers are`;
            }
            confirmDialog({
               message: `This trip will require at least ${numDrivers} drivers but ${msg} available at the requested trip time. Do you want to proceed?`,
               header: 'Confirmation',
               icon: 'pi pi-exclamation-triangle',
               accept: () => saveTrip(),
               reject: null
            });
         }
         else {
            saveTrip();
         }
      });
   }

   const fillVehicleType = (vt) => {
      var dest = 'Destination';
      var src = 'Source';
      if (source) {
         src = source.name ?? source.formatted_address;
      }
      if (destination) {
         dest = destination.name ?? destination.formatted_address;
      }

      if (vt) {
         if (vt.roundTrip) {
            return <div><span style={{ fontWeight: 'bold' }}>Chase Car</span></div>;
         }
         else if (vt.fromOrigin) {
            return <div>From <span style={{ fontWeight: 'bold' }}>{src}</span> to <span style={{ fontWeight: 'bold' }}>{dest}</span></div>;
         }
         else {
            return <div>From <span style={{ fontWeight: 'bold' }}>{dest}</span> to <span style={{ fontWeight: 'bold' }}>{src}</span></div>;
         }
      }
      return <></>;
   }

   const getPorterNotesList = () => {
      var apiUrl = `${API_Root}api/trip/GetAllPorterNotes`;
      axios.get(apiUrl).then((values) => {
         console.log('porter notes', values.data);
         setAllNotes(values.data);
      });
   }

   const getDistanceInfo = () => {
      var apiUrl = `${API_Root}api/trip/GetDistanceInfo`;
      var request = {
         sourceId: source.place_id,
         destinationId: destination.place_id
      };

      axios.post(apiUrl, request).then((value) => {
         console.log('distance info', value);
         setDistance(value.data);
      });
   }

   const getDealership = (id) => {
      var apiUrl = `${API_Root}api/dealership/get?id=${id}`;
      axios.get(apiUrl).then((values) => {
         let dealer = values.data;
         setSource({
            name: dealer.dealershipName,
            formatted_address: `${dealer.streetAddress}, ${dealer.city}, ${dealer.stateAbbr} ${dealer.zipCode}`,
            place_id: dealer.googlePlaceId,
            geometry: {
               location: {
                  lat: dealer.lat,
                  lng: dealer.lon
               }
            }
         });
      });
   }

   const setInitialSource = () => {
      getDealership(props.dealershipId);
   }

   const formatAMPM = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      hours = hours < 10 ? '0' + hours : hours;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
   }

   const getExistingTrip = (id) => {
      var apiUrl = `${API_Root}api/trip/GetTrip?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('get trip', values.data);
         const trip = values.data;
         setTripId(trip.id);
         setDistance({ miles: trip.estimatedMiles, minutes: trip.estimatedHours, estimatedDistance: trip.distance, estimatedDuration: trip.duration });
         setContactName(trip.contactName);
         setContactPhone(trip.contactNumber);
         setRoundTrip(trip.roundTrip);
         setSourceContactName(trip.sourceContactName);
         setSourceContactPhone(trip.sourceContactNumber);
         setDealNumber(trip.dealNumber);
         setCustomNotes(trip.porterNotes ?? '');
         setSelNotes(trip.TripPorterNotes.map((n) => { return n.noteId }));

         //requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
         //requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),

         let timeFrom = new Date(trip.requestedDeliveryDateTime);
         let timeTo = new Date(trip.requestedDeliveryDateTimeEnd);

         deliveryDateSelected(timeFrom);
         selectFromTime(formatAMPM(timeFrom));
         setToTime(formatAMPM(timeTo));

         if (trip.pickupDateTime) {
            let pTime = new Date(trip.pickupDateTime);
            pickupDateSelected(pTime);
            setPickupTime(formatAMPM(pTime));
         }

         setVehicles(trip.TripVehicles);

         setSource({
            name: trip.Dealership2.dealershipName,
            formatted_address: `${trip.Dealership2.streetAddress}, ${trip.Dealership2.city}, ${trip.Dealership2.stateAbbr} ${trip.Dealership2.zipCode}`,
            place_id: trip.Dealership2.googlePlaceId,
            geometry: {
               location: {
                  lat: trip.Dealership2.lat,
                  lng: trip.Dealership2.lon
               }
            }
         });


         setDestination({
            name: trip.Dealership1.dealershipName,
            formatted_address: `${trip.Dealership1.streetAddress}, ${trip.Dealership1.city}, ${trip.Dealership1.stateAbbr} ${trip.Dealership1.zipCode}`,
            place_id: trip.Dealership1.googlePlaceId,
            geometry: {
               location: {
                  lat: trip.Dealership1.lat,
                  lng: trip.Dealership1.lon
               }
            }
         });

      });
   }

   const validateSubmit = () => {
      var msgs = [];
      if (!source) {
         msgs.push('Please select an origin location');
      }
      if (!destination) {
         msgs.push('Please select a destination location');
      }
      if (!deliverytime || deliverytime.length === 0) {
         msgs.push('Please provide the requested delivery date');
      }

      if (!fromTime || fromTime.length === 0) {
         msgs.push('Please provide the requested delivery time from');
      }

      if (!toTime || toTime.length === 0) {
         msgs.push('Please provide the requested delivery time to');
      }

      if (msgs.length > 0) {
         setMessages(msgs);
         setShowValidation(true);
         return false;
      }
      else {
         return true;
      }
   }

   const getMessages = () => {
      if (messages.length > 0) {
         return <>
            <div>Please correct the following issues before saving</div>
            <div>
               <ul className="text-red-400">
                  {messages.map((m) => <li>{m}</li>)}
               </ul>
            </div>
         </>
      }
      else {
         return <></>;
      }
   }

   const stripTimeZone = (dt) => {
      let formatted = moment(dt).format('YYYY-MM-DDTHH:mm:ss') + "Z";
      return moment(formatted).toDate();
   }

   const saveTrip = () => {

      if (!validateSubmit()) {
         return;
      }

      var trip = {
         id: tripId ?? 0,
         dealershipId: props.dealershipId,
         createdBy: props.userId,
         TripVehicles: vehicles,
         estimatedMiles: distance.miles,
         estimatedHours: distance.minutes,
         contactName: contactname,
         contactNumber: contactphone,
         requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
         pickupDateTime: (pickupDate && pickupTime) ? new Date(pickupDate.toDateString() + ' ' + pickupTime) : null,
         estimatedDistance: distance.distance,
         estimatedDuration: distance.duration,
         roundTrip: roundTrip,
         requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),
         sourceContactName: sourceContactName,
         sourceContactNumber: sourceContactPhone,
         dealNumber: dealNumber,
         porterNotes: customNotes,
         TripPorterNotes: selNotes.map((n) => { return { noteId: n } })
      };

      var dto = {
         trip: trip,
         source: source,
         destination: destination
      };

      setTripToSave(dto);
      setShowSaveConfirm(true);
   }

   const completeSave = () => {
      let t = tripToSave;
      //t.trip.requestedDeliveryDateTime = stripTimeZone(t.trip.requestedDeliveryDateTime);
      //t.trip.requestedDeliveryDateTimeEnd = stripTimeZone(t.trip.requestedDeliveryDateTimeEnd);

      //if (t.trip.pickupDateTime) {
      //   t.trip.pickupDateTime = stripTimeZone(t.trip.pickupDateTime);
      //}

      var apiUrl = `${API_Root}api/trip/SaveTrip`;
      axios.post(apiUrl, t).then((values) => {
         console.log('save trip', values.data);
         if (values.data === true) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Trip successfully created', life: 3000 });
         }
         else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Trip could not be created', life: 3000 });
         }
         setShowSaveConfirm(false);
         props.onCreated();
      });
   }

   const getMasterTimes = () => {
      var time = moment('00:00', 'HH:mm');
      var lst = [];

      for (var i = 0; i < 48; i++) {
         lst.push({
            index: i,
            value: time.format("hh:mm A")
         });
         time.add(30, 'm');
      }
      console.log(lst);
      setMasterTimes(lst);
      setFromTimes(lst);
   }

   const getMinDate = () => {
      var curTimeM = moment(new Date());

      if (distance) {
         curTimeM.add(distance.minutes, 'm');
      }
     return(curTimeM.toDate());
   }

   const selectFromTime = (val) => {
      setFromTime(val);
      var ind = 0;
      for (var i = 0; i < fromTimes.length; i++) {
         if (fromTimes[i].value === val) {
            ind = i + 1;
            break;
         }
      }
      var toLst = fromTimes.slice(ind, fromTimes.length);
      console.log('to times', toLst);
      setToTimes(toLst);
      setToTime(toLst[0].value);
   }

   const deliveryDateSelected = (dt) => {
      var curTimeM = moment(new Date());

      if (distance) {
         curTimeM.add(distance.minutes, 'm');
      }
      var curTimeD = curTimeM.toDate();

      if (dt.getDay() == curTimeD.getDay() &&
         dt.getMonth() == curTimeD.getMonth() &&
         dt.getFullYear() == curTimeD.getFullYear()) {
         setFromTimes(getTimesBetween(curTimeD.getHours(), curTimeD.getMinutes(), 23, 31));
      }
      else {
         setFromTimes(getTimesBetween(0, 0, 23, 31));
      }
      setDeliveryTime(dt);
   }

   const pickupDateSelected = (dt) => {
      var curTimeD = new Date();

      if (dt.getDay() == curTimeD.getDay() &&
         dt.getMonth() == curTimeD.getMonth() &&
         dt.getFullYear() == curTimeD.getFullYear()) {
         setPickupTimes(getTimesBetween(curTimeD.getHours(), curTimeD.getMinutes(), 23, 31));
      }
      else {
         setPickupTimes(getTimesBetween(0, 0, 23, 31));
      }
      setPickupDate(dt);
   }

   const getTimesBetween = (hour1, min1, hour2, min2) => {
      let h = hour1;
      let m = 0;
      if (min1 > 0 && min1 < 30) {
         m = 30;
      }
      else if (min1 > 30 && h < 23) {
         h++;
      }
      let curdt = new Date();
      curdt.setHours(h, m, 0, 0);
      let time = moment(curdt);
      let stopdt = new Date();
      stopdt.setHours(hour2, min2);

      var lst = [];
      let i = 0;
      while (time.toDate() < stopdt) {
         lst.push({
            index: i,
            value: time.format("hh:mm A")
         });
         i++;
         time.add(30, 'm');
      }
      return lst;
   }

   const onNotesChange = (e) => {
      let _notes = [...selNotes];

      if (e.checked)
         _notes.push(e.value);
      else
         _notes.splice(_notes.indexOf(e.value), 1);

      setSelNotes(_notes);
   }

   useEffect(() => {
      if (props.tripId) {
         getExistingTrip(props.tripId);
      }
      else {
         setSourceContactName(props.userName);
         setSourceContactPhone(props.userPhone);
      }
      getVehicleTypes();
   }, [init]);

   useEffect(() => {
      if (source !== undefined && destination !== undefined) {
         getDistanceInfo();
      }

      if (!props.tripId) {
         setDeliveryTime('');
         setFromTime('');
         setToTime('');
         setPickupDate('');
         setPickupTime('');
      }
   }, [source, destination]);

   const selectSource = (p) => {
      setSource(p);
   }

   const addVehicleSave = (v) => {
      console.log('adding vehicle', v);
      let old = vehicles || [];
      let vehs = [...old];
      vehs.push(v);
      console.log('new array', vehs);
      setVehicles(vehs);
      setShowAddVehicle(false);
   }

   const removeVehicle = (i) => {
      let old = vehicles || [];
      let vehs = [...old];
      vehs.splice(i, 1);
      setVehicles(vehs);
   }

   const getDealNumberClass = () => {
      return (dealNumber) ? "w-12rem text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "w-12rem text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none focus:border-primary w-full border-red-400";
   }

   const getToTimeClass = () => {
      return (toTime) ? "ml-2" : "ml-2 border-red-400";
   }

   const getFromTimeClass = () => {
      return (fromTime) ? "ml-2" : "ml-2 border-red-400";
   }

   const getDeliveryDateClass = () => {
      return (deliverytime) ? "w-10rem" : "w-10rem border-red-400";
   }

   const getDistanceTemplate = () => {
      if (distance) {
         return <span className="font-semibold font-italic text-sm">{distance.distance} / {distance.duration}</span>;
      }
      return "";
   }

   const getVehicleList = (vehType) => {
      return vehicles?.map((v, t) => {
         let del = <i className="pi pi-times-circle mr-1 text-red-600 cursor-pointer text-xl" onClick={() => removeVehicle(t)}></i>;
         if (v.tripVehicleId == vehType) {
            if (v.vehicleVIN && v.vehicleMake && v.vehicleModel &&
               v.vehicleVIN.length > 0 && v.vehicleMake.length > 0 && v.vehicleModel.length > 0) {
               return <div key={vehType + '.' + t}>{del}<span style={{ fontWeight: 'bold' }}>{v.vehicleVIN}</span> {v.vehicleYear} {v.vehicleMake} {v.vehicleModel}</div>;
            }
            else {
               return <div key={vehType + '.' + t}>{del}<span style={{ fontWeight: 'bold' }}>Unspecified Vehicle</span></div>;
            }
         } else {
            return <></>;
         }
      });
   }

   const addVehicle = (typeToAdd) => {
      setVehicleTypeToAdd(typeToAdd);
      setShowAddVehicle(true);
   }

   if (!init) {
      getMasterTimes();
      getPorterNotesList();
      setInitialSource();
      setInit(true);
   }

   const canStep2 = () => {
      if (source && destination) {
         return true;
      } else {
         return false;
      }
   }

   const canStep3 = () => {
      if (dealNumber && deliverytime && fromTime && toTime && fromTime.length > 0 && toTime.length > 0) {
         return true;
      } else {
         return false;
      }
   }

   const canStep4 = () => {
      if (vehicles && vehicles.length > 0) {
         return true;
      } else {
         return false;
      }
   }

    const switchLocations = () => {
        let newSource = destination;
        let newDest = source;
        setSource(newSource);
        setDestination(newDest);
    }

   const getNextButton = (en) => {
      if (en) {
         return <Button label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />;
      }
      else {
         return <Button disabled label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />;
      }
   }

   const getSaveButton = () => {
      if (canStep2() && canStep3() && canStep4()) {
         return <Button className="mr-6" label="Save" icon="pi pi-save" onClick={() => checkAvailableDrivers()} />;
      }
      else {
         return <Button disabled className="mr-6" label="Save" icon="pi pi-save" onClick={() => checkAvailableDrivers()} />;
      }
   }

   return (
      <>
         <Stepper ref={stepperRef} style={{ flexBasis: '50rem' }}>
            <StepperPanel header="Route">
               <div className="flex flex-column ">
                  <div className="w-full font-bold pl-3">{getDistanceTemplate()}</div>
                  <div className="flex flex-wrap mt-2">
                     <table>
                        <thead>
                           <tr className="surface-600 text-center font-bold text-200">
                                       <td style={{ width: "50%" }}>Source</td>
                                       <td style={{ width: "50%" }}>Destination</td>
                           </tr>
                        </thead>
                               <thead>
                                   <tr>
                                       <td colSpan={2} className="text-center">
                                           <div className="grid">
                                               <div className="col-5">
                                    <TripLocation value={source} onChange={(l) => setSource(l)}></TripLocation>
                                                   </div>
                                               <div className="col-2 text-2xl">
                                                   <Button label="Swap" icon="pi pi-arrows-h" link onClick={() => switchLocations()} />
                                               </div>
                                               <div className="col-5">
                                    <TripLocation value={destination} onChange={(l) => setDestination(l)}></TripLocation>
                                               </div>
                                            </div>
                                       </td>
                                    </tr>
                           <tr className="bg-white">
                              <td>
                                 <div className="field">
                                    <label htmlFor="sourcecontactname">Contact Name</label>
                                    <input id="sourcecontactname"
                                       value={sourceContactName}
                                       onChange={(e) => setSourceContactName(e.target.value)}
                                       className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                                 </div>
                              </td>
                              <td>
                                 <div className="field">
                                    <label htmlFor="contactname">Contact Name</label>
                                    <input id="contactname"
                                       value={contactname}
                                       onChange={(e) => setContactName(e.target.value)}
                                       className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                                 </div>
                              </td>
                           </tr>
                           <tr className="bg-white">
                              <td>
                                 <div className="field">
                                    <label htmlFor="sourcecontactphone">Contact Phone</label>
                                    <InputMask id="sourcecontactphone"
                                       mask="(999) 999-9999"
                                       value={sourceContactPhone}
                                       onChange={(e) => setSourceContactPhone(e.target.value)}
                                       className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                                 </div>
                              </td>
                              <td>
                                 <div className="field">
                                    <label htmlFor="contactphone">Contact Phone</label>
                                    <InputMask id="contactphone"
                                       mask="(999) 999-9999"
                                       value={contactphone}
                                       onChange={(e) => setContactPhone(e.target.value)}
                                       className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                                 </div>
                              </td>
                           </tr>
                        </thead>
                     </table>
                  </div>
               </div>
               <div className="flex pt-4 justify-content-end">
                  {getNextButton(canStep2())}
               </div>
            </StepperPanel>
            <StepperPanel header="Schedule">
               <div className="field flex flex-column">
                  <label htmlFor="dealnumber">Deal Number</label>
                  <input id="dealnumber"
                     value={dealNumber}
                     onChange={(e) => setDealNumber(e.target.value)}
                     className={getDealNumberClass()} />
               </div>
               <div className="field flex flex-column">
                  <div className="field mt-4">
                     <label htmlFor="pickuptime">Pickup Available Time</label>
                     <div>
                        <Calendar minDate={new Date()} value={pickupDate} onChange={(e) => pickupDateSelected(e.value)} placeholder="Select Date" inputClassName="w-10rem" />
                        <Dropdown value={pickupTime} onChange={(e) => setPickupTime(e.value)} options={pickupTimes} optionLabel="value" className="ml-2"
                           placeholder="Time" />
                     </div>
                  </div>
               </div>
               <div className="flex flex-column h-12rem">
                  <div className="field mt-4">
                     <label htmlFor="deliverytime">Requested Delivery Time Range</label>
                     <div>
                        <Calendar minDate={getMinDate()} value={deliverytime} onChange={(e) => deliveryDateSelected(e.value)} placeholder="Select Date" inputClassName={getDeliveryDateClass()} />
                        <Dropdown value={fromTime} onChange={(e) => selectFromTime(e.value)} options={fromTimes} optionLabel="value"
                           placeholder="From" className={getFromTimeClass()} />
                        <Dropdown value={toTime} onChange={(e) => setToTime(e.value)} options={toTimes} optionLabel="value"
                           placeholder="To" className={getToTimeClass()} />
                     </div>
                  </div>
               </div>
               <div className="flex pt-4 justify-content-between">
                  <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                  {getNextButton(canStep3())}
               </div>
            </StepperPanel>
            <StepperPanel header="Vehicles">
               <div className="flex flex-column">
                  <table>
                     <thead>
                        <tr>
                           <td style={{ width: '34%', textAlign: 'center' }}>{fillVehicleType(vehicleTypes[0])}</td>
                           <td style={{ width: '33%', textAlign: 'center' }}>{fillVehicleType(vehicleTypes[1])}</td>
                           <td style={{ width: '33%', textAlign: 'center' }}>{fillVehicleType(vehicleTypes[2])}</td>
                        </tr>
                        <tr>
                           <td style={{ width: '34%', textAlign: 'center' }}><Button className="p-2 mr-4" label="Add" icon="pi pi-plus-circle" link onClick={() => addVehicle(vehicleTypes[0]) } /></td>
                           <td style={{ width: '33%', textAlign: 'center' }}><Button className="p-2 mr-4" label="Add" icon="pi pi-plus-circle" link onClick={() => addVehicle(vehicleTypes[1])} /></td>
                           <td style={{ width: '33%', textAlign: 'center' }}><Button className="p-2 mr-4" label="Add" icon="pi pi-plus-circle" link onClick={() => addVehicle(vehicleTypes[2])} /></td>
                        </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <td>{getVehicleList(vehicleTypes[0]?.id)}</td>
                           <td>{getVehicleList(vehicleTypes[1]?.id)}</td>
                           <td>{getVehicleList(vehicleTypes[2]?.id)}</td>
                        </tr>

                     </tbody>
                  </table>
               </div>
               <div className="flex pt-4 justify-content-between">
                  <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                  {getNextButton(canStep4())}
               </div>
            </StepperPanel>
            <StepperPanel header="Notes">
               <div className="flex flex-column">
                  <div className="flex flex-wrap justify-content-left gap-3 mt-2">
                     <label htmlFor="">Standard Notes</label>
                     <ScrollPanel style={{ width: '100%', height: '100px' }}>
                        {allNotes.map((n, i) => {
                           return (<div key={i}>
                              <Checkbox
                                 value={n.id}
                                 onChange={onNotesChange}
                                 checked={selNotes.includes(n.id)} />
                              <span className="ml-2">{n.note}</span>
                           </div>);
                        })
                        }
                     </ScrollPanel>
                     <div className="field">
                        <label htmlFor="customnotes">Custom Note</label>
                        <div>
                           <InputTextarea autoResize
                              id="customnotes"
                              value={customNotes}
                              onChange={(e) => setCustomNotes(e.target.value)}
                              rows={5}
                              cols={100} />
                        </div>
                     </div>
                   </div>
               </div>
               <div className="flex pt-4 justify-content-start">
                  <Button label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
               </div>
            </StepperPanel>
         </Stepper>
         <Toast ref={toast} />
         <div className="w-full text-right mr-4 mb-2 absolute bottom-0">
            <Button className="mr-6" label="Cancel" icon="pi pi-times" onClick={() => props.onCancel()} />
            {getSaveButton()}
         </div>
         <Dialog header="Validation" visible={showValidation} style={{ width: '30vw' }} onHide={() => setShowValidation(false)}>
            {getMessages()}
            <div className="col-5 text-right">
               <Button label="Close" onClick={() => setShowValidation(false)} />
            </div>
         </Dialog>
         <Dialog header="Confirm New Trip" visible={showSaveConfirm} style={{ width: '65vw' }} onHide={() => setShowSaveConfirm(false)}>
            <TripReview trip={tripToSave}></TripReview>
            <div className="w-full text-right">
               <Button className="mr-2" label="Cancel" onClick={() => setShowSaveConfirm(false)} />
               <Button label="Save" onClick={() => completeSave()} />
            </div>
         </Dialog>
         <Dialog header="Add Vehicle" visible={showAddVehicle} style={{ width: '80vw' }} onHide={() => setShowAddVehicle(false)}>
            <AddVehicle
               value={null}
               vehicleType={vehicleTypeToAdd}
               vehicleTypeText={fillVehicleType(vehicleTypeToAdd)}
               onCancel={() => setShowAddVehicle(false)}
               onAdd={(v) => checkDupVehicle(v)}>
            </AddVehicle>
         </Dialog>
      </>);

};