import React, { useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../../globalState";
import axios from 'axios'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

export const TripLocation = ({ value, onChange }) => {
   const [place, setPlace] = React.useState(undefined);
   const [places, setPlaces] = React.useState();
   const [placesearch, setPlaceSearch] = React.useState('');

   const doPlaceSearch = (e) => {
      var apiUrl = `${API_Root}api/dealership/getPlaces?query=${placesearch}`;
      axios.get(apiUrl).then((values) => {
         console.log('place search', values.data);
         setPlaces(values.data);
      });
   }

   const handleSearchKeyDown = (event) => {
      if (event.key === 'Enter') {
         doPlaceSearch(event);
      }
   }

   const selectLocation = (p) => {
      let pid = p.value.place_id;
      console.log('p', p);
      geocodeByPlaceId(pid)
         .then(results => {
            console.log('geocode', results)
            if (results && results.length > 0) {
               let place = results[0];
               place.name = p.value.structured_formatting?.main_text ?? "Private Address";

               onChange(place);
               setPlace(place);
               setPlaceSearch("");
               setPlaces(undefined);
            }
         });

   }

   const clearSource = () => {
      onChange(undefined);
      setPlace(undefined);
      setPlaceSearch("");
      setPlaces(undefined);
   }

   useEffect(() => {
      setPlace(value);

   }, [value]);

   if (place === undefined) {

      return (<>
         <div className="w-24rem lg:w-30rem">
            <GooglePlacesAutocomplete
               className="w-24rem lg:w-30rem"
               apiKey="AIzaSyA8PPKH971gH5S2bQB2wPi1XBYsfdOny38"
               selectProps={{
                  styles: {
                     input: (provided) => ({
                        ...provided,
                        width: '100%',
                     }),
                     option: (provided) => ({
                        ...provided,
                        color: 'blue',
                     }),
                     singleValue: (provided) => ({
                        ...provided,
                        color: 'blue',
                     }),
                  },
                  value: placesearch,
                  onChange: selectLocation,
               }}
            />
         </div>
      </>);
   }
   else {

      return (
         <div className="mt-1 border-400 border-1 p-2 mr-3">
            <div>
               <div className="w-full">
                  <div className="w-full text-xl">{place ? place?.name ?? "Private Address" : ""}</div>
                  <div className="w-full">{place?.formatted_address}</div>
               </div>
            </div>
            <div className="w-full text-right"><Button label="Search Again" link onClick={() => clearSource()} /></div>
         </div>
      );
   }
}