import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { ProgressSpinner } from 'primereact/progressspinner';
import axios from 'axios'
import 'primeflex/primeflex.css';
import { API_Root } from "../globalState";
import { Tag } from 'primereact/tag';
import { TripFiles } from '../mobile/trip/TripFile';

export const TripDetails = (props) => {
   const [init, setInit] = useState(false);
   const [trip, setTrip] = useState();
   const [tripId, setTripId] = useState();
   const [userId, setUserId] = useState();
   const [showComplete, setShowComplete] = useState();
   const [confirmIndex, setConfirmIndex] = useState(0);
   const [confirmNotes, setConfirmNotes] = useState([]);
   const [actualMiles, setActualMiles] = useState();
   const [porters, setPorters] = useState();
   const [porter, setPorter] = useState();
   const [showFiles, setShowFiles] = useState(false);

   if (!init) {
      setInit(true);
      const query = new URLSearchParams(props.location.search);
      const uid = query.get('u');
      const tid = query.get('t');
      setTripId(tid);
      setUserId(uid);

      var apiUrl = `${API_Root}api/trip/GetTrip?id=${tid}`;

      axios.get(apiUrl).then((values) => {
         console.log('trip', values.data);
         var success = values.data != null;
         if (success) {
            setTrip(values.data);
            setActualMiles(values.data.estimatedMiles);
            let pTemp = [];

            values.data.TripVehicles.forEach((p) => {
               pTemp.push({
                  name: p.User.firstName + ' ' + p.User.lastName,
                  id: p.porterId,
                  type: p.isChase ? "Chase" : "Driver",
                  phone: p.User.phone,
                  confirmed: p.confirmed
               });

               if (p.porterId == uid) {
                  console.log('porter', p);
                  setPorter(p);
               }
            });
            setPorters(pTemp);
         }
      });
   }

   const refreshTrip = () => {
      var apiUrl = `${API_Root}api/trip/GetTrip?id=${tripId}`;
      axios.get(apiUrl).then((values) => {
         console.log('trip', values.data);
         var success = values.data != null;
         if (success) {
            setTrip(values.data);
            let pTemp = [];

            values.data.TripVehicles.forEach((p) => {
               pTemp.push({
                  name: p.User.firstName + ' ' + p.User.lastName,
                  id: p.porterId,
                  type: p.isChase ? "Chase" : "Driver",
                  phone: p.User.phone,
                  confirmed: p.confirmed
               });

               if (p.porterId == userId) {
                  console.log('porter', p);
                  setPorter(p);
               }
            });
            setPorters(pTemp);
         }
      });
   }

   const beginTrip = () => {
      var apiUrl = `${API_Root}api/trip/BeginTrip?tripId=${tripId}&porterId=${userId}`;

      axios.get(apiUrl).then((values) => {
         refreshTrip();
      });
   }

   const completeTrip = () => {
      var apiUrl = `${API_Root}api/trip/CompleteTrip?tripId=${tripId}&porterId=${userId}&actualMiles=${actualMiles}`;

      axios.get(apiUrl).then((values) => {
         refreshTrip();
         setShowComplete(false);
      });
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   };

   const formatTime = (dt) => {
      var d = new Date(dt);
      return d.toLocaleTimeString('en-US', {
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   };

   const formatBool = (b) => {
      if (b) {
         return "Yes";
      } else {
         return "No";
      }
   };

   const porterListTemplate = (p) => {
      let type;
      if (!p.confirmed) {
         type = <Tag severity="warning" value="Unconfirmed"></Tag>;
      }
      else if (p.isChase) {
         type = <Tag severity="success" value="Chase"></Tag>;
      }
      else {
         type = <Tag severity="info" value="Driver"></Tag>;
      }

      return (<>
         <div className="w-full mt-4">
            <span className="font-bold">{p.vehicleVIN}</span> - {p.vehicleYear} {p.vehicleMake} {p.vehicleModel}
         </div>
         <div key={p.id} className="flex flex-row flex-wrap">
            <div className="mr-4">
               {p.User.firstName} {p.User.lastName}
            </div>
            <div className="mr-4">
               <a href={"tel:" + p.User.phone}>{p.User.phone}</a>
            </div>
            <div className="mr-4">
               {type}
            </div>
         </div>
         </>
      );
   }

   const splitOnCR = (s) => {
      return s?.split(/\r?\n/);
   }

   const splitVehicleNotes = (s) => {
      return this.splitOnCR(s)?.map((n, i) => {
         return (<div key={i} className="w-full"><span className="ml-4">{n}</span></div>);
      })
   }

   const getButtons = () => {
      if (!porter?.actualDepartDateTime) {
         return (<Button className="mr-4" label="Begin Trip" onClick={() => beginTrip()}></Button>);
      } else if (!porter.completed) {
         return (<Button className="mr-4" label="Complete Trip" onClick={() => showConfirm()}></Button>);
      } else {
         return (<div>
            Trip has been completed
         </div>);
      };
   }

   const showConfirm = () => {
      let notes = [];
      trip.TripPorterNotes?.forEach((n) => {
         notes.push(n.PorterNotesLookup.note);
      });

      splitOnCR(trip.porterNotes)?.map((n, i) => {
         notes.push(n);
      });

      setConfirmNotes(notes);
      setConfirmIndex(0);
      setShowComplete(true);
   }

   const confirmNote = () => {
      let newIndex = confirmIndex + 1;
      if (newIndex >= confirmNotes.length) {
         setConfirmIndex(-1);
      }
      else {
         setConfirmIndex(newIndex);
      }
   }

   const getConfirmContent = () => {
      if (confirmIndex === -1 || confirmIndex >= confirmNotes.length) {
         return <div>
            <div className="w-full flex justify-content-start">
               <div className="mr-2 pt-2">Actual Miles</div>
               <div><InputNumber value={actualMiles} onValueChange={(e) => setActualMiles(e.value)} /></div>
            </div>
            <div className="w-full text-right mt-4">
               <Button className="mr-4" label="Complete Trip" onClick={() => completeTrip()}></Button>
            </div>
         </div>;
      }
      else {
         return <div className="w-full">
            <div className="w-full font-semibold text-left mb-2">
               Please confirm that each note has been completed
            </div>
            <div className="w-full flex justify-content-start">
               <div className="mr-2"><Checkbox checked={false} onChange={e => confirmNote()} /></div>
               <div>{confirmNotes[confirmIndex]}</div>
            </div>
         </div>;
      }
   }

   if (trip && porter) {

      return (<Card>
         <div className="grid">
            <div className="col-12">
               <h3>Trip for {trip.Dealership.dealershipName}</h3>
            </div>
         </div>
         <Divider />
         <div className="grid">
            <div className="col-12">
               <span className="font-bold">Depart: </span>{formatDate(trip.departDateTimeStart)} - {formatTime(trip.departDateTimeEnd)}
            </div>
            <div className="col-12">
               <span className="font-bold">Arrive: </span>{formatDate(trip.requestedDeliveryDateTime)} - {formatTime(trip.requestedDeliveryDateTimeEnd)}
            </div>
         </div>
         <div className="flex">
            <div className="mr-4">
               <span className="font-bold">Number of Vehicles: </span>{trip.TripVehicles.length}
            </div>
            <div className="mr-4">
               <span className="font-bold">Chase Car: </span>{formatBool(trip.roundTrip)}
            </div>
            <div className="">
               
            </div>
         </div>
         <Divider />
         <div className="flex">
            <div className="mr-4">
               <div className="w-full font-bold">Source</div>
               <div className="w-full">{trip.Dealership2.dealershipName ?? "Private address"}</div>
               <div className="w-full">{trip.Dealership2.streetAddress}</div>
               <div className="w-full">{trip.Dealership2.city} {trip.Dealership2.stateAbbr} {trip.Dealership2.zipCode}</div>
            </div>
            <div className="">
               <div className="w-full font-bold">Destination</div>
               <div className="w-full">{trip.Dealership1.dealershipName ?? "Private address"}</div>
               <div className="w-full">{trip.Dealership1.streetAddress}</div>
               <div className="w-full">{trip.Dealership1.city} {trip.Dealership1.stateAbbr} {trip.Dealership1.zipCode}</div>
            </div>
         </div>
         <Divider />
         {trip?.TripVehicles?.map((p, i) => {
            return porterListTemplate(p);
         })}
         <Divider />
         <div className="grid">
            <div className="col-12">
               <div className="w-full">
                  <ul>
                     {trip.TripPorterNotes?.map((n, i) => {
                        return (<li key={i}>{n.PorterNotesLookup.note}</li>);
                     })
                     }
                     {splitOnCR(trip.porterNotes)?.map((n, i) => {
                        return (<li key={"custom" + i}>{n}</li>);
                     })
                     }
                  </ul>
               </div>
            </div>
         </div>
         <Divider />
         <div className="grid">
            <div className="col-6 text-left">
               <Button className="mr-4" label="Manage Files" link onClick={() => setShowFiles(true)}></Button>
            </div>
            <div className="col-6 text-right">
               {getButtons()}
            </div>
         </div>
         <Dialog header="Complete Trip" visible={showComplete} className="sm:w-11 md:w-6 lg:w-4 xl:w-4" onHide={() => setShowComplete(false)}>
            <div className="w-full text-right mt-2">
               {getConfirmContent()}
            </div>
         </Dialog>
         <Dialog header="Trip Files" visible={showFiles} className="sm:w-11 md:w-6 lg:w-4 xl:w-4" onHide={() => setShowFiles(false)}>
            <TripFiles userId={userId} tripId={tripId}></TripFiles>
         </Dialog>
      </Card>);

   }
   else if (trip && !porter) {
      return (<Card className="text-center">You are not assigned to this trip</Card>);
   }
   else {
         return (<Card className="text-center"><ProgressSpinner /></Card>);
      }

   }