import React, { useState } from 'react';
import { Invoice } from './Invoice';

export const InvoicePrint = (props) => {
   const [init, setInit] = useState(false);
   const [invoiceId, setInvoiceId] = useState(0);

   if (!init) {
      setInit(true);
      const query = new URLSearchParams(props.location.search);
      const id = query.get('id');
      setInvoiceId(id);
   }

   if (invoiceId > 0) {
      return (<Invoice invoiceId={invoiceId} />);
   }
   else {
      return (<div></div>);
   }

}