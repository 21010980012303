import React from 'react';
import 'primeflex/primeflex.css';
import logo from '../../logo.png';
import { API_Root } from "../../globalState";
import axios from 'axios'
import { Card } from 'primereact/card';

export class TripReview extends React.PureComponent {

   constructor(props) {
      super(props);
      this.state = {
      }
   }

   formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   }

   splitOnCR = (s) => {
      if (!s || (s && s.trim().length === 0)) {
         return [];
      }

      return s?.split(/\r?\n/);
   }

   splitVehicleNotes = (s) => {
      return this.splitOnCR(s)?.map((n, i) => {
         return (<div key={i} className="w-full"><span className="ml-4">{n}</span></div>);
      })
   }

   numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }

   formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   getVehicleType = (v) => {
      let rtn = '';
      let src = this.props.trip.source.name ?? "Private address";
      let dest = this.props.trip.destination.name ?? "Private address";
      if (v.tripVehicleId) {
         switch (v.tripVehicleId) {
            case 1:
               rtn = `(Deliver to ${dest})`;
               break;
            case 2:
               rtn = `(Return to ${src})`;
               break;
            case 3:
               rtn = `(Chase Car)`;
               break;
         }
      }
      return rtn;
   }

   getVehicleMessage = () => {
      var there = 0;
      var back = 0;
      var round = 0;

      this.props.trip?.trip.TripVehicles?.forEach((v, i) => {
         switch (v.tripVehicleId) {
            case 1:
               there++;
               break;
            case 2:
               back++;
               break;
            case 3:
               round++;
               break;
         }
      });

      if (there > 0 && (back == 0 && round == 0)) {
         return <div className="text-red-400 font-italic mt-2">Warning: Alternate return transportation will be required.</div>;
      } else if (back > 0 && (there == 0 && round == 0)) {
         return <div className="text-red-400 font-italic mt-2">Warning: Alternate pickup transportation will be required.</div>;
      } else {
         return <></>;
      }

   }

   render() {
      console.log('review trip', this.props.trip);
      return (
         <div className="p-4">
            <div className="grid">
               <div className="col-12">
                  <div className="w-full">
                     <div className="w-full"><span className="font-semibold mr-2">Requested Delivery:</span>{this.formatDate(this.props.trip.trip.requestedDeliveryDateTime)} - {this.formatDate(this.props.trip.trip.requestedDeliveryDateTimeEnd)}</div>
                  </div>
               </div>
            </div>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Vehicles
            </div>
            {this.props.trip?.trip.TripVehicles?.map((v, i) => {
               if (v.vehicleYear || v.vehicleMake || v.vehicleModel) {
                  return (<div key={i} className="mt-2 pt-0"><div className=" mt-0 pt-0 w-full">
                     <span className="font-bold">{v.vehicleVIN}</span> - {v.vehicleYear} {v.vehicleMake} {v.vehicleModel} {this.getVehicleType(v)}
                  </div>
                     {this.getVehicleMessage()}
                  </div>);
               }
               else {
                  return (<div key={i} className="mt-2 pt-0"><div className=" mt-0 pt-0 w-full">
                     <span className="font-bold">Unspecified Vehicle</span> - {this.getVehicleType(v)}
                  </div>
                     {this.getVehicleMessage()}
                  </div>);
               }
            })}

            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Route
            </div>
            <div className="grid">
               <div className="col-5">
                  <div className="w-full">
                     <div className="w-full">{this.props.trip.source.name ?? "Private address"}</div>
                     <div className="w-full">{this.props.trip.source.formatted_address}</div>
                  </div>
               </div>
               <div className="col-2 text-center vertical-align-middle">
                  <div className="w-full">
                     <div className="text-center w-full"><i className="pi pi-arrow-right text-2xl" style={{ color: 'slateblue' }}></i></div>
                     <div className="text-center w-full">{this.props.trip.trip.estimatedDistance}</div>
                     <div className="text-center w-full">{this.props.trip.trip.estimatedDuration}</div>
                  </div>
               </div>
               <div className="col-5 pl-4">
                  <div className="w-full ml-4">
                     <div className="w-full">{this.props.trip.destination.name ?? "Private address"}</div>
                     <div className="w-full">{this.props.trip.destination.formatted_address}</div>
                  </div>
               </div>
            </div>
         </div>
      );
   }
}