import React, { useState, useEffect, useRef } from 'react';
import { TripHeader } from './TripHeader';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import axios from 'axios'
import 'primeflex/primeflex.css';
import { API_Root } from "../globalState";

export const AssignTrip = (props) => {
   const [porters, setPorters] = useState();
   const [porter, setPorter] = useState();
   const toast = useRef(null);

   useEffect(() => {
      getPortersForTrip(props.trip.dealershipId, props.trip.departDateTimeStart, props.trip.requestedDeliveryDateTimeEnd, props.trip.sourceDealershipId);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getPortersForTrip = (dealershipId, tripDate, tripEnd, sourceId) => {
      var apiUrl = `${API_Root}api/dealership/getAvailablePortersFromSource?dealershipId=${dealershipId}&tripDate=${tripDate}&tripEnd=${tripEnd}&sourceId=${sourceId}`;
      axios.get(apiUrl).then((values) => {
         console.log('porters', values.data);
         var pArr = values.data;

         pArr.forEach((p) => {
             p.fullName = p.firstName + ' ' + p.lastName;

             if (p.distanceFromSource > 0.0) {
                 p.fullName += ` (${(Math.round(p.distanceFromSource * 10) / 10).toFixed(1)} mi)`;
             }
         });

         setPorters(values.data);
      });
   }

   const assignPorter = () => {
      var apiUrl = `${API_Root}api/trip/AssignPorterToVehicle?tripId=${props.trip.id}&porterId=${porter.id}&vehicleId=${props.vehicle.id}`;
      axios.get(apiUrl).then((values) => {
         console.log('assign porter', values.data);
         var success = values.data;
         if (success) {
            toast.current.show({ severity: 'success', summary: 'Driver Assigned', detail: 'Driver successfully assigned to this trip', life: 3000 });
         } else {
            toast.current.show({ severity: 'warning', summary: 'Error', detail: 'Driver unable to be assigned to this trip', life: 3000 });
         }
         props.onAssign();
      });
   }

   const getPorterDropdown = () => {

      if (porters && porters.length > 0) {
         return (<Dropdown value={porter} onChange={(e) => setPorter(e.value)} options={porters} optionLabel="fullName"
            itemTemplate={optionTemplate} placeholder="Select a porter" className="w-24rem" panelClassName="w-24rem" />);
      }
      else {
         return (<div className="font-bold text-red-400">No available porters found</div>);
      }
   }

   const optionTemplate = (option) => {
      let left = "col-2 border-round-left-3xl shadow-2 opacity-80 font-bold text-0 text-center " +
         (option.hoursSinceLastTrip > 48 || option.hoursSinceLastTrip === 0 ? "bg-green-400" :
         option.hoursSinceLastTrip >= 24 ? "bg-yellow-400" :
            "bg-red-400");

      let right = "col-2 border-round-right-3xl shadow-2 opacity-80 font-bold text-0 text-center " +
         (option.hoursBeforeNextTrip > 48 || option.hoursBeforeNextTrip === 0 ? "bg-green-400" :
         option.hoursBeforeNextTrip >= 24 ? "bg-yellow-400" :
            "bg-red-400");
            //<div className={left}>
            //   {option.hoursSinceLastTrip}
            //</div>
            //<div className={right}>
            //   {option.hoursBeforeNextTrip}
            //</div>
      return (
         <div className="grid">
            <div className="col-8">
               {option.fullName}
            </div>

         </div>
      );
   }

   return (
      <>
         <Toast ref={toast} />
         <Card>
            <TripHeader trip={props.trip}></TripHeader>
         </Card>
         <div className="grid mt-4">
            <div className="col-8 text-right">
               {getPorterDropdown()}
            </div>
            <div className="col-4 text-right">
               <Button label="Assign" icon="pi pi-save" onClick={() => assignPorter()} />
            </div>
         </div>
      </>
   );
}