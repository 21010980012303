import React, { useEffect, useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../../globalState";
import axios from 'axios'
import { Toast } from 'primereact/toast';

export const AddVehicle = ({ value, vehicleType, vehicleTypeText, onAdd, onCancel }) => {
   const [vin, setVin] = React.useState();
   const [make, setMake] = React.useState();
   const [model, setModel] = React.useState();
   const [year, setYear] = React.useState();
   const [notes, setNotes] = React.useState('');
   const [stockNumber, setStockNumber] = React.useState();
   const [chaseCar, setChaseCar] = React.useState();
   const toast = useRef(null);

   const getVINInfo = () => {
      var apiUrl = `${API_Root}api/trip/GetVehicleInfo?vin=${vin}`;
      axios.get(apiUrl).then((values) => {
         console.log('vehicle info', values);
         var vehicleInfo = values.data;

         setMake(vehicleInfo.make);
         setModel(vehicleInfo.model);
         setYear(vehicleInfo.year);
         setNotes(vehicleInfo.notes);

      });
   }

   const handleVINKeyDown = (event) => {
      if (event.key === 'Enter') {
         getVINInfo();
      }
   }

   //useEffect(() => {
   //   console.log('veh value', value);
   //   setVin(value.vehicleVIN);
   //   setMake(value.vehicleMake);
   //   setModel(value.vehicleModel);
   //   setYear(value.vehicleYear);
   //   setNotes(value.vehicleNotes);
   //}, [value]);

   const handleAddButton = () => {
      if (vehicleType && vehicleType.validateInfo) {
         if (!vin || vin.length < 10) {
            toast.current.show({ severity: 'error', summary: 'VIN Required', detail: 'Please provide a valid VIN', life: 3000 });
            return;
         }

         if (!stockNumber) {
            toast.current.show({ severity: 'error', summary: 'Stock Number Required', detail: 'Please provide a stock number', life: 3000 });
            return;
         }

         if (!make) {
            toast.current.show({ severity: 'error', summary: 'Vehicle Make Required', detail: 'Please provide the make of the vehicle', life: 3000 });
            return;
         }

         if (!model) {
            toast.current.show({ severity: 'error', summary: 'Vehicle Model Required', detail: 'Please provide the model of the vehicle', life: 3000 });
            return;
         }
      }

      let dto = {
         vehicleVIN: vin ?? '',
         vehicleMake: make ?? '',
         vehicleModel: model ?? '',
         vehicleYear: year ?? '',
         vehicleNotes: notes ?? '',
         stockNumber: stockNumber ?? '',
         tripVehicleId: vehicleType?.id
      }
      onAdd(dto);
   }

   const getStockNumberClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((stockNumber && stockNumber.length > 0) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getVINClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((vin && vin.length > 10) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getMakeClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((make && make.length > 0) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getModelClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((model && model.length > 0) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   return (
      <>
         <div className="p-2">
            {vehicleTypeText}
            <div className="field w-24rem lg:w-30rem">
               <label htmlFor="vin">VIN</label>
               <div className="grid">
                  <div className="field col-8">
                     <InputText id="vin"
                        value={vin ?? ""}
                        onChange={(e) => setVin(e.target.value)}
                        onKeyDown={handleVINKeyDown}
                        className={getVINClass()} />
                  </div>
                  <div className="col-4">
                     <Button className="p-2" icon="pi pi-search" onClick={() => getVINInfo()} />
                  </div>
               </div>
            </div>
            <div className="grid">
               <div className="col-3">
                  <div className="field">
                     <label htmlFor="color">Stock Number</label>
                     <input id="color"
                        value={stockNumber ?? ""}
                        onChange={(e) => setStockNumber(e.target.value)}
                        className={ getStockNumberClass() } />
                  </div>
               </div>
               <div className="col-3">
                  <div className="field">
                     <label htmlFor="year">Year</label>
                     <input id="year"
                        value={year ?? ""}
                        onChange={(e) => setYear(e.target.value)}
                        className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                  </div>
               </div>
               <div className="col-3">
                  <div className="field">
                     <label htmlFor="make">Make</label>
                     <input id="make"
                        value={make ?? ""}
                        onChange={(e) => setMake(e.target.value)}
                        className={getMakeClass()} />
                  </div>
               </div>
               <div className="col-3">
                  <div className="field">
                     <label htmlFor="model">Model</label>
                     <input id="model"
                        value={model ?? ""}
                        onChange={(e) => setModel(e.target.value)}
                        className={getModelClass()} />
                  </div>
               </div>
            </div>
         </div>
         <div className="w-full text-right">
            <Button label="Cancel" className="m-2" icon="pi pi-cancel" onClick={() => onCancel()} />
            <Button label="Save" className="m-2" icon="pi pi-plus" onClick={() => handleAddButton()} />
         </div>
         <Toast ref={toast} />
      </>);

};