import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputNumber } from 'primereact/inputnumber';
import { InsuranceFile } from './dealership/InsuranceFile';

import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../globalState";

export const DealerAdmin = () => {
   const [users, setUsers] = useState([]);
   const [invoiceSettings, setInvoiceSettings] = useState([]);
   const [showAddUser, setShowAddUser] = useState(false);
   const [addEmail, setAddEmail] = useState('');
   const [addType, setAddType] = useState('');
   const [rateType, setRateType] = useState('');
   const [rateTypes, setRateTypes] = useState([]);
   const [invoiceSetting, setInvoiceSetting] = useState(1);
   const [invoiceEmail, setInvoiceEmail] = useState('');
   const [rate, setRate] = useState('0.35');
   const [minFee, setMinFee] = useState('50.00');
   const [autoAssign, setAutoAssign] = useState(true);
   const [delay, setDelay] = useState(10);
   const [insuranceFile, setInsuranceFile] = useState(0);
   const [replyEmail, setReplyEmail] = useState('');
   const [curDealership, setCurDealership] = useState(undefined);
   const [init, setInit] = useState(false);
   const [user] = useGlobalState(g_user);
   const [editUser, setEditUser] = useState();
   const [userRate, setUserRate] = useState();
   const [userRateMethod, setUserRateMethod] = useState();
   const [showEditUser, setShowEditUser] = useState(false);

   useEffect(() => {
      if (!init) {
         setInit(true);
         selectDealership(user.adminDealerships[0]);
         let rt = [{ id: 'm', name: 'Per Mile' },
            { id: 'h', name: 'Per Hour' }];

         setRateTypes(rt);
         setRateType(rt[0].id);
         getInvoiceSettings();
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const getDealershipDropdown = () => {
      if (user?.adminDealerships !== undefined && user?.adminDealerships?.length > 1) {
         return <div className="flex">
            <label htmlFor="dealerships"></label>
            <Dropdown value={curDealership} onChange={(e) => selectDealership(e.value)} options={user.adminDealerships} optionLabel="dealershipName"
               placeholder="Select a dealership" className="" />
         </div>;
      }
      else {
         return <div></div>;
      }
   }

   const selectDealership = (sel) => {
      setCurDealership(sel);
      getUsersForDealership(sel?.id);
      getSettings(sel?.id);
   }

   const getUsersForDealership = (id) => {
      var apiUrl = `${API_Root}api/dealership/getUsers?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('users', values.data);
         setUsers(values.data);
      });
   }

   const getInvoiceSettings = (id) => {
      var apiUrl = `${API_Root}api/dealership/GetInvoiceSettings`;
      axios.get(apiUrl).then((values) => {
         setInvoiceSettings(values.data);
      });
   }

   const getSettings = (id) => {
      setRateType('');
      setRate('0.35');
      setReplyEmail('');
      setAutoAssign(false);
      setDelay(10);
      setMinFee('50');
      setInvoiceSetting(1);
      setInvoiceEmail('');
      setInsuranceFile(0);

      var apiUrl = `${API_Root}api/dealership/getSettings?id=${id}`;
      axios.get(apiUrl).then((values) => {
         let settings = values.data;
         if (settings) {
            setRateType(settings.rateMethod);
            setRate(settings.rate);
            setReplyEmail(settings.replyEmailAddress ?? '');
            setAutoAssign(settings.autoAssignment);
            setDelay(settings.autoAssignDelayMinutes);
            setMinFee(settings.minFee);
            setInvoiceSetting(settings.invoiceSettingId);
            setInvoiceEmail(settings.invoiceEmail ?? '');
            setInsuranceFile(settings.insuranceCardFile);
         }
      });
   }

   const saveSettings = () => {
      var apiUrl = `${API_Root}api/dealership/saveSettings`;

      var dto = {
         dealershipId: curDealership.id,
         rateMethod: rateType,
         rate: rate,
         replyEmailAddress: replyEmail,
         autoAssignment: autoAssign,
         autoAssignDelayMinutes: delay,
         minFee: minFee,
         invoiceSettingId: invoiceSetting,
         invoiceEmail: invoiceEmail,
         insuranceCardFile: insuranceFile
      };

      axios.post(apiUrl, dto).then((values) => {
      });
   }

   const deleteUser = (id) => {
      var apiUrl = `${API_Root}api/dealership/deleteUserReference?id=${id}`;
      axios.get(apiUrl).then((values) => {
         getUsersForDealership(curDealership.id);
      });
   }

   const addUser = () => {
      var apiUrl = `${API_Root}api/user/AddDealershipUser?email=${addEmail}&dealerId=${curDealership.id}&type=${addType}`;
      axios.get(apiUrl).then((values) => {
         setShowAddUser(false);
         setAddEmail('');
         getUsersForDealership(curDealership.id);
      });
   }

   const nameTemplate = (rowData) => {
      return `${rowData.firstName} ${rowData.lastName}`;
   };

   const typeTemplate = (rowData) => {
      if (rowData.isPorter) {
         return 'Driver';
      }
      else {
         return 'Admin';
      }
   };

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   const editDealerUser = (user) => {
      setEditUser(user);
      setUserRate(user.rate);
      setUserRateMethod(user.rateMethod);
      setShowEditUser(true);
   }

   const saveDealerUser = () => {
      let user = editUser;
      user.rate = userRate;
      user.rateMethod = userRateMethod;

      var apiUrl = `${API_Root}api/dealership/updateUser`;
      axios.post(apiUrl, user).then((values) => {
         let newUsers = [...users];
         setUsers(newUsers);
         setShowEditUser(false);
      });
   }

   const rateTemplate = (rowData) => {
      if (!rowData.isPorter) {
         return (<></>);
      }

      let value = "-";
      if (rowData.rate) {
         value = `${formatter.format(rowData.rate)} / ${rowData.rateMethod}`;
      }

      return (
         <div className="w-full">
            <div className="w-full text-left cursor-pointer" onClick={() => editDealerUser(rowData)}>
               {value}
            </div>
         </div>
      );
   };

   const actionTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">
               <Button className="p-2" icon="pi pi-trash" link onClick={() => deleteUser(rowData.referenceId)} />
            </div>
         </div>
      );
   };

   const addButtonTemplate = () => {
      return <Button className="p-2 mr-2" label="Add User" icon="pi pi-plus-circle" link onClick={() => setShowAddUser(true)} />;
   }

   const invoicingTemplate = () => {
      if (invoiceSetting == 1) {
         return (<div className="grid mt-4">
            <div className="col-3 text-right pr-2 pt-3">
               <label>Invoicing Type</label>
            </div>
            <div className="col-3 text-left">
               <Dropdown value={invoiceSetting} onChange={(e) => setInvoiceSetting(e.value)} options={invoiceSettings} optionLabel="typeName" optionValue="id" />
            </div>
            <div className="col-3 text-right pr-2 pt-3">
            </div>
            <div className="col-3 text-left">
            </div>
         </div>);
      }
      else {
         return (<div className="grid mt-4">
            <div className="col-3 text-right pr-2 pt-3">
               <label>Invoicing Type</label>
            </div>
            <div className="col-3 text-left">
               <Dropdown value={invoiceSetting} onChange={(e) => setInvoiceSetting(e.value)} options={invoiceSettings} optionLabel="typeName" optionValue="id" />
            </div>
            <div className="col-3 text-right pr-2 pt-3">
               <label>Send Invoices To</label>
            </div>
            <div className="col-3 text-left">
               <input id="invoiceEmail" value={invoiceEmail} onChange={(e) => setInvoiceEmail(e.target.value)} className={getInvoiceEmailClass()} />
            </div>
         </div>);
      }
   }

   const validateEmail = (e) => {
      return String(e)
         .toLowerCase()
         .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
   };

   const getReplyEmailClass = () => {
      return (replyEmail && !validateEmail(replyEmail)) ?
      "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full" :
      "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
   }

   const getInvoiceEmailClass = () => {
      return (invoiceEmail && !validateEmail(invoiceEmail)) ?
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full" :
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
   }

   return (
      <>
         <div className="w-full bg-white p-3">
            {getDealershipDropdown()}
         </div>
         <TabView>
            <TabPanel header="Users">
               <div className="card">
                  <div className="grid">
                     <div className="col-12 text-right">
                        {addButtonTemplate()}
                     </div>
                  </div>
                  <DataTable value={users} tableStyle={{ minWidth: '50rem' }}>
                     <Column header="Name" body={nameTemplate} ></Column>
                     <Column header="Email" style={{ minWidth: '12rem' }} field="email" />
                     <Column header="Phone" style={{ minWidth: '12rem' }} field="phone" />
                     <Column header="Type" style={{ minWidth: '12rem' }} body={typeTemplate} />
                     <Column header="Rate" style={{ minWidth: '12rem' }} body={rateTemplate} />
                     <Column header="" style={{ minWidth: '12rem' }} body={actionTemplate} />
                  </DataTable>
               </div>
            </TabPanel>
            <TabPanel header="Settings">
               <div className="w-7">
                  <div className="w-full border-500 border-bottom-1 border-top-none border-left-none border-right-none font-bold text-600 text-lg mt-2 mb-2">
                     Trip Assignment Settings
                  </div>
                  <div className="grid mt-2">
                     <div className="col-3 text-right pr-2">
                        <label>Use Automatic Assignments</label>
                     </div>
                     <div className="col-3 text-left">
                        <Checkbox checked={autoAssign} onChange={e => setAutoAssign(e.checked)} />
                     </div>
                     <div className="col-3 text-right pr-2 pt-3">
                        <label>Delay (minutes)</label>
                     </div>
                     <div className="col-3 text-left">
                        <InputNumber value={delay} onValueChange={(e) => setDelay(e.value)} min={0} max={999} />
                     </div>
                  </div>
                  <div className="grid mt-2">
                     <div className="col-3 text-right pr-2 pt-3">
                        <label>Reply To Email Address</label>
                     </div>
                     <div className="col-3 text-left">
                        <input id="email" value={replyEmail} onChange={(e) => setReplyEmail(e.target.value)} className={getReplyEmailClass()} />
                     </div>
                     <div className="col-6">
                     </div>
                  </div>
                  <div className="w-full border-500 border-bottom-1 border-top-none border-left-none border-right-none font-bold text-600 text-lg mt-2 mb-2">
                     Billing Settings
                  </div>
                  <div className="grid mt-4">
                     <div className="col-3 text-right pr-2 pt-3">
                        <label>Rate Type</label>
                     </div>
                     <div className="col-3 text-left">
                        <Dropdown value={rateType} onChange={(e) => setRateType(e.value)} options={rateTypes} optionLabel="name" optionValue="id" />
                     </div>
                     <div className="col-3 text-right pr-2 pt-3">
                        <label>Rate</label>
                     </div>
                     <div className="col-3 text-left">
                        <InputNumber value={rate} onValueChange={(e) => setRate(e.value)} min={0} max={99} minFractionDigits={2} maxFractionDigits={2} />
                     </div>
                  </div>
                  <div className="grid mt-2">
                     <div className="col-3 text-right pr-2 pt-3">
                        <label>Minimum Fee</label>
                     </div>
                     <div className="col-3 text-left">
                        <InputNumber value={minFee} onValueChange={(e) => setMinFee(e.value)} min={0} max={999} minFractionDigits={2} maxFractionDigits={2} />
                     </div>
                     <div className="col-3 text-right pr-2 pt-3">
                     </div>
                     <div className="col-3 text-left">
                     </div>
                  </div>
                  {invoicingTemplate()}
                  <div className="w-full border-500 border-bottom-1 border-top-none border-left-none border-right-none font-bold text-600 text-lg mt-2 mb-2">
                     Insurance Information
                  </div>
                  <InsuranceFile dealership={curDealership} fileId={insuranceFile} updateFileId={(id) => setInsuranceFile(id) } />
                  <div className="w-full text-right mt-2">
                     <Button label="Save" icon="pi pi-save" onClick={() => saveSettings()} />
                  </div>
               </div>
            </TabPanel>
         </TabView>
         <Dialog header="Edit User Rate" visible={showEditUser} style={{ width: '40vw' }} onHide={() => setShowEditUser(false)}>
            <div className="grid p-4 w-full">
               <div className="col-2 text-right pr-2 pt-3">
                  <label>Rate Type</label>
               </div>
               <div className="col-4 text-left">
                  <Dropdown value={userRateMethod} onChange={(e) => setUserRateMethod(e.value)} options={rateTypes} optionLabel="name" optionValue="id" />
               </div>
               <div className="col-2 text-right pr-2 pt-3">
                  <label>Rate</label>
               </div>
               <div className="col-4 text-left">
                  <InputNumber value={userRate} onValueChange={(e) => setUserRate(e.value)} min={0} max={99} minFractionDigits={2} maxFractionDigits={2} />
               </div>
            </div>

            <div className="w-full text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => saveDealerUser()} />
            </div>
         </Dialog>
         <Dialog header="Add Dealership User" visible={showAddUser} style={{ width: '50vw' }} onHide={() => setShowAddUser(false)}>
            <div className="grid mt-4">
               <div className="col-5">
                  <input id="addemail"
                     placeholder="Email Address"
                     value={addEmail}
                     onChange={(e) => setAddEmail(e.target.value)}
                     className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
               </div>
               <div className="col-7">
                  <div className="flex flex-wrap gap-3">
                     <div className="flex align-items-center">
                        <RadioButton inputId="addtypeporter" name="addtype" value="p" onChange={(e) => setAddType(e.value)} checked={addType === 'p'} />
                        <label htmlFor="addtypeporter" className="ml-2">Driver</label>
                     </div>
                     <div className="flex align-items-center">
                        <RadioButton inputId="addtypeadmin" name="addtype" value="a" onChange={(e) => setAddType(e.value)} checked={addType === 'a'} />
                        <label htmlFor="addtypeadmin" className="ml-2">Admin</label>
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => addUser()} />
            </div>
         </Dialog>
      </>
   );
}