import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Invoice } from './Invoice';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import { Calendar } from 'primereact/calendar';

export const Receivables = () => {
   const [invoices, setInvoices] = useState([]);
   const [showInvoice, setShowInvoice] = useState(false);
   const [showInvoicePaid, setShowInvoicePaid] = useState(false);
   const [invoiceToShow, setInvoiceToShow] = useState();
   const [invoiceToPay, setInvoiceToPay] = useState();
   const [payNote, setPayNote] = useState('');
   const [init, setInit] = useState(false);
   const printRef = useRef(null);

   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   useEffect(() => {
      if (!init) {
         setInit(true);
         getInvoices();
      }
   }, []);

   const getInvoices = () => {
      var apiUrl = `${API_Root}api/invoice/GetInvoices`;
      axios.get(apiUrl).then((values) => {
         console.log('invoices', values.data);
         setInvoices(values.data);
      });
   }

   const deleteInvoice = (id) => {
      var apiUrl = `${API_Root}api/invoice/DeleteInvoice?invoiceId=${id}`;
      axios.get(apiUrl).then((values) => {
         getInvoices();
      });
   }

   const showMarkPaid = (id) => {
      setPayNote('');
      setInvoiceToPay(id);
      setShowInvoicePaid(true);
   }

   const markAsPaid = () => {
      var apiUrl = `${API_Root}api/invoice/PaymentReceived?invoiceId=${invoiceToPay}&note=${payNote}`;
      axios.get(apiUrl).then((values) => {
         getInvoices();
      });
   }
   
   const showInvoiceDialog = (id) => {
      setInvoiceToShow(id);
      setShowInvoice(true);
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   }

   const formatLongDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: 'numeric',
         month: 'long',
         year: 'numeric'
      });
   }

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   const invoiceNumberTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">
               <div className="w-full font-bold">{rowData.id}</div>
            </div>
         </div>
      );
   };

   const createdDateTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">
               <div className="w-full">{formatDate(rowData.dateCreated)}</div>
            </div>
         </div>
      );
   };

   const dealerNameTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">
               <div className="w-full">{rowData.Dealership.dealershipName}</div>
            </div>
         </div>
      );
   };

   const costTemplate = (rowData) => {
      let total = rowData.expenseTotal + rowData.mileageTotal;

      return (
         <div className="w-full">
            <div className="w-full text-center">
               <div className="w-full">{formatter.format(total)}</div>
            </div>
         </div>
      );
   };

   const actionTemplate = (rowData) => {

      if (rowData.isPaid) {
         return (
            <div className="w-full">
               <div className="w-full text-right">
                  <Button className="p-2" icon="pi pi-print" link tooltip="Print" onClick={() => showInvoiceDialog(rowData.id)} />
               </div>
            </div>
         );
      }
      else {
         return (
            <div className="w-full">
               <div className="w-full text-right">
                  <Button className="p-2" icon="pi pi-trash" link tooltip="Delete" onClick={() => deleteInvoice(rowData.id)} />
                  <Button className="p-2" icon="pi pi-check-square" tooltip="Mark as paid" link onClick={() => showMarkPaid(rowData.id)} />
                  <Button className="p-2" icon="pi pi-print" tooltip="Print" link onClick={() => showInvoiceDialog(rowData.id)} />
               </div>
            </div>
         );
      }

   };

   const itemTemplate = (item) => {
         //<div className="flex justify-content-between flex-wrap flex-column xl:flex-row xl:align-items-start p-4 gap-4">
      if (item.isPaid) {
         return <div className="grid">
            <div className="col-2 line-through">
               {invoiceNumberTemplate(item)}
            </div>
            <div className="col-2 line-through">
               {createdDateTemplate(item)}
            </div>
            <div className="col-4 line-through">
               {dealerNameTemplate(item)}
            </div>
            <div className="col-2 line-through">
               {costTemplate(item)}
            </div>
            <div className="col-2">
               {actionTemplate(item)}
            </div>

         </div>;
      }
      else {
         return <div className="grid">
            <div className="col-2">
               {invoiceNumberTemplate(item)}
            </div>
            <div className="col-2">
               {createdDateTemplate(item)}
            </div>
            <div className="col-4">
               {dealerNameTemplate(item)}
            </div>
            <div className="col-2">
               {costTemplate(item)}
            </div>
            <div className="col-2">
               {actionTemplate(item)}
            </div>

         </div>;
      }
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="card">
               <div className="grid font-bold text-center">
                  <div className="col-2">
                     Invoice #
                  </div>
                  <div className="col-2">
                     Invoice Date
                  </div>
                  <div className="col-4">
                     Dealership
                  </div>
                  <div className="col-2">
                     Invoice Total
                  </div>
                  <div className="col-2">
                     
                  </div>

               </div>
               <DataScroller value={invoices} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Invoice" visible={showInvoice} style={{ width: '65vw' }} onHide={() => setShowInvoice(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <Invoice ref={printRef} invoiceId={invoiceToShow}></Invoice>
         </Dialog>
         <Dialog header="Mark Invoice Paid" visible={showInvoicePaid} style={{ width: '25vw' }} onHide={() => setShowInvoicePaid(false)}>
            <div className="grid mt-4">
               <div className="col-5">
                  <input id="paidnote"
                     placeholder="Payment note"
                     value={payNote}
                     onChange={(e) => setPayNote(e.target.value)}
                     className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
               </div>
            </div>
            <div className="w-full text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => markAsPaid()} />
            </div>
         </Dialog>
      </>
   );
}