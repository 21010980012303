import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Divider } from 'primereact/divider';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../globalState";
import axios from 'axios'
import { useRef } from 'react';

export const SuperAdmin = (props) => {
   const [placesearch, setPlaceSearch] = React.useState();
   const [source, setSource] = React.useState();
   const [places, setPlaces] = React.useState();
   const [userEmail, setUserEmail] = React.useState();

   const [init, setInit] = React.useState(false);

   const toast = useRef(null);

   const doPlaceSearch = () => {
      var apiUrl = `${API_Root}api/dealership/getDealerPlaces?query=${placesearch}`;
      axios.get(apiUrl).then((values) => {
         console.log('place search', values.data);
         setPlaces(values.data);
      });
   }

   const saveUser = () => {
      var apiUrl = `${API_Root}api/user/AddAdminUser`;
      var dto = {
         userEmail: userEmail,
         dealership: source
      };

      axios.post(apiUrl, dto).then((values) => {
         console.log('get dealership', values.data);
         if (values.data === true) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Admin user successfully created', life: 3000 });
         }
         else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Admin user could not be created', life: 3000 });
         }
      });
   }

   const handleSearchKeyDown = (event) => {
      if (event.key === 'Enter') {
         doPlaceSearch();
      }
   }

   const validateEmail = (e) => {
      return String(e)
         .toLowerCase()
         .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
   };

   const selectSource = (p) => {
      setSource(p);
      setPlaceSearch("");
      setPlaces(undefined);
   }

   const normalStyle = "w-5 text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
   const invalidStyle = "w-5 border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   const getEmailField = () => {
      if (userEmail && userEmail.length > 0 && validateEmail(userEmail)) {
         return (<input id="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} className={invalidStyle} />);
      }
   }

   const getAddUserButton = () => {
      if (source && userEmail && userEmail.length > 0 && validateEmail(userEmail)) {
         return (<Button label="Add Admin User" icon="pi pi-save" onClick={() => saveUser()} />);
      } else {
         return (<></>);
      }
   }

   return (
      <>
         <Toast ref={toast} />

         <TabView>
            <TabPanel header="Add Dealer Admin">
               <div className="grid">
                  <div className="col-6">
                     <div className="grid">
                        <div className="col-5">
                           <span className="p-input-icon-left w-full">
                              <i className="pi pi-search" />
                              <InputText placeholder="Search"
                                 className="w-full"
                                 value={placesearch}
                                 onChange={(e) => setPlaceSearch(e.target.value)}
                                 onKeyDown={handleSearchKeyDown} />

                           </span>
                        </div>
                        <div className="col-4">
                           <Button icon="pi pi-search" onClick={() => doPlaceSearch()} />
                        </div>
                     </div>
                     <div>
                        {places?.map((p, i) => {
                           return <Card className="mt-1" key={i} title={p.name} subTitle={p.formatted_address} onClick={() => selectSource(p)}></Card>;
                        })}
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="text-3xl">{source ? source?.name ?? "Private Address" : ""}</div>
                     <div className="text-xl">{source?.formatted_address}</div>
                     <Divider />
                     {getEmailField()}
                     <Divider />
                     <div className="w-full text-right mt-2">
                        {getAddUserButton()}
                     </div>
                  </div>
               </div>
            </TabPanel>
         </TabView>
      </>);

};