import React from 'react';
import 'primeflex/primeflex.css';
import './Invoice.css';
import '../print.css';
import logo from '../../logo.png';
import { API_Root } from "../../globalState";
import axios from 'axios'
import { Card } from 'primereact/card';

export class Invoice extends React.PureComponent {

   constructor(props) {
      super(props);
      this.state = {
         invoice: undefined,
         images: []
      }
      this.getCharges();
      this.getImages();
   }

   getCharges = () => {
      var apiUrl = `${API_Root}api/invoice/GetInvoice?invoiceId=${this.props.invoiceId}`;
      axios.get(apiUrl).then((values) => {
         console.log('invoice', values.data);
         this.setState({ invoice: values.data });
      });
   }

   getImages = () => {
      var apiUrl = `${API_Root}api/trip/GetImagesForInvoice?invoiceId=${this.props.invoiceId}`;
      axios.get(apiUrl).then((values) => {
         this.setState({ images: values.data });
      });
   }

   formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   }

   formatLongDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: 'numeric',
         month: 'long',
         year: 'numeric'
      });
   }

   numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }


   getUserFiles = (v, i) => {
      if (this.state.images) {
         let imgs = this.state.images.filter(img => img.vehicleId == v.id  && img.DollarAmount > 0);

         if (imgs && imgs.length > 0) {
            return (<>
               <div className="pagebreak"></div>
               <div>
                  <div className="w-full font-bold text-3xl mt-6">
                     Expenses for deal number {v.Trip.dealNumber}
                  </div>
                  {imgs.map((f, j) => {
                     let amt = f.DollarAmount > 0 ? <span className="font-bold mr-2">${f.DollarAmount}</span> : <></>;

                     return (
                        <div className="m-2 imagebox mt-4" key={j}>
                           <div style={{ width: "600px", height: "600px", backgroundImage: "url('" + f.contents.replace(/(\r\n|\n|\r)/gm, "") + "')", backgroundRepeat: "no-repeat", backgroundSize: "contain" }}></div>
                           <div className="w-full">
                              {amt} {f.fileDescription}
                           </div>
                        </div>);
                  })}
               </div>
            </>);
         }
         else {
            return (<></>);
         }
      }
      else {
         return (<></>);
      }
   }

   formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   chargesHeader = () => {
      return (<thead><tr>
         <th className="tcol1">Deal #</th>
         <th className="tcol2">Description</th>
         <th className="tcol3">Expenses</th>
         <th className="tcol4">Mileage</th>
      </tr></thead>);
   }

   chargesTemplate = (deal, desc, exp, mil, i) => {
      return (<tr key={i}>
         <td className="tcol1">{deal}</td>
         <td className="tcol2">{desc}</td>
         <td className="tcol3">{exp}</td>
         <td className="tcol4">{mil}</td>
      </tr>);
   }

   fillTemplateLines = () => {
      var numlines = this.state.invoice?.TripVehicles?.length ?? 0;
      var rtn = [];
      for (var i = numlines; i < 15; i++) {
         rtn.push(this.chargesTemplate('', '', '', '', i));
      }
      return rtn;
   }

   getUploadSection = () => {
      if (this.state.images && this.state.images.length > 0) {
         return (<>
            <div className="w-full border-bottom-2 text-xl font-semibold pl-4 mt-3 mb-3">
               Driver Uploads
            </div>
            <div className="w-full">
               {this.state.images.map((f, i) => {
                  return (this.getUserFiles(f, i));
               })};
            </div>
         </>);
      }
      else {
         return (<></>);
      }
   }

   getInvoiceVehicle = (id) => {
      if (this.state.invoice) {
         this.state.invoice.TripVehicles.forEach((v, i) => {
            if (v.id == id) {
               return v;
            }
         });
      }
      return null;
   }

   getVehicleType = (v) => {
      let rtn = '';
      if (v.VehicleType) {
         rtn = `(${v.VehicleType.typeName})`;
      }
      return rtn;
   }

   render() {
      return (
         <div className="p-4">
            <div className="w-full mb-1 mt-4">
               <div className="grid">
                  <div className="col-3">
                     <img src={logo} className="h-3rem" style={{ objectFit: "scale-down" }} />
                  </div>
                  <div className="col-9">
                     <div className="w-full text-6xl text-right font-bold">
                        Invoice
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full mt-4">
               <div className="grid">
                  <div className="col-6 font-bold">
                     Bill To:
                  </div>
                  <div className="col-6 text-right font-semibold">
                     Invoice Number: {this.state.invoice?.id} <span className="hiddenTitle">Mozo Invoice {this.state.invoice?.id}</span>
                  </div>
               </div>
            </div>
            <div className="w-full mb-3 p-0">
               <div className="grid">
                  <div className="col-6">
                     {this.state.invoice?.Dealership.dealershipName}<br />
                     {this.state.invoice?.Dealership.streetAddress}<br />
                     {this.state.invoice?.Dealership.city}, {this.state.invoice?.Dealership.stateAbbr} {this.state.invoice?.Dealership.zipCode}
                  </div>
                  <div className="col-6 text-right font-semibold">
                     {this.formatLongDate(new Date())}
                  </div>
               </div>
            </div>
            <table>
               {this.chargesHeader()}
               <tbody>
               {this.state.invoice?.TripVehicles.map((v, i) => {
                  return this.chargesTemplate(v.Trip.dealNumber, v.MileageCostDescription, this.formatter.format(v.ExpenseCost), this.formatter.format(v.MileageCost), i) 
               })}
                  {this.fillTemplateLines()}
               </tbody>
            </table>
            <div className="w-full text-right text-xl font-bold">
               Invoice Total: {this.formatter.format(this.state.invoice?.expenseTotal + this.state.invoice?.mileageTotal)}
            </div>
            {this.state.invoice?.TripVehicles.map((v, i) => {
               return this.getUserFiles(v, i)
            })}
         </div>
      );
   }
}