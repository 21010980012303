import React, { useState, useEffect } from 'react';
import { NavLink } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useCookies } from "react-cookie";
import { UserProfile } from './UserProfile';
import { SetPassword } from './SetPassword';
import useGlobalState, { g_user } from "../globalState";
import './NavMenu.css';
import logo from '../logo.png';

export const NavMenuBar = () => {
   const [user, setUser] = useGlobalState(g_user);
   const [cookies, setCookie] = useCookies(["user"]);
   const [showProfile, setShowProfile] = useState(false);
   const [showPassword, setShowPassword] = useState(false);
   const history = useHistory();

   const getLoggedInUser = () => {
      if (user !== null && user !== undefined) {
         return <div className="border-round flex surface-300 vertical-align-middle text-right">
            <div className="vertical-align-middle mt-2 cursor-pointer w-full"
               onClick={() => setShowProfile(true)}>
               <span className="mt-2 ml-4 mr-4">{userName()}</span>
            </div>
            <Button className="p-2" title="Log out" icon="pi pi-sign-out" link onClick={() => logOut()} />
         </div>;
      } else {
         return <NavLink tag={Link} className="text-dark" to="/login">Login</NavLink>;
      }
   }

    const userName = () => {
        if (user && user.user && user.user.firstName && user.user.lastName) {
            return `${user.user.firstName} ${user.user.lastName}`;
        }
        else {
            return "Profile";
        }
    }

   const logOut = () => {
      console.log('logout', cookies);
      setCookie("user", null, { path: "/" });
      setUser(null);
   }

   const hideProfile = () => {
      setShowProfile(false);
   }

   const getNavItems = () => {
      let trips = [];
      let admin = [];

      if (user?.adminDealerships !== null && user?.adminDealerships?.length > 0) {
         trips.push({
            label: 'Current Trips',
            icon: 'pi pi-map',
            command: () => {
               history.push('/trip-list');
            }
         });
         trips.push({
            label: 'Trip History',
            icon: 'pi pi-history',
            command: () => {
               history.push('/trip-history');
            }
         });
      }

      if (user?.porterDealerships !== null && user?.porterDealerships?.length > 0) {
         admin.push({
            label: 'Schedule',
            icon: 'pi pi-calendar',
            url: '/schedule'
         });
         trips.push({
            label: 'Available Trips',
            icon: 'pi pi-car',
            command: () => {
               history.push('/available-trips');
            }
         });
      }

      if (user?.user?.systemAdmin) {
         admin.push({
            label: 'System Admin',
            icon: 'pi pi-cog',
            command: () => {
               history.push('/sa');
            }
         });
         admin.push({
            label: 'Billing Admin',
            icon: 'pi pi-money-bill',
            command: () => {
               history.push('/billing');
            }
         });
      }

      if (user?.adminDealerships !== null && user?.adminDealerships?.length > 0) {
         admin.push({
            label: 'Dealer Admin',
            icon: 'pi pi-globe',
            command: () => {
               history.push('/dealer-admin');
            }
         });
      }

      let rtn = [];
      if (trips.length > 1) {
         rtn.push({
            label: 'Trips',
            items: trips
         });
      }
      else if (trips.length === 1) {
         rtn.push(trips[0]);
      }

      if (admin.length > 1) {
         rtn.push({
            label: 'Admin',
            items: admin
         });
      }
      else if (admin.length === 1) {
         rtn.push(admin[0]);
      }

      return rtn;
   }

   const getStart = () => {
      return <img src={logo} alt="logo" className="h-3rem" style={{ objectFit: "scale-down" }} />
   }

   useEffect(() => {
      if (user !== null && user !== undefined) {
         if ((user?.porterDealerships !== null && user?.porterDealerships?.length > 0) &&
            (!user.user.firstName || !user.user.lastName || !user.user.phone || !user.user.streetAddress ||
            !user.user.city || !user.user.stateAbbr || !user.user.zipCode)) {
            console.log('showing profile');
            setShowProfile(true);
         }
         else if (!user.user.pass) {
            setShowPassword(true);
         }
      }
   }, [user]);


   return (
      <header className="bg-white shadow-4">
         <Menubar model={getNavItems()} start={getStart()} end={getLoggedInUser()} />
         <Dialog header="User Profile" visible={showProfile} style={{ width: '80vw' }} onHide={() => setShowProfile(false)}>
            <UserProfile onClose={() => hideProfile()}></UserProfile>
         </Dialog>
         <Dialog header="Set Password" visible={showPassword} style={{ width: '50vw' }} onHide={() => setShowPassword(false)}>
            <SetPassword onClose={() => setShowPassword(false)}></SetPassword>
         </Dialog>
      </header>
   );
}
