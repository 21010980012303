import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Invoice } from './Invoice';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import { Calendar } from 'primereact/calendar';

export const Billables = (props) => {
   const [dealers, setDealers] = useState([]);
   const [invoice, setInvoice] = useState([]);
   const [showInvoice, setShowInvoice] = useState(false);
   const printRef = useRef(null);
   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   useEffect(() => {
      getBillables();
   }, []);

   const getBillables = () => {
      var apiUrl = `${API_Root}api/invoice/GetUnbilledTripsByDealer`;
      axios.get(apiUrl).then((values) => {
         console.log('dealers', values.data);
         setDealers(values.data);
      });
   }

   const createInvoice = (dealershipId) => {
      var apiUrl = `${API_Root}api/invoice/CreateInvoice?dealershipId=${dealershipId}`;
      axios.get(apiUrl).then((values) => {
         console.log('invoice', values.data);
         setInvoice(values.data);
         setShowInvoice(true);
         getBillables();
      });
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      var formattedDate = d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
      return formattedDate;
   };

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   const itemTemplate = (item) => {
      let label = "Invoice " + formatter.format(item.billableTotal);
      return <div className="">
         <div className="grid m-3 border-round-md border-2 border-50 border-solid surface-400 p-2 font-bold text-xl">
            <div className="col-9">
               {item.dealership.dealershipName}
            </div>
            <div className="col-3"><Button label={label} onClick={() => createInvoice(item.dealership.id)}></Button></div>
         </div>
         <div>
            {item.trips.map((t, i) => {
               return tripTemplate(t, i);
            })}
         </div>
      </div>
   }

   const tripTemplate = (t, i) => {
      return (<div key={i}>
         <div className="border-bottom-2 border-black-alpha-50 ml-5 mr-5 mt-2 text-lg font-bold" >{formatDate(t.trip.requestedDeliveryDateTime)} {t.trip.Dealership2.city}, {t.trip.Dealership2.stateAbbr} to {t.trip.Dealership1.city}, {t.trip.Dealership1.stateAbbr}</div>
         {t.drivers.map((v, j) => { return vehicleTemplate(v, j) })}
      </div>);
   }

   const vehicleTemplate = (v, i) => {
      return (<div key={i}>
         <div className="ml-6 mr-6 mt-2 font-bold">{v.user.firstName} {v.user.lastName}</div>
         {v.vehicles.map((veh, j) => {
            if (veh.vehicleVIN || veh.vehicleMake || veh.vehicleModel) {
               return <div key={j} className="ml-6 mr-6 mt-2"><span className="font-bold">{veh.vehicleVIN}</span> {veh.vehicleYear} {veh.vehicleMake} {veh.vehicleModel}</div>
            }
            else {
               return <div key={j} className="ml-6 mr-6 mt-2"><span className="font-bold">Unspecified Vehicle</span></div>
            }
         })}
         {chargesTemplate(v.vehicles[0].MileageCostDescription, '', formatter.format(v.mileageTotal), 0)}
         {v.vehicles.map((veh, k) => { return veh.TripFiles.map((f, j) => { return chargesTemplate(f.fileDescription, formatter.format(f.DollarAmount), '', j) }) })}
      </div>);
   }

   const chargesTemplate = (desc, exp, mil, i) => {
      return (<div key={i} className="grid ml-8 mr-8">
         <div className="col-8">{desc}</div>
         <div className="col-2">{exp}</div>
         <div className="col-2">{mil}</div>
      </div>);
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="card">
               <DataScroller value={dealers} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Invoice" visible={showInvoice} style={{ width: '65vw' }} onHide={() => setShowInvoice(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <Invoice ref={printRef} invoiceId={invoice?.id}></Invoice>
         </Dialog>
      </>
   );
}