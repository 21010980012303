import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from '../PrintTrip';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import { Calendar } from 'primereact/calendar';

export const TripHistory = () => {
   const [trips, setTrips] = useState([]);
   const [showPrint, setShowPrint] = useState(false);
   const [tripToPrint, setTripToPrint] = useState();
   const [dealerships, setDealerships] = useState(undefined);
   const [curDealership, setCurDealership] = useState(undefined);
   const [init, setInit] = useState(false);
   const [user,] = useGlobalState(g_user);
   const printRef = useRef(null);
   const [viewDate, setViewDate] = useState();

   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   const getDealerships = () => {
      var dealerArr = [];

      user.adminDealerships.forEach((d) => {
         dealerArr.push({ id: d.id, name: d.dealershipName, admin: true, porter: false });
      });

      user.porterDealerships.forEach((d) => {
         var match = undefined;
         dealerArr.forEach((a) => {
            if (a.id === d.id) {
               match = a;
            }
         });

         if (match !== undefined) {
            match.porter = true;
         } else {
            dealerArr.push({ id: d.id, name: d.dealershipName, admin: false, porter: true });
         }
      });

      setDealerships(dealerArr);
      if (dealerArr.length > 0) {
         setCurDealership(dealerArr[0]);
      }
   }

   useEffect(() => {
      if (!init) {
         setInit(true);
         setViewDate(new Date);
         getDealerships();
      }
   }, []);

   useEffect(() => {
      if (curDealership && viewDate) {
         getTripsForDealership(curDealership.id);
      }
   }, [curDealership, viewDate]);

   const getDealershipDropdown = () => {
      if (dealerships !== undefined && dealerships.length === 1) {
         return <div className="text-lg md:text-3xl">Trips For {dealerships[0].name}</div>;
      }
      else if (dealerships !== undefined && dealerships.length > 1) {
         return <div className="flex">
            <div className="col-2 text-right mr-3 mt-2">
               <label htmlFor="dealerships">Trips For</label>
            </div>
            <div className="col-8">
               <Dropdown value={curDealership} onChange={(e) => selectDealership(e.value)} options={dealerships} optionLabel="name"
                  placeholder="Select a dealership" className="" />
            </div>
         </div>;
      }
      else {
         return <div></div>;
      }
   }

   const selectDealership = (sel) => {
      setCurDealership(sel);
   }

   
   const getTripsForDealership = (id) => {
      let dt = new Date();
      if (viewDate) {
         dt = viewDate;
      }

      var apiUrl = `${API_Root}api/trip/GetHistoryForDealership?id=${id}&month=${dt.getMonth()}&year=${dt.getFullYear()}`;
      axios.get(apiUrl).then((values) => {
         console.log('trips', values.data);
         setTrips(values.data);
      });
   }

   const routeTemplate = (rowData) => {
      return (
        <div className="w-full text-center">{rowData.Dealership2.city}, {rowData.Dealership2.stateAbbr} to {rowData.Dealership1.city}, {rowData.Dealership1.stateAbbr}</div>
      );
   };

    const printTrip = (t) => {
      setTripToPrint(t);
      setShowPrint(true);
   }

   const requestedDateTimeTemplate = (rowData) => {
      var d = new Date(rowData.requestedDeliveryDateTime);
      var formattedDate = d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
      let cnt = rowData.TripVehicles.length;

      return (
         <div className="">
            <div className="w-full font-bold">{formattedDate} - {cnt} vehicles</div>
         </div>
      );

   };

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   const costTemplate = (rowData) => {
      let total = rowData.ExpenseCost + rowData.MileageCost;

      return (
         <div className="w-full">
            <div className="w-full text-center">
               <div className="w-full font-bold">{formatter.format(total)}</div>
            </div>
         </div>
      );
   };

   const actionTemplate = (rowData) => {

      return (
         <div className="w-full">
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-print" link onClick={() => printTrip(rowData)} />
            </div>
         </div>
      );
   };

   const addButtonTemplate = () => {
      return <Calendar className="mr-3" value={viewDate} onChange={(e) => setViewDate(e.value)} view="month" dateFormat="MM yy" />;
   }

   const itemTemplate = (item) => {
      return <div className="flex justify-content-between flex-wrap flex-column xl:flex-row xl:align-items-start p-4 gap-4">
         <div>
            {requestedDateTimeTemplate(item)}
         </div>
         <div>
            {routeTemplate(item)}
         </div>
         <div>
            {costTemplate(item)}
         </div>
         <div>
            {actionTemplate(item)}
         </div>

      </div>
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="grid mt-2 ml-2">
               <div className="col-6">
                  {getDealershipDropdown()}
               </div>
               <div className="col-6 text-right">
                  {addButtonTemplate()}
               </div>
            </div>
            <div className="card">
               <DataScroller value={trips} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Print Trip" visible={showPrint} style={{ width: '65vw' }} onHide={() => setShowPrint(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PrintTrip ref={printRef} trip={tripToPrint} dealer={curDealership}></PrintTrip>
         </Dialog>
      </>
   );
}