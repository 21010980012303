import React, { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";  
import 'primeicons/primeicons.css';
import { API_Root } from "../globalState";
import axios from 'axios'
import { useRef } from 'react';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

export const CreateTripComponent = (props) => {
   const [tripId, setTripId] = React.useState(0);
   const [dealership, setDealership] = React.useState();
   const [source, setSource] = React.useState();
   const [places, setPlaces] = React.useState();
   const [destination, setDestination] = React.useState();
   const [distance, setDistance] = React.useState();
   const [vin, setVin] = React.useState();
   const [make, setMake] = React.useState();
   const [model, setModel] = React.useState();
   const [year, setYear] = React.useState();
   const [notes, setNotes] = React.useState();
   const [color, setColor] = React.useState();
   const [deliverytime, setDeliveryTime] = React.useState();
   const [placesearch, setPlaceSearch] = React.useState('');
   const [contactname, setContactName] = React.useState();
   const [contactphone, setContactPhone] = React.useState();
   const [sourceContactName, setSourceContactName] = React.useState();
   const [sourceContactPhone, setSourceContactPhone] = React.useState();
   const [dealNumber, setDealNumber] = React.useState();
   const [chaseCar, setChaseCar] = React.useState(false);
   const [roundTrip, setRoundTrip] = React.useState(false);
   const [masterTimes, setMasterTimes] = React.useState();
   const [fromTimes, setFromTimes] = React.useState();
   const [toTimes, setToTimes] = React.useState();
   const [fromTime, setFromTime] = React.useState();
   const [toTime, setToTime] = React.useState();
   const [customNotes, setCustomNotes] = React.useState();
   const [selNotes, setSelNotes] = React.useState([]);
   const [allNotes, setAllNotes] = React.useState([]);
   const [numPorters, setNumPorters] = React.useState(1);
   const [showValidation, setShowValidation] = React.useState(false);
   const [messages, setMessages] = React.useState([]);

   const [init, setInit] = React.useState(false);

   const toast = useRef(null);

   const doPlaceSearch = () => {
      var apiUrl = `${API_Root}api/dealership/getPlaces?query=${placesearch}`;
      axios.get(apiUrl).then((values) => {
         console.log('place search', values.data);
         setPlaces(values.data);
      });
   }

   const getPorterNotesList = () => {
      var apiUrl = `${API_Root}api/trip/GetAllPorterNotes`;
      axios.get(apiUrl).then((values) => {
         console.log('porter notes', values.data);
         setAllNotes(values.data);
      });
   }

   const getVINInfo = () => {
      var apiUrl = `${API_Root}api/trip/GetVehicleInfo?vin=${vin}`;
      axios.get(apiUrl).then((values) => {
         console.log('vehicle info', values);
         var vehicleInfo = values.data;

         setMake(vehicleInfo.make);
         setModel(vehicleInfo.model);
         setYear(vehicleInfo.year);
         setNotes(vehicleInfo.notes);

      });
   }

   const getDistanceInfo = () => {
      var apiUrl = `${API_Root}api/trip/GetDistanceInfo?sourceId=${source.place_id}&destinationId=${destination.place_id}`;
      axios.get(apiUrl).then((value) => {
         console.log('distance info', value);
         setDistance(value.data);
      });
   }

   const clearSource = () => {
      setSource(undefined);
      setDistance(undefined);
   }

   const clearDestination = () => {
      setDestination(undefined);
      setDistance(undefined);
   }

   const getDealership = (id) => {
      var apiUrl = `${API_Root}api/dealership/get?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('get dealership', values.data);
         setDealership(values.data);
      });
   }

   const formatAMPM = (date) => {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      hours = hours < 10 ? '0' + hours : hours;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
   }

   const getExistingTrip = (id) => {
      var apiUrl = `${API_Root}api/trip/GetTrip?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('get trip', values.data);
         const trip = values.data;
         setTripId(trip.id);
         setVin(trip.vehicleVIN);
         setMake(trip.vehicleMake);
         setModel(trip.vehicleModel);
         setYear(trip.vehicleYear);
         setDistance({ miles: trip.estimatedMiles, minutes: trip.estimatedHours, estimatedDistance: trip.distance, estimatedDuration: trip.duration });
         setContactName(trip.contactName);
         setContactPhone(trip.contactNumber);
         setNotes(trip.vehicleNotes);
         setRoundTrip(trip.roundTrip);
         setChaseCar(trip.chaseCar);
         setSourceContactName(trip.sourceContactName);
         setSourceContactPhone(trip.sourceContactNumber);
         setDealNumber(trip.dealNumber);
         setCustomNotes(trip.porterNotes);
         setNumPorters(trip.numPorters);
         setSelNotes(trip.TripPorterNotes.map((n) => { return n.noteId }));

         //requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
         //requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),

         let timeFrom = new Date(trip.requestedDeliveryDateTime);
         let timeTo = new Date(trip.requestedDeliveryDateTimeEnd);

         setDeliveryTime(timeFrom);
         selectFromTime(formatAMPM(timeFrom));
         setToTime(formatAMPM(timeTo));

         setSource({
            name: trip.Dealership2.dealershipName,
            formatted_address: `${trip.Dealership2.streetAddress}, ${trip.Dealership2.city}, ${trip.Dealership2.stateAbbr} ${trip.Dealership2.zipCode}`,
            place_id: trip.Dealership2.googlePlaceId,
            geometry: {
               location: {
                  lat: trip.Dealership2.lat,
                  lng: trip.Dealership2.lon
               }
            }
         });


         setDestination({
            name: trip.Dealership1.dealershipName,
            formatted_address: `${trip.Dealership1.streetAddress}, ${trip.Dealership1.city}, ${trip.Dealership1.stateAbbr} ${trip.Dealership1.zipCode}`,
            place_id: trip.Dealership1.googlePlaceId,
            geometry: {
               location: {
                  lat: trip.Dealership1.lat,
                  lng: trip.Dealership1.lon
               }
            }
         });

      });
   }

   const validateSubmit = () => {
      var msgs = [];
      if (!source) {
         msgs.push('Please select an origin location');
      }
      if (!destination) {
         msgs.push('Please select a destination location');
      }
      if (!vin || vin.length < 11) {
         msgs.push('Please provide a valid vehicle VIN');
      }
      if (!make || make.length < 1) {
         msgs.push('Please provide the make of the vehicle');
      }

      if (!model || model.length === 0) {
         msgs.push('Please provide the model of the vehicle');
      }

      if (!deliverytime || deliverytime.length === 0) {
         msgs.push('Please provide the requested delivery date');
      }

      if (!fromTime || fromTime.length === 0) {
         msgs.push('Please provide the requested delivery time from');
      }

      if (!toTime || toTime.length === 0) {
         msgs.push('Please provide the requested delivery time to');
      }

      if (msgs.length > 0) {
         setMessages(msgs);
         setShowValidation(true);
         return false;
      }
      else {
         return true;
      }
   }

   const getMessages = () => {
      if (messages.length > 0) {
         return <>
            <div>Please correct the following issues before saving</div>
            <div>
               <ul className="text-red-400">
                  {messages.map((m) => <li>{m}</li>)}
               </ul>
            </div>
         </>
      }
      else {
         return <></>;
      }
   }

   const saveTrip = () => {

      if (!validateSubmit()) {
         return;
      }

      var apiUrl = `${API_Root}api/trip/SaveTrip`;
      var trip = {
         id: tripId ?? 0,
         dealershipId: props.dealershipId,
         createdBy: props.userId,
         vehicleVIN: vin,
         vehicleMake: make,
         vehicleModel: model,
         vehicleYear: year,
         estimatedMiles: distance.miles,
         estimatedHours: distance.minutes,
         contactName: contactname,
         contactNumber: contactphone,
         requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
         vehicleNotes: notes,
         estimatedDistance: distance.distance,
         estimatedDuration: distance.duration,
         roundTrip: roundTrip,
         chaseCar: chaseCar,
         requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),
         sourceContactName: sourceContactName,
         sourceContactNumber: sourceContactPhone,
         dealNumber: dealNumber,
         porterNotes: customNotes,
         numPorters: numPorters,
         TripPorterNotes: selNotes.map((n) => { return { noteId: n } })
      };

      var dto = {
         trip: trip,
         source: source,
         destination: destination
      };

      axios.post(apiUrl, dto).then((values) => {
         console.log('get dealership', values.data);
         if (values.data === true) {
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Trip successfully created', life: 3000 });
         }
         else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Trip could not be created', life: 3000 });
         }
         props.onCreated();
      });
   }

   const handleSearchKeyDown = (event) => {
      if (event.key === 'Enter') {
         doPlaceSearch();
      }
   }

   const handleVINKeyDown = (event) => {
      if (event.key === 'Enter') {
         getVINInfo();
      }
   }

   const getMasterTimes = () => {
      var time = moment('00:00', 'HH:mm');
      var lst = [];

      for (var i = 0; i < 48; i++) {
         lst.push({
            index: i,
            value: time.format("hh:mm A")
         });
         time.add(30, 'm');
      }
      console.log(lst);
      setMasterTimes(lst);
      setFromTimes(lst);
   }

   const selectFromTime = (val) => {
      setFromTime(val);
      var ind = 0;
      for (var i = 0; i < 48; i++) {
         if (masterTimes[i].value === val) {
            ind = i + 1;
            break;
         }
      }
      var toLst = masterTimes.slice(ind, 48);
      console.log('to times', toLst);
      setToTimes(toLst);
      setToTime(toLst[0].value);
   }

   const onNotesChange = (e) => {
      let _notes = [...selNotes];

      if (e.checked)
         _notes.push(e.value);
      else
         _notes.splice(_notes.indexOf(e.value), 1);

      setSelNotes(_notes);
   }

   if (!init) {
      getMasterTimes();
      getPorterNotesList();

      setInit(true);
   }

   useEffect(() => {
      if (props.tripId) {
         getExistingTrip(props.tripId);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [init]);

   const selectSource = (p) => {
      setSource(p);
      setPlaceSearch("");
      setPlaces(undefined);
   }

   const getSourceTab = () => {
      if (source === undefined) {
         if (dealership === undefined) {
            getDealership(1);
         }

         return (<>
            <div className="grid">
               <div className="col-5">
                  <span className="p-input-icon-left w-full">
                     <i className="pi pi-search" />
                     <InputText placeholder="Search"
                        className="w-full"
                        value={placesearch}
                        onChange={(e) => setPlaceSearch(e.target.value)}
                        onKeyDown={handleSearchKeyDown} />
                        
                  </span>
               </div>
               <div className="col-4">
                  <Button icon="pi pi-search" onClick={() => doPlaceSearch()} />
               </div>
            </div>
            <div>
               {places?.map((p, i) => {
                  return <Card className="mt-1" key={i} title={p.name} subTitle={p.formatted_address} onClick={() => selectSource(p)}></Card>;
               })}
            </div>
         </>);
      }
      else {
         if (destination !== undefined && distance === undefined) {
            getDistanceInfo();
         }

         return (
            <Card className="mt-1">
               <div className="grid">
                  <div className="col-5">
                     <div className="text-3xl">{source ? source?.name ?? "Private Address" : ""}</div>
                     <div className="text-xl">{source?.formatted_address}</div>
                  </div>
                  <div className="col-2 text-center vertical-align-middle h-full">
                     <div className="text-center w-full"><i className="pi pi-arrow-right text-2xl" style={{ color: 'slateblue' }}></i></div>
                     <div className="text-center w-full">{distance?.distance}</div>
                     <div className="text-center w-full">{distance?.duration}</div>
                  </div>
                  <div className="col-5">
                     <div className="text-2xl">{destination ? destination?.name ?? "Private Address" : ""}</div>
                     <div className="text-xl">{destination?.formatted_address}</div>
                  </div>
               </div>
               <div className="w-full text-right"><Button label="Search Again" link onClick={() => clearSource()} /></div>
            </Card>
         );
      }
   }

   const selectDestination = (p) => {
      setDestination(p);
      setPlaceSearch("");
      setPlaces(undefined);
   }

   const getDestinationTab = () => {
      if (destination === undefined) {
         if (dealership === undefined) {
            getDealership(1);
         }

         return (<>
            <div className="grid">
               <div className="col-5"> 
                  <span className="p-input-icon-left w-full">
                     <i className="pi pi-search" />
                     <InputText placeholder="Search"
                        className="w-full"
                        value={placesearch}
                        onChange={(e) => setPlaceSearch(e.target.value)}
                        onKeyDown={handleSearchKeyDown} />
                  </span>
               </div>
               <div className="col-4">
                  <Button icon="pi pi-search" onClick={() => doPlaceSearch()} />
               </div>
            </div>
            <div>
               {places?.map((p, i) => {
                  return <Card className="mt-1" key={i} title={p.name} subTitle={p.formatted_address} onClick={() => selectDestination(p) }></Card>;
               })}
            </div>
         </>);
      }
      else {
         if (source !== undefined && distance === undefined) {
            getDistanceInfo();
         }

         return (
            <Card className="mt-1">
               <div className="grid">
                  <div className="col-5">
                     <div className="text-3xl">{source ? source?.name ?? "Private Address" : ""}</div>
                     <div className="text-xl">{source?.formatted_address}</div>
                  </div>
                  <div className="col-2 text-center vertical-align-middle h-full">
                     <div className="text-center w-full"><i className="pi pi-arrow-right text-2xl" style={{ color: 'slateblue' }}></i></div>
                     <div className="text-center w-full">{distance?.distance}</div>
                     <div className="text-center w-full">{distance?.duration}</div>
                  </div>
                  <div className="col-5">
                     <div className="text-2xl">{destination ? destination?.name ?? "Private Address" : ""}</div>
                     <div className="text-xl">{destination?.formatted_address}</div>
                  </div>
                  </div>
               <div className="w-full text-right"><Button label="Search Again" link onClick={() => clearDestination()} /></div>
            </Card>
            );
      }
   }

   const getToTimeClass = () => {
      return (toTime) ? "ml-2" : "ml-2 border-red-400";
   }

   const getFromTimeClass = () => {
      return (fromTime) ? "ml-2" : "ml-2 border-red-400";
   }

   const getDeliveryDateClass = () => {
      return (deliverytime) ? "" : "border-red-400";
   }

   const getVINClass = () => {
      return (vin && vin.length > 10) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getMakeClass = () => {
      return (make && make.length > 0) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getModelClass = () => {
      return (model && model.length > 0) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   return (
      <>
         <Toast ref={toast} />
         <div className="w-full text-right mt-2">
            <Button label="Save" icon="pi pi-save" onClick={() => saveTrip()} />
         </div>

      <TabView>
         <TabPanel header="Source Location">
            {getSourceTab()}
         </TabPanel>
         <TabPanel header="Destination">
            {getDestinationTab()}
         </TabPanel>
         <TabPanel header="Vehicle">
            <div>
               <div className="field">
                  <label htmlFor="vin">VIN</label>
                  <div className="grid">
                     <div className="field col-5">
                           <InputText id="vin"
                              value={vin}
                              onChange={(e) => setVin(e.target.value)}
                              onKeyDown={handleVINKeyDown}
                              className={getVINClass()} />
                     </div>
                     <div className="col-4">
                        <Button className="p-2" icon="pi pi-search" onClick={() => getVINInfo()} />
                     </div>
                  </div>
                  </div>
                  <div className="grid">
                     <div className="col-6">
                        <div className="field">
                           <label htmlFor="make">Make</label>
                              <input id="make"
                                 value={make}
                                 onChange={(e) => setMake(e.target.value)}
                                 className={getMakeClass()} />
                        </div>
                     </div>
                     <div className="col-6">
                        <div className="field">
                           <label htmlFor="model">Model</label>
                              <input id="model"
                                 value={model}
                                 onChange={(e) => setModel(e.target.value)}
                                 className={getModelClass()} />
                        </div>
                     </div>
               </div>
                  <div className="grid">
                     <div className="col-6">
                        <div className="field">
                           <label htmlFor="year">Year</label>
                           <input id="year"
                              value={year}
                              onChange={(e) => setYear(e.target.value)}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                        <div className="field">
                           <label htmlFor="color">Color</label>
                           <input id="color"
                              value={color}
                              onChange={(e) => setColor(e.target.value)}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                     </div>
                     <div className="col-6">
                        <div className="field">
                           <label htmlFor="notes">Notes</label>
                           <div>
                              <InputTextarea autoResize
                                 id="notes"
                                 value={notes}
                                 onChange={(e) => setNotes(e.target.value)}
                                 rows={5}
                                 cols={60} className="w-full" />
                           </div>
                        </div>
                     </div>
                  </div>
            </div>
         </TabPanel>
         <TabPanel header="Additional Info">
            <div className="flex flex-wrap justify-content-left gap-3">
               <div className="flex align-items-center">
                     <Checkbox inputId="roundtrip" checked={roundTrip} onChange={e => setRoundTrip(e.checked)} />
                     <label htmlFor="roundtrip" className="ml-2">Round trip</label>
               </div>
               <div className="flex align-items-center">
                     <Checkbox inputId="chasecar" checked={chaseCar} onChange={e => setChaseCar(e.checked)} />
                     <label htmlFor="chasecar" className="ml-2">Chase car needed</label>
               </div>
            </div>
            <div className="field mt-4">
               <label htmlFor="deliverytime">Requested Delivery Time Range</label>
               <div>
                  <Calendar value={deliverytime} onChange={(e) => setDeliveryTime(e.value)} placeholder="Select Date" inputClassName={getDeliveryDateClass()} />
                  <Dropdown value={fromTime} onChange={(e) => selectFromTime(e.value)} options={fromTimes} optionLabel="value"
                     placeholder="From" className={getFromTimeClass()} />
                  <Dropdown value={toTime} onChange={(e) => setToTime(e.value)} options={toTimes} optionLabel="value"
                     placeholder="To" className={getToTimeClass()} />
               </div>
            </div>

            <div className="grid mt-2">
                  <div className="col-4">
                     <div className="field">
                        <label htmlFor="dealnumber">Deal Number</label>
                        <input id="dealnumber"
                           value={dealNumber}
                           onChange={(e) => setDealNumber(e.target.value)}
                           className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                     </div>
                     <div className="field">
                        <label htmlFor="dealnumber">Number of Porters Needed</label>
                        <InputNumber value={numPorters} onValueChange={(e) => setNumPorters(e.value)} min={1} max={20} />
                     </div>
                  </div>
                  <div className="col-4">
                     <div className="field">
                        <label htmlFor="sourcecontactname">Source Contact Name</label>
                        <input id="sourcecontactname"
                           value={sourceContactName}
                           onChange={(e) => setSourceContactName(e.target.value)}
                           className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                     </div>
                     <div className="field">
                        <label htmlFor="sourcecontactphone">Source Contact Phone</label>
                        <InputMask id="sourcecontactphone"
                           mask="(999) 999-9999"
                           value={sourceContactPhone}
                           onChange={(e) => setSourceContactPhone(e.target.value)}
                           className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                     </div>
                  </div>
                  <div className="col-4">
                     <div className="field">
                        <label htmlFor="contactname">Destination Contact Name</label>
                        <input id="contactname"
                           value={contactname}
                           onChange={(e) => setContactName(e.target.value)}
                           className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                     </div>
                     <div className="field">
                        <label htmlFor="contactphone">Destination Contact Phone</label>
                        <InputMask id="contactphone"
                           mask="(999) 999-9999"
                           value={contactphone}
                           onChange={(e) => setContactPhone(e.target.value)}
                           className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                     </div>
                  </div>
            </div>
         </TabPanel>
            <TabPanel header="Notes">
               <label htmlFor="">Standard Notes</label>
               <ScrollPanel style={{ width: '100%', height: '150px' }}>
                  {allNotes.map((n, i) => {
                     return (<div key={i}>
                        <Checkbox
                           value={n.id}
                           onChange={onNotesChange}
                           checked={selNotes.includes(n.id)} />
                        <span className="ml-2">{n.note}</span>
                     </div>);
                  })
                  }
               </ScrollPanel>
               <div className="field">
                  <label htmlFor="customnotes">Custom Note</label>
                  <div>
                     <InputTextarea autoResize
                        id="customnotes"
                        value={customNotes}
                        onChange={(e) => setCustomNotes(e.target.value)}
                        rows={5}
                        cols={120} />
                  </div>
               </div>
            </TabPanel>
         </TabView>
         <Dialog header="Validation" visible={showValidation} style={{ width: '30vw' }} onHide={() => setShowValidation(false)}>
            {getMessages()}
            <div className="col-5 text-right">
               <Button label="Close" onClick={() => setShowValidation(false)} />
            </div>
         </Dialog>

      </>);

};