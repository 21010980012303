import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from '../PrintTrip';
import { useReactToPrint } from 'react-to-print';
import addNotification from 'react-push-notification';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import logo from '../../favicon.ico';
import 'primeicons/primeicons.css';

export const AvailableTrip = ({ trip, onChange, onPrint }) => {
   const [init, setInit] = useState(false);
   const [user,] = useGlobalState(g_user);
   const [roundTrips, setRoundTrips] = useState([]);
   const [returns, setReturns] = useState([]);
   const [deliveries, setDeliveries] = useState([]);
   const [allChase, setAllChase] = useState([]);
   const [allReturns, setAllReturns] = useState([]);
   const [allDeliveries, setAllDeliveries] = useState([]);
   const [showSelectReturn, setShowSelectReturn] = useState(false);

   useEffect(() => {
      if (!init) {
         setInit(true);
      }
   }, []);

   useEffect(() => {
      let round = [];
      let there = [];
      let back = [];
      let chase = [];
      let allThere = [];
      let allBack = [];

      if (trip) {
         trip.TripVehicles.forEach(veh => {
            if (veh.VehicleType.roundTrip) {
               chase.push(veh);
            } else if (veh.VehicleType.fromOrigin) {
               allThere.push(veh);
            } else {
               allBack.push(veh);
            }

            if (!veh.User) {
               if (veh.VehicleType.roundTrip) {
                  round.push(veh);
               } else if (veh.VehicleType.fromOrigin) {
                  there.push(veh);
               } else {
                  back.push(veh);
               }
            }
         });
         setRoundTrips(round);
         setReturns(back);
         setDeliveries(there);
         setAllChase(chase);
         setAllDeliveries(allThere);
         setAllReturns(allBack);
      }
   }, [trip]);

   const MINUTE_MS = 60000;

   const confirmClaimTrip = (tripId, veh) => {
      confirmDialog({
         message: 'Are you sure you want to claim this trip?',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => claimTrip(tripId, veh),
         reject: null
      });
   }

   const claimTrip = (tripId, veh) => {
      var apiUrl = `${API_Root}api/trip/AssignPorterToVehicle?tripId=${tripId}&porterId=${user.user.id}&vehicleId=${veh.id}`;
      axios.get(apiUrl).then((values) => {
         if (veh.VehicleType.fromOrigin && returns.length > 0) {
            setShowSelectReturn(true);
         } else {
            onChange();
         }
      });
   }

   const routeTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">{rowData.Dealership2.city}, {rowData.Dealership2.stateAbbr} to {rowData.Dealership1.city}, {rowData.Dealership1.stateAbbr}</div>
            <div className="w-full text-center">{rowData.estimatedDistance} / {rowData.estimatedDuration}</div>
         </div>
      );
   };

   const printTrip = (t) => {
      onPrint();
   }

   const requestedDateTimeTemplate = (rowData) => {
      var d = new Date(rowData.requestedDeliveryDateTime);
      var formattedDate = d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
      let cnt = rowData.TripVehicles.length;

      return (
         <div className="">
            <div className="w-full font-bold">{formattedDate}</div>
            <div className="w-full font-italic font-semibold">{cnt} vehicles</div>
         </div>
      );

   };

   const portersTemplate = () => {
      let vehs = [];
      let roundIcon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-right-arrow-left"></i>;
      let deliverIcon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-right"></i>;
      let returnIcon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-left"></i>;

      if (deliveries.length > 0 || roundTrips.length > 0 || trip.userHasDriveThere) {
         deliveries.forEach((s, i) => {
            let veh = s.vehicleYear + ' ' + s.vehicleMake + ' ' + s.vehicleModel;
            if (!s.vehicleYear && !s.vehicleMake && !s.vehicleModel) {
               veh = "Unspecified Vehicle";
            }
            let label = "Driver needed";
            let classes = "cursor-pointer pr-4 pl-4 relative w-full border-round-xl border-1 text-center mb-1 shadow-2 opacity-80 font-bold text-0 text-sm surface-500";
            vehs.push(<div key={s.id} className={classes} onClick={() => confirmClaimTrip(trip.id, s)}>{deliverIcon} {veh} - {label}</div>);
         });
         roundTrips.forEach((s, i) => {
            let veh = s.vehicleYear + ' ' + s.vehicleMake + ' ' + s.vehicleModel;
            if (!s.vehicleYear && !s.vehicleMake && !s.vehicleModel) {
               veh = "Unspecified Vehicle";
            }
            let label = "Driver needed";
            let classes = "cursor-pointer pr-4 pl-4 relative w-full border-round-xl border-1 text-center mb-1 shadow-2 opacity-80 font-bold text-0 text-sm surface-500";
            vehs.push(<div key={s.id} className={classes} onClick={() => confirmClaimTrip(trip.id, s)}>{roundIcon} {veh} - {label}</div>);
         });
      }
      else {
         returns.forEach((s, i) => {
            let veh = s.vehicleYear + ' ' + s.vehicleMake + ' ' + s.vehicleModel;
            if (!s.vehicleYear && !s.vehicleMake && !s.vehicleModel) {
               veh = "Unspecified Vehicle";
            }
            let label = "Driver needed";
            let classes = "cursor-pointer pr-4 pl-4 relative w-full border-round-xl border-1 text-center mb-1 shadow-2 opacity-80 font-bold text-0 text-sm surface-500";
            vehs.push(<div key={i} className={classes} onClick={() => confirmClaimTrip(trip.id, s)}>{returnIcon} {veh} - {label}</div>);
         });
      }
      return vehs;
   }

   const selectReturnTemplate = () => {
      let vehs = [];
      let returnIcon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-left"></i>;

      returns.forEach((s, i) => {
         let veh = s.vehicleYear + ' ' + s.vehicleMake + ' ' + s.vehicleModel;
         if (!s.vehicleYear && !s.vehicleMake && !s.vehicleModel) {
            veh = "Unspecified Vehicle";
         }
         let label = "Driver needed";
         let classes = "cursor-pointer pr-4 pl-4 relative w-full border-round-xl border-1 text-center mb-1 shadow-2 opacity-80 font-bold text-0 text-sm surface-500";
         vehs.push(<div key={i} className={classes} onClick={() => claimTrip(trip.id, s)}>{returnIcon} {veh} - {label}</div>);
      });
      return vehs;
   }

   const getReturnInfo = () => {
      let available;
      let resolve;

      if (roundTrips.length > 0 || deliveries.length > 0) {

         if (roundTrips.length > 0 && deliveries.length > 0) {
            available = `${deliveries.length} one-way and ${roundTrips.length} chase car vehicles need a driver`;
         } else if (deliveries.length > 0) {
            available = `${deliveries.length} one-way vehicles need a driver`;
         } else {
            available = `${roundTrips.length} chase car vehicles need a driver`;
         }

         if (allChase.length > 0 && allReturns.length > 0) {
            resolve = `${allReturns.length} one-way and ${allChase.length} chase car vehicles for return`;
         }
         else if (allReturns.length > 0) {
            resolve = `${allReturns.length} one-way vehicles for return (${returns.length} needing driver)`;
         } else if (allChase.length > 0) {
            resolve = `${allChase.length} chase car vehicles for return`;
         } else {
            resolve = 'Alternate return arrangements required';
         }
      }
      else {
         available = `${returns.length} one-way return vehicles need a driver`;

         if (allChase.length > 0 && allDeliveries.length > 0) {
            resolve = `${allDeliveries.length} one-way and ${allChase.length} chase car vehicles for pickup`;
         }
         else if (allDeliveries.length > 0) {
            resolve = `${allReturns.length} one-way vehicles for pickup`;
         } else if (allChase.length > 0) {
            resolve = `${allChase.length} chase car vehicles for pickup`;
         } else {
            resolve = 'Alternate pickup arrangements required';
         }
      }

      return <>
         <div className="text-bluegray-300 text-xs font-italic font-bold text-center">{available}</div>
         <div className="text-bluegray-300 text-xs font-italic font-bold text-center">{resolve}</div>
      </>;
   }

   const actionTemplate = (rowData) => {

      return (
         <div className="w-full">
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-print" link onClick={() => printTrip(rowData)} />
            </div>
         </div>
      );
   };

   return (
      <>
         <div className="flex justify-content-between flex-wrap flex-column xl:flex-row xl:align-items-start p-4 gap-4">
            <div>
               {requestedDateTimeTemplate(trip)}
            </div>
            <div>
               {routeTemplate(trip)}
            </div>
            <div>
               {portersTemplate()}
               {getReturnInfo()}
            </div>
            <div>
               {actionTemplate(trip)}
            </div>

         </div>
         <Dialog header="Select Return Vehicle" visible={showSelectReturn} style={{ width: '35vw' }} onHide={() => setShowSelectReturn(false)}>
            <div className="w-full p-4">
               {selectReturnTemplate()}
            </div>
         </Dialog>
      </>
   );
}