import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Card } from 'primereact/card';
import { Password } from 'primereact/password';
import axios from 'axios'
import 'primeflex/primeflex.css';
import './Login.css';
import useGlobalState, { g_user, API_Root } from "../globalState";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Spinner } from 'reactstrap';

export const Login = (props) => {
   const [email, setEmail] = useState('');
   const [password, setPassword] = useState('');
   const [checkedCookie, setCheckedCookie] = useState(false);
   const [loading, setLoading] = useState(false);
   const [user, setUser] = useGlobalState(g_user);
   const [cookies, setCookie] = useCookies(["user"]);
   const toast = useRef(null);
   const history = useHistory();

   const getLoggedInUser = (id) => {
      if (id !== "undefined" && !checkedCookie) {
         //setGettingUser(true);
         var apiUrl = `${API_Root}api/user/GetLoggedInUser?uid=${id}`;

         axios.get(apiUrl).then((values) => {
            setCheckedCookie(true);
            //setGettingUser(false);
            var success = values.data != null;
            if (success) {
               setUser(values.data);
               goToLanding(values.data, props?.location?.state?.from);
               //toast.current.show({ severity: 'success', summary: 'Successful Login', detail: 'Porter successfully assigned to this trip', life: 3000 });
            } else {
               toast.current.show({ severity: 'error', summary: 'Unsuccessful', detail: 'Unable to log in.  Please check your information and try again.', life: 3000 });
            }
         }, 
         (e) => {
            setCheckedCookie(true);
            //setGettingUser(false);
         });
      }
   }

   const goToLanding = (u, from) => {
      if (from) {
         history.push(props.location.state.from);
      } else if (u?.adminDealerships !== null && u?.adminDealerships?.length > 0) {
         history.push('/trip-list');
      } else if (user?.porterDealerships !== null && user?.porterDealerships?.length > 0) {
         history.push('/available-trips');
      } else {
         history.push('/home');
      }
   }

   useEffect(() => {
      var cookieUser = cookies.user;
      if (cookieUser !== null && cookieUser !== undefined) {
         console.log("user cookie", cookieUser);
         getLoggedInUser(cookieUser);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cookies.user]);

   const handleEnter = (e) => {
      if (e.keyCode === 13) {
         doLogin();
      }
   }

   const doLogin = () => {
      var apiUrl = `${API_Root}api/user/Login`;
      var dto = {
         email: email,
         password: password
      };
      setLoading(true);

      axios.post(apiUrl, dto).then((values) => {
         setLoading(false);
         console.log('login', values.data);
         var success = values.data?.user != null;
         if (success) {
            setCookie("user", values.data.user.id, { path: "/" }); 
            setUser(values.data);
            goToLanding(values.data, props?.location?.state?.from);
            //toast.current.show({ severity: 'success', summary: 'Successful Login', detail: 'Porter successfully assigned to this trip', life: 3000 });
         } else {
            toast.current.show({ severity: 'error', summary: 'Unsuccessful', detail: 'Unable to log in.  Please check your information and try again.', life: 3000 });
         }
      });
   }

   const getLoginButton = () => {
      if (loading) {
         return <ProgressSpinner className="h-2rem w-2rem" />
      } else if (email.length > 2 && password.length > 6) {
         return <Button className="mr-4" label="Log In" onClick={() => doLogin()}></Button>;
      } else {
         return <Button className="mr-4" label="Log In" onClick={() => doLogin()} disabled></Button>;
      }
   }

   if (!(cookies.user && !checkedCookie)) {

      return (
         <>
            <Toast ref={toast} />
            <Card className="logincard mt-4" title="Log In">
               <div className="field m-4">
                  <label htmlFor="username">Email</label>
                  <input id="username"
                     name="username"
                     value={email}
                     onChange={(e) => setEmail(e.target.value)}
                     className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
               </div>
               <div className="field m-4">
                  <label htmlFor="passwrod">Password</label>
                  <div>
                     <Password id="password"
                        value={password}
                        name="username"
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => handleEnter(e)}
                        inputClassName="w-full"
                        className="w-full"
                        feedback={false}
                        toggleMask />
                  </div>
               </div>
               <div className="grid">
                  <div className="col-6">
                     <Link to="/reset" className="mr-4 text-sm">Forgot Password</Link>
                  </div>
                  <div className="col-6 text-right p-4">
                     {getLoginButton()}
                  </div>
               </div>
            </Card>
         </>
      );
   }
   else {
      return <Spinner />
   }
}