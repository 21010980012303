import React, { useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Card } from 'primereact/card';
import { Toast } from 'primereact/toast';
import { Checkbox } from 'primereact/checkbox';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import moment from 'moment';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../../globalState";
import axios from 'axios'
import axiosInterceptor from '../../AuthIntercept';
import { useRef } from 'react';
import { TripLocation } from './Location';
import { TripVehicle } from './Vehicle';
import { TripReview } from './TripReview';

export const TripEntry = (props) => {
    const [tripId, setTripId] = React.useState(0);
    const [dealership, setDealership] = React.useState();
    const [source, setSource] = React.useState();
    const [destination, setDestination] = React.useState();
    const [distance, setDistance] = React.useState();
    const [deliverytime, setDeliveryTime] = React.useState('');
    const [contactname, setContactName] = React.useState('');
    const [contactphone, setContactPhone] = React.useState('');
    const [sourceContactName, setSourceContactName] = React.useState('');
    const [sourceContactPhone, setSourceContactPhone] = React.useState('');
    const [dealNumber, setDealNumber] = React.useState('');
    const [roundTrip, setRoundTrip] = React.useState(false);
    const [masterTimes, setMasterTimes] = React.useState();
    const [fromTimes, setFromTimes] = React.useState();
    const [toTimes, setToTimes] = React.useState();
    const [fromTime, setFromTime] = React.useState('');
    const [toTime, setToTime] = React.useState('');
    const [customNotes, setCustomNotes] = React.useState('');
    const [selNotes, setSelNotes] = React.useState([]);
    const [allNotes, setAllNotes] = React.useState([]);
    const [vehicles, setVehicles] = React.useState();
    const [showValidation, setShowValidation] = React.useState(false);
    const [messages, setMessages] = React.useState([]);
    const [tripToSave, setTripToSave] = React.useState({});
    const [showSaveConfirm, setShowSaveConfirm] = React.useState(false);

    const [init, setInit] = React.useState(false);

    const toast = useRef(null);

    const getPorterNotesList = () => {
        var apiUrl = `${API_Root}api/trip/GetAllPorterNotes`;
        axios.get(apiUrl).then((values) => {
            console.log('porter notes', values.data);
            setAllNotes(values.data);
        });
    }

    const getDistanceInfo = () => {
        var apiUrl = `${API_Root}api/trip/GetDistanceInfo`;
        var request = {
            sourceId: source.place_id,
            destinationId: destination.place_id
        };

        axios.post(apiUrl, request).then((value) => {
            console.log('distance info', value);
            setDistance(value.data);
        });
    }

    const getDealership = (id) => {
        var apiUrl = `${API_Root}api/dealership/get?id=${id}`;
        axios.get(apiUrl).then((values) => {
            let dealer = values.data;
            setSource({
                name: dealer.dealershipName,
                formatted_address: `${dealer.streetAddress}, ${dealer.city}, ${dealer.stateAbbr} ${dealer.zipCode}`,
                place_id: dealer.googlePlaceId,
                geometry: {
                    location: {
                        lat: dealer.lat,
                        lng: dealer.lon
                    }
                }
            });
        });
    }

    const setInitialSource = () => {
        getDealership(props.dealershipId);
    }

    const formatAMPM = (date) => {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        hours = hours < 10 ? '0' + hours : hours;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
    }

    const getExistingTrip = (id) => {
        var apiUrl = `${API_Root}api/trip/GetTrip?id=${id}`;
        axios.get(apiUrl).then((values) => {
            console.log('get trip', values.data);
            const trip = values.data;
            setTripId(trip.id);
            setDistance({ miles: trip.estimatedMiles, minutes: trip.estimatedHours, estimatedDistance: trip.distance, estimatedDuration: trip.duration });
            setContactName(trip.contactName);
            setContactPhone(trip.contactNumber);
            setRoundTrip(trip.roundTrip);
            setSourceContactName(trip.sourceContactName);
            setSourceContactPhone(trip.sourceContactNumber);
            setDealNumber(trip.dealNumber);
            setCustomNotes(trip.porterNotes ?? '');
            setSelNotes(trip.TripPorterNotes.map((n) => { return n.noteId }));

            //requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
            //requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),

            let timeFrom = new Date(trip.requestedDeliveryDateTime);
            let timeTo = new Date(trip.requestedDeliveryDateTimeEnd);

            setDeliveryTime(timeFrom);
            selectFromTime(formatAMPM(timeFrom));
            setToTime(formatAMPM(timeTo));
            setVehicles(trip.TripVehicles);

            setSource({
                name: trip.Dealership2.dealershipName,
                formatted_address: `${trip.Dealership2.streetAddress}, ${trip.Dealership2.city}, ${trip.Dealership2.stateAbbr} ${trip.Dealership2.zipCode}`,
                place_id: trip.Dealership2.googlePlaceId,
                geometry: {
                    location: {
                        lat: trip.Dealership2.lat,
                        lng: trip.Dealership2.lon
                    }
                }
            });


            setDestination({
                name: trip.Dealership1.dealershipName,
                formatted_address: `${trip.Dealership1.streetAddress}, ${trip.Dealership1.city}, ${trip.Dealership1.stateAbbr} ${trip.Dealership1.zipCode}`,
                place_id: trip.Dealership1.googlePlaceId,
                geometry: {
                    location: {
                        lat: trip.Dealership1.lat,
                        lng: trip.Dealership1.lon
                    }
                }
            });

        });
    }

    const validateSubmit = () => {
        var msgs = [];
        if (!source) {
            msgs.push('Please select an origin location');
        }
        if (!destination) {
            msgs.push('Please select a destination location');
        }
        if (!deliverytime || deliverytime.length === 0) {
            msgs.push('Please provide the requested delivery date');
        }

        if (!fromTime || fromTime.length === 0) {
            msgs.push('Please provide the requested delivery time from');
        }

        if (!toTime || toTime.length === 0) {
            msgs.push('Please provide the requested delivery time to');
        }

        if (msgs.length > 0) {
            setMessages(msgs);
            setShowValidation(true);
            return false;
        }
        else {
            return true;
        }
    }

    const getMessages = () => {
        if (messages.length > 0) {
            return <>
                <div>Please correct the following issues before saving</div>
                <div>
                    <ul className="text-red-400">
                        {messages.map((m) => <li>{m}</li>)}
                    </ul>
                </div>
            </>
        }
        else {
            return <></>;
        }
    }

    const saveTrip = () => {

        if (!validateSubmit()) {
            return;
        }

        var trip = {
            id: tripId ?? 0,
            dealershipId: props.dealershipId,
            createdBy: props.userId,
            TripVehicles: vehicles,
            estimatedMiles: distance.miles,
            estimatedHours: distance.minutes,
            contactName: contactname,
            contactNumber: contactphone,
            requestedDeliveryDateTime: new Date(deliverytime.toDateString() + ' ' + (fromTime ?? "")),
            estimatedDistance: distance.distance,
            estimatedDuration: distance.duration,
            roundTrip: roundTrip,
            requestedDeliveryDateTimeEnd: new Date(deliverytime.toDateString() + ' ' + (toTime ?? fromTime ?? "")),
            sourceContactName: sourceContactName,
            sourceContactNumber: sourceContactPhone,
            dealNumber: dealNumber,
            porterNotes: customNotes,
            TripPorterNotes: selNotes.map((n) => { return { noteId: n } })
        };

        var dto = {
            trip: trip,
            source: source,
            destination: destination
        };

        setTripToSave(dto);
        setShowSaveConfirm(true);
    }

    const completeSave = () => {
        var apiUrl = `${API_Root}api/trip/SaveTrip`;
        axios.post(apiUrl, tripToSave).then((values) => {
            console.log('save trip', values.data);
            if (values.data === true) {
                toast.current.show({ severity: 'success', summary: 'Success', detail: 'Trip successfully created', life: 3000 });
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Trip could not be created', life: 3000 });
            }
            setShowSaveConfirm(false);
            props.onCreated();
        });
    }

    const getMasterTimes = () => {
        var time = moment('00:00', 'HH:mm');
        var lst = [];

        for (var i = 0; i < 48; i++) {
            lst.push({
                index: i,
                value: time.format("hh:mm A")
            });
            time.add(30, 'm');
        }
        console.log(lst);
        setMasterTimes(lst);
        setFromTimes(lst);
    }

    const selectFromTime = (val) => {
        setFromTime(val);
        var ind = 0;
        for (var i = 0; i < 48; i++) {
            if (masterTimes[i].value === val) {
                ind = i + 1;
                break;
            }
        }
        var toLst = masterTimes.slice(ind, 48);
        console.log('to times', toLst);
        setToTimes(toLst);
        setToTime(toLst[0].value);
    }

    const onNotesChange = (e) => {
        let _notes = [...selNotes];

        if (e.checked)
            _notes.push(e.value);
        else
            _notes.splice(_notes.indexOf(e.value), 1);

        setSelNotes(_notes);
    }

    useEffect(() => {
        if (props.tripId) {
            getExistingTrip(props.tripId);
        }
        else {
            setSourceContactName(props.userName);
            setSourceContactPhone(props.userPhone);
        }
    }, [init]);

    useEffect(() => {
        if (source !== undefined && destination !== undefined) {
            getDistanceInfo();
        }
    }, [source, destination]);

    const selectSource = (p) => {
        setSource(p);
    }

    const updateVehicle = (v, i) => {
        let vehs = vehicles;
        vehs[i].vehicleVIN = v.vehicleVIN;
        vehs[i].vehicleMake = v.vehicleMake;
        vehs[i].vehicleModel = v.vehicleModel;
        vehs[i].vehicleYear = v.vehicleYear;
        vehs[i].vehicleColor = v.vehicleColor;
        vehs[i].vehicleNotes = v.vehicleNotes;
        vehs[i].tripVehicleId = v.tripVehicleId;
        vehs[i].isChase = v.isChase;
        setVehicles(vehs);
    }

    const addVehicle = () => {
        let old = vehicles || [];
        let vehs = [...old];
        vehs.push({
            vehicleVIN: '',
            vehicleMake: '',
            vehicleModel: '',
            vehicleYear: '',
            vehicleColor: '',
            vehicleNotes: '',
            tripVehicleId: 1,
            isChase: false
        });
        console.log('vehicles', vehs);
        setVehicles(vehs);
    }

    const removeVehicle = (i) => {
        let old = vehicles || [];
        let vehs = [...old];
        vehs.splice(i, i);
        setVehicles(vehs);
    }

    const getToTimeClass = () => {
        return (toTime) ? "ml-2" : "ml-2 border-red-400";
    }

    const getFromTimeClass = () => {
        return (fromTime) ? "ml-2" : "ml-2 border-red-400";
    }

    const getDeliveryDateClass = () => {
        return (deliverytime) ? "w-10rem" : "w-10rem border-red-400";
    }

    const getDistanceTemplate = () => {
        if (distance) {
            return <span className="font-semibold font-italic text-sm">- {distance.distance} / {distance.duration}</span>;
        }
        return "";
    }

    const switchLocations = () => {
        let newSource = destination;
        let newDest = source;
        setSource(newSource);
        setDestination(newDest);
    }

    if (!init) {
        getMasterTimes();
        getPorterNotesList();
        addVehicle();
        setInitialSource();
        setInit(true);
    }

    return (
        <>
            <Toast ref={toast} />
            <div className="w-full text-right mt-2">
                <Button label="Save" icon="pi pi-save" onClick={() => saveTrip()} />
            </div>
            <div className="w-full border-bottom-1 border-400 font-bold pl-3">Route Information {getDistanceTemplate()}</div>
            <div className="flex flex-wrap mt-2">
                <div className="ml-2">
                    <div className="w-full font-semibold text-sm">Origin</div>
                    <TripLocation value={source} onChange={(l) => setSource(l)}></TripLocation>
                </div>
                <div className="mr-2 ml-2">
                    <Button label="Save" icon="pi pi-arrows-h" link onClick={() => switchLocations()} />
                </div>
                <div className="ml-2">
                    <div className="w-full font-semibold text-sm">Destination</div>
                    <TripLocation value={destination} onChange={(l) => setDestination(l)}></TripLocation>
                </div>
            </div>
            <div className="w-full border-bottom-1 border-400 font-bold pl-3 mt-4">Vehicle Information</div>
            <div className="flex flex-wrap">
                {vehicles?.map((v, i) => {
                    let del = <i className="pi pi-times-circle absolute right-0 top-0 mt-1 mr-1 text-red-600 cursor-pointer text-xl" onClick={() => removeVehicle(i)}></i>;
                    if (i == 0) {
                        del = <></>;
                    }

                    return (<div key={i} className="relative max-w-30rem m-3">
                        {del}
                        <TripVehicle value={v} onChange={(tv) => updateVehicle(tv, i)}></TripVehicle>
                    </div>
                    )
                })}
                <div className="w-full text-center">
                    <Button className="p-2 mt-2 mb-2" label="Add another vehicle" icon="pi pi-plus" link onClick={() => addVehicle()} />
                </div>
            </div>
            <div className="w-full border-bottom-1 border-400 font-bold pl-3 mt-4">Additional Information</div>
            <div className="flex flex-wrap justify-content-left gap-3">
                <div className="field mt-4">
                    <label htmlFor="deliverytime">Requested Delivery Time Range</label>
                    <div>
                        <Calendar value={deliverytime} onChange={(e) => setDeliveryTime(e.value)} placeholder="Select Date" inputClassName={getDeliveryDateClass()} />
                        <Dropdown value={fromTime} onChange={(e) => selectFromTime(e.value)} options={fromTimes} optionLabel="value"
                            placeholder="From" className={getFromTimeClass()} />
                        <Dropdown value={toTime} onChange={(e) => setToTime(e.value)} options={toTimes} optionLabel="value"
                            placeholder="To" className={getToTimeClass()} />
                    </div>
                </div>

                <div className="grid">
                    <div className="col-4">
                        <div className="h-2rem w-full text-center mb-2"></div>
                        <div className="field">
                            <label htmlFor="dealnumber">Deal Number</label>
                            <input id="dealnumber"
                                value={dealNumber}
                                onChange={(e) => setDealNumber(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                        <div className="mt-4 mb-4">
                            <div className="flex align-items-center">
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="h-auto w-full text-center font-semibold mb-2 surface-700 text-0">Origin</div>
                        <div className="field">
                            <label htmlFor="sourcecontactname">Contact Name</label>
                            <input id="sourcecontactname"
                                value={sourceContactName}
                                onChange={(e) => setSourceContactName(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                        <div className="field">
                            <label htmlFor="sourcecontactphone">Contact Phone</label>
                            <InputMask id="sourcecontactphone"
                                mask="(999) 999-9999"
                                value={sourceContactPhone}
                                onChange={(e) => setSourceContactPhone(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="h-auto w-full text-center font-semibold mb-2 surface-700 text-0">Destination</div>
                        <div className="field">
                            <label htmlFor="contactname">Contact Name</label>
                            <input id="contactname"
                                value={contactname}
                                onChange={(e) => setContactName(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                        <div className="field">
                            <label htmlFor="contactphone">Contact Phone</label>
                            <InputMask id="contactphone"
                                mask="(999) 999-9999"
                                value={contactphone}
                                onChange={(e) => setContactPhone(e.target.value)}
                                className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full border-bottom-1 border-400 font-bold pl-3 mt-4">Notes</div>
            <div className="flex flex-wrap justify-content-left gap-3 mt-2">
                <label htmlFor="">Standard Notes</label>
                <ScrollPanel style={{ width: '100%', height: '100px' }}>
                    {allNotes.map((n, i) => {
                        return (<div key={i}>
                            <Checkbox
                                value={n.id}
                                onChange={onNotesChange}
                                checked={selNotes.includes(n.id)} />
                            <span className="ml-2">{n.note}</span>
                        </div>);
                    })
                    }
                </ScrollPanel>
                <div className="field">
                    <label htmlFor="customnotes">Custom Note</label>
                    <div>
                        <InputTextarea autoResize
                            id="customnotes"
                            value={customNotes}
                            onChange={(e) => setCustomNotes(e.target.value)}
                            rows={5}
                            cols={120} />
                    </div>
                </div>
                <div className="w-full text-right mt-2">
                    <Button label="Save" icon="pi pi-save" onClick={() => saveTrip()} />
                </div>
            </div>
            <Dialog header="Validation" visible={showValidation} style={{ width: '30vw' }} onHide={() => setShowValidation(false)}>
                {getMessages()}
                <div className="col-5 text-right">
                    <Button label="Close" onClick={() => setShowValidation(false)} />
                </div>
            </Dialog>
            <Dialog header="Confirm New Trip" visible={showSaveConfirm} style={{ width: '65vw' }} onHide={() => setShowSaveConfirm(false)}>
                <TripReview trip={tripToSave}></TripReview>
                <div className="w-full text-right">
                    <Button className="mr-2" label="Cancel" onClick={() => setShowSaveConfirm(false)} />
                    <Button label="Save" onClick={() => completeSave()} />
                </div>
            </Dialog>
        </>);

};