import React from 'react';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FileUpload } from 'primereact/fileupload';
import { Divider } from 'primereact/divider';
import Compressor from 'compressorjs';
import axios from 'axios'
import 'primeflex/primeflex.css';
import { API_Root } from "../../globalState";

export class InsuranceFile extends React.Component {

   constructor(props) {
      super(props);
      this.fileupload = React.createRef(null);
   }

   onImageUpload = async (event) => {
      const file = event.files[0];
      const dealershipId = this.props.dealership.id;
      const updateFileId = this.props.updateFileId;

      new Compressor(file, {
         quality: 0.6,
         maxHeight: 400,
         success(result) {
            const reader = new FileReader();
            reader.onload = (e) => {
               let dto = {
                  dealershipId: dealershipId,
                  content: reader.result
               };

               var apiUrl = `${API_Root}api/dealership/SaveInsuranceFile`;
               axios.post(apiUrl, dto).then((values) => {
                  let fileId = values.data;
                  if (fileId > 0 && updateFileId) {
                     updateFileId(fileId);
                  }
               });
            };
            reader.readAsDataURL(result);
         },
         error(err) {
            console.log(err.message);
         },
      });

      this.fileupload.clear();
   }

   getImageDisplay = () => {
      if (this.props.fileId &&
         this.props.fileId > 0) {
         var imgURL = `${API_Root}api/image/GetUserFile?id=${this.props.fileId}`;
         return (<img alt='insurance card' style={{ marginTop: 10 }} src={imgURL} />);
      }
      else {
         return (<div style={{ marginTop: 10 }}>No insurance card has been uploaded.</div>);
      }
   }

   render() {
      return (<div className="mt-4">
         <FileUpload
            ref={ref => { this.fileupload = ref }}
            mode="basic"
            name="image"
            accept="image/*"
            customUpload
            auto
            uploadHandler={this.onImageUpload}
            chooseLabel="Upload Insurance Card"
            maxFileSize={10000000} />
         {this.getImageDisplay()}
      </div>);
   }
}