import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Billables } from './Billables';
import { Receivables } from './Receivables';
import { Payables } from './Payables';
import { PaymentHistory } from './PaymentHistory';

export const BillingAdmin = () => {
   const [init, setInit] = useState(false);

   useEffect(() => {
      if (!init) {
         setInit(true);
      }
   }, []);

   return (
      <>
         <TabView>
            <TabPanel header="Pending Billables">
               <Billables dealershipId={0}></Billables>
            </TabPanel>
            <TabPanel header="Receivables">
               <Receivables></Receivables>
            </TabPanel>
            <TabPanel header="Payables">
               <Payables></Payables>
            </TabPanel>
            <TabPanel header="Payment History">
               <PaymentHistory></PaymentHistory>
            </TabPanel>
         </TabView>
      </>
   );
}