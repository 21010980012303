import React, { useRef, useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { CreateTripComponent } from './CreateTrip';
import { AssignTrip } from './AssignTrip';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from './PrintTrip';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../globalState";

export const TripListDealership = () =>  {
   const [trips, setTrips] = useState([]);
   const [showAdd, setShowAdd] = useState(false);
   const [showEdit, setShowEdit] = useState(false);
   const [tripToEdit, setTripToEdit] = useState(0);
   const [showAssign, setShowAssign] = useState(false);
   const [tripToAssign, setTripToAssign] = useState();
   const [showPrint, setShowPrint] = useState(false);
   const [tripToPrint, setTripToPrint] = useState();
   const [dealerships, setDealerships] = useState(undefined);
   const [curDealership, setCurDealership] = useState(undefined);
   const [init, setInit] = useState(false);
   const [user, setUser] = useGlobalState(g_user);
   const printRef = useRef(null);
   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   const getDealerships = () => {
      var dealerArr = [];

      user.adminDealerships.forEach((d) => {
         dealerArr.push({ id: d.id, name: d.dealershipName, admin: true, porter: false });
      });

      user.porterDealerships.forEach((d) => {
         var match = undefined;
         dealerArr.forEach((a) => {
            if (a.id === d.id) {
               match = a;
            }
         });

         if (match !== undefined) {
            match.porter = true;
         } else {
            dealerArr.push({ id: d.id, name: d.dealershipName, admin: false, porter: true });
         }
      });

      setDealerships(dealerArr);
      if (dealerArr.length > 0) {
         setCurDealership(dealerArr[0]);
         getTripsForDealership(dealerArr[0].id);
      }
   }

   useEffect(() => {
      if (!init) {
         setInit(true);
         getDealerships();
      }
   }, []);

   const getDealershipDropdown = () => {
      if (dealerships !== undefined && dealerships.length === 1) {
         return <div><h3>Trips For {dealerships[0].name}</h3></div>;
      }
      else if (dealerships !== undefined && dealerships.length > 1) {
         return <div className="flex">
            <label htmlFor="dealerships">Trips For</label>
            <Dropdown value={curDealership} onChange={(e) => selectDealership(e.value)} options={dealerships} optionLabel="name"
               placeholder="Select a dealership" className="" />
         </div>;
      }
      else {
         return <div></div>;
      }
   }

   const selectDealership = (sel) => {
      setCurDealership(sel);
      getTripsForDealership(sel.id);
   }

   const getTripsForDealership = (id) => {
      var apiUrl = `${API_Root}api/trip/GetTripsForDealership?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('trips', values.data);
         setTrips(values.data);
      });
   }

   const removeTripPorter = (porterId, tripId) => {
      var apiUrl = `${API_Root}api/trip/RemoveTripPorter?porterId=${porterId}&tripId=${tripId}`;
      axios.get(apiUrl).then((values) => {
         getTripsForDealership(curDealership.id);
      });
   }

   const confirmDeleteTrip = (id) => {
      confirmDialog({
         message: 'Are you sure you want to delete this trip?',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => deleteTrip(id),
         reject: null
      });
   }

   const deleteTrip = (id) => {
      var apiUrl = `${API_Root}api/trip/DeleteTrip?id=${id}`;
      axios.get(apiUrl).then((values) => {
         getTripsForDealership(curDealership.id);
      });
   }

   const sourceTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full">{rowData.Dealership2.dealershipName ?? "Private address"}</div>
            <div className="w-full">{rowData.Dealership2.streetAddress}</div>
            <div className="w-full">{rowData.Dealership2.city} {rowData.Dealership2.stateAbbr} {rowData.Dealership2.zipCode}</div>
         </div>
      );
   };

   const destinationTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full">{rowData.Dealership1.dealershipName ?? "Private address"}</div>
            <div className="w-full">{rowData.Dealership1.streetAddress}</div>
            <div className="w-full">{rowData.Dealership1.city} {rowData.Dealership1.stateAbbr} {rowData.Dealership1.zipCode}</div>
         </div>
      );
   };

   const newTripAdded = () => {
      setShowAdd(false);
      setShowEdit(false);
      getTripsForDealership(curDealership.id);
   }

   const assignPorter = (t) => {
      setTripToAssign(t);
      setShowAssign(true);
   }

   const porterAssigned = () => {
      setShowAssign(false);
      getTripsForDealership(curDealership.id);
   }

   const printTrip = (t) => {
      setTripToPrint(t);
      setShowPrint(true);
   }

   const requestedDateTimeTemplate = (rowData) => {
      var d = new Date(rowData.requestedDeliveryDateTime);
      var formattedDate = d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true 
      });

      return (
         <div className="w-full">
            <div className="w-full font-bold">{formattedDate}</div>
            <div className="w-full">{rowData.vehicleYear} {rowData.vehicleMake} {rowData.vehicleModel}</div>
            <div className="w-full">{rowData.estimatedDistance} / {rowData.estimatedDuration}</div>
         </div>
      );

   };

   const statusTemplate = (rowData) => {
      var assigned;
      var completed;

      if (rowData.completed) {
         completed = <Tag value="Complete" severity="success" ></Tag>;
      } else {
         completed = <Tag value="Incomplete" severity="warning" ></Tag>;
      }

      if (rowData.porterId !== undefined && rowData.porterId !== null) {
         assigned = <Tag value={"Assigned to " + rowData.User1.firstName + " " + rowData.User1.lastName} severity="success" ></Tag>;
      } else {
         assigned = <Tag value="Unassigned" severity="warning" ></Tag>;
      }

      return (
         <div className="w-full">
            <div className="w-full">{assigned}</div>
            <div className="w-full">{completed}</div>
         </div>
      );
   };

   const portersTemplate = (rowData) => {
      let chaseCar = rowData.chaseCar;
      let numPorters = rowData.numPorters;

      let slots = [];
      let porters = [];
      let chaser = null;

      for (var i = 0; i < rowData.TripPorters.length; i++) {
         if (rowData.TripPorters[i].isChase) {
            chaser = {
               id: rowData.TripPorters[i].userId,
               chase: true,
               label: `${rowData.TripPorters[i].User.firstName} ${rowData.TripPorters[i].User.lastName}`,
               isFilled: true,
               confirmed: rowData.TripPorters[i].confirmed
            };
         }
         else {
            porters.push({
               id: rowData.TripPorters[i].userId,
               chase: false,
               label: `${rowData.TripPorters[i].User.firstName} ${rowData.TripPorters[i].User.lastName}`,
               isFilled: true,
               confirmed: rowData.TripPorters[i].confirmed
            });
         }
      }

      if (chaseCar) {
         if (chaser) {
            slots.push(chaser);
         }
         else {
            slots.push({
               id: 0,
               chase: true,
               label: `Chase Car Needed`,
               isFilled: false,
               confirmed: false
            });
         }
      }

      for (var i = 0; i < numPorters; i++) {
         if (i < porters.length) {
            slots.push(porters[i]);
         }
         else {
            slots.push({
               id: 0,
               chase: false,
               label: `Porter Needed`,
               isFilled: false,
               confirmed: false
            });
         }
      }

      return slots.map((s, i) => {
         let classes = "relative w-full border-round-xl border-1 text-center mb-1 shadow-2 opacity-80 font-bold text-0 text-sm " + (!s.isFilled ? "surface-500" : !s.confirmed ? "bg-yellow-600" : !s.chase ? "bg-green-600" : "bg-blue-600");
         let dlink = s.isFilled ? <i className="pi pi-times-circle absolute right-0 mt-1 mr-1 text-white cursor-pointer text-sm" onClick={() => removeTripPorter(s.id, rowData.id)}></i> : <></>;
         return (<div key={i} className={classes}>{s.label}{dlink}</div>)
      });

   }

   const needsPorter = (rowData) => {
      let chaseCar = rowData.chaseCar;
      let numPorters = rowData.numPorters;

      let slots = [];
      let porters = [];
      let chaser = null;

      for (var i = 0; i < rowData.TripPorters.length; i++) {
         if (rowData.TripPorters[i].isChase) {
            chaser = {
               id: rowData.TripPorters[i].userId,
               chase: true,
               label: `${rowData.TripPorters[i].User.firstName} ${rowData.TripPorters[i].User.lastName}`,
               isFilled: true,
               confirmed: rowData.TripPorters[i].confirmed
            };
         }
         else {
            porters.push({
               id: rowData.TripPorters[i].userId,
               chase: false,
               label: `${rowData.TripPorters[i].User.firstName} ${rowData.TripPorters[i].User.lastName}`,
               isFilled: true,
               confirmed: rowData.TripPorters[i].confirmed
            });
         }
      }

      if ((chaseCar && !chaser) || numPorters > porters.length) {
         return true;
      }
      else {
         return false;
      }
   }

   const editTrip = (id) => {
      setTripToEdit(id);
      setShowEdit(true);
   }

   const actionTemplate = (rowData) => {
      let assign = needsPorter(rowData) ? <Button className="p-2" icon="pi pi-user-plus" link onClick={() => assignPorter(rowData)} /> : <></>;

      return (
         <div className="w-full">
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-trash" link onClick={() => confirmDeleteTrip(rowData.id)} />
               <Button className="p-2" icon="pi pi-file-edit" link onClick={() => editTrip(rowData.id)} />
            </div>
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-print" link onClick={() => printTrip(rowData)} />
               {assign}
            </div>
         </div>
      );
   };

   const addButtonTemplate = () => {
      if (curDealership && curDealership.admin) {
         return <Button className="p-2 mr-4" label="Add Trip" icon="pi pi-plus-circle" link onClick={() => setShowAdd(true)} />;
      }
      else {
         return <div></div>;
      }
   }
                  //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="grid mt-2 ml-2">
               <div className="col-6">
                  {getDealershipDropdown()}
               </div>
               <div className="col-6 text-right">
                  {addButtonTemplate()}
               </div>
            </div>
            <DataTable value={trips} tableStyle={{ minWidth: '50rem' }}>
               <Column header="" body={requestedDateTimeTemplate} ></Column>
               <Column header="From" style={{ minWidth: '12rem' }} body={sourceTemplate} />
               <Column header="To" style={{ minWidth: '12rem' }} body={destinationTemplate} />
               <Column header="Porters" style={{ minWidth: '12rem' }} body={portersTemplate} />
               <Column header="" style={{ minWidth: '12rem' }} body={actionTemplate} />
            </DataTable>
         </div>
         <Dialog header="Create Trip" visible={showAdd} style={{ width: '75vw' }} onHide={() => setShowAdd(false)}>
            <CreateTripComponent onCreated={newTripAdded} dealershipId={curDealership?.id} userId={user?.user?.id}></CreateTripComponent>
         </Dialog>
         <Dialog header="Edit Trip" visible={showEdit} style={{ width: '75vw' }} onHide={() => setShowEdit(false)}>
            <CreateTripComponent onCreated={newTripAdded} dealershipId={curDealership?.id} userId={user?.user?.id} tripId={tripToEdit}></CreateTripComponent>
         </Dialog>
         <Dialog header="Assign Porter" visible={showAssign} style={{ width: '65vw' }} onHide={() => setShowAssign(false)}>
            <AssignTrip onAssign={porterAssigned} trip={tripToAssign}></AssignTrip>
         </Dialog>
         <Dialog header="Print Trip" visible={showPrint} style={{ width: '65vw' }} onHide={() => setShowPrint(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PrintTrip ref={printRef} trip={tripToPrint} dealer={curDealership}></PrintTrip>
         </Dialog>
      </>
   );
}