import React, { useState } from 'react';
import { Card } from 'primereact/card';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tag } from 'primereact/tag';
import axios from 'axios'
import 'primeflex/primeflex.css';
import { API_Root } from "../globalState";

export const TripInfo = (props) => {
   const [init, setInit] = useState(false);
   const [trip, setTrip] = useState();
   const [tripId, setTripId] = useState();
   const [userId, setUserId] = useState();
   const [confirmed, setConfirmed] = useState();
   const [rejected, setRejected] = useState();

   if (!init) {
      setInit(true);
      const query = new URLSearchParams(props.location.search);
      const uid = query.get('u');
      const tid = query.get('t');

      var apiUrl = `${API_Root}api/trip/GetTrip?id=${tid}`;

      axios.get(apiUrl).then((values) => {
         console.log('trip', values.data);
         var success = values.data != null;
         if (success) {
            setTrip(values.data);
            setTripId(tid);
            setUserId(uid);

            values.data.TripVehicles.forEach((p) => {
               if (p.porterId == uid) {
                  setConfirmed(p.confirmed);
               }
            });
         }
      });
   }

   const confirmTrip = () => {
      var apiUrl = `${API_Root}api/trip/ConfirmPorter?tripId=${tripId}&porterId=${userId}`;

      axios.get(apiUrl).then((values) => {
         setConfirmed(true);
      });
   }

   const rejectTrip = () => {
      var apiUrl = `${API_Root}api/trip/RejectPorter?tripId=${tripId}&porterId=${userId}`;

      axios.get(apiUrl).then((values) => {
         setRejected(true);
      });
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   };

   const formatTime = (dt) => {
      var d = new Date(dt);
      return d.toLocaleTimeString('en-US', {
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   };

   const formatBool = (b) => {
      if (b) {
         return "Yes";
      } else {
         return "No";
      }
   };

   const porterListTemplate = (p) => {
      let type;
      if (!p.confirmed) {
         type = <Tag severity="warning" value="Unconfirmed"></Tag>;
      }
      else if (p.isChase) {
         type = <Tag severity="success" value="Chase"></Tag>;
      }
      else {
         type = <Tag severity="info" value="Driver"></Tag>;
      }

      return (<>
         <div className="w-full mt-4">
            <span className="font-bold">{p.vehicleVIN}</span> - {p.vehicleYear} {p.vehicleMake} {p.vehicleModel}
         </div>
         <div key={p.id} className="flex flex-row flex-wrap">
            <div className="mr-4">
               {p.User.firstName} {p.User.lastName}
            </div>
            <div className="mr-4">
               <a href={"tel:" + p.User.phone}>{p.User.phone}</a>
            </div>
            <div className="mr-4">
               {type}
            </div>
         </div>
      </>
      );
   }

   const getButtons = () => {
      if (confirmed) {
         return (<div className="font-bold font-italic">This trip has been confirmed</div>);
      } else if (rejected) {
         return (<div className="font-bold font-italic">This trip has been rejected</div>);
      } else {
         return (<>
            <Button className="mr-4" label="Reject" onClick={() => rejectTrip()}></Button>
            <Button className="mr-4" label="Confirm" onClick={() => confirmTrip()}></Button>
         </>);
      }
   };

   if (trip) {

      return (<Card>
         <div className="grid">
            <div className="col-12">
               <h3>Trip for {trip.Dealership.dealershipName}</h3>
            </div>
         </div>
         <Divider />
         <div className="grid">
            <div className="col-12">
               <span className="font-bold">Depart: </span>{formatDate(trip.departDateTimeStart)} - {formatTime(trip.departDateTimeEnd)}
            </div>
            <div className="col-12">
               <span className="font-bold">Arrive: </span>{formatDate(trip.requestedDeliveryDateTime)} - {formatTime(trip.requestedDeliveryDateTimeEnd)}
            </div>
         </div>
         <div className="flex">
            <div className="mr-4">
               <span className="font-bold">Number of Vehicles: </span>{trip.TripVehicles.length}
            </div>
            <div className="mr-4">
               <span className="font-bold">Chase Car: </span>{formatBool(trip.roundTrip)}
            </div>
            <div className="">
            </div>
         </div>
         <Divider />
         <div className="grid">
            <div className="col-6 font-bold">
               Source
         </div>
            <div className="col-6 font-bold">
               Destination
         </div>
         </div>
         <div className="grid">
            <div className="col-6">
               <div className="w-full">{trip.Dealership2.dealershipName ?? "Private address"}</div>
               <div className="w-full">{trip.Dealership2.streetAddress}</div>
               <div className="w-full">{trip.Dealership2.city} {trip.Dealership2.stateAbbr} {trip.Dealership2.zipCode}</div>
            </div>
            <div className="col-6">
               <div className="w-full">{trip.Dealership1.dealershipName ?? "Private address"}</div>
               <div className="w-full">{trip.Dealership1.streetAddress}</div>
               <div className="w-full">{trip.Dealership1.city} {trip.Dealership1.stateAbbr} {trip.Dealership1.zipCode}</div>
            </div>
         </div>
         <Divider />
         {trip?.TripVehicles?.map((p, i) => {
            return porterListTemplate(p);
         })}
         <Divider />
         <div className="grid">
            <div className="col-12 text-right">
               {getButtons()}
            </div>
         </div>
      </Card>);
   }
   else {
      return (<Card className="text-center"><ProgressSpinner /></Card>);
   }

}