import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from '../PrintTrip';
import { AvailableTrip } from "./AvailableTrip";
import { useReactToPrint } from 'react-to-print';
import addNotification from 'react-push-notification';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import logo from '../../favicon.ico';

export const AvailableTrips = () => {
   const [trips, setTrips] = useState([]);
   const [showPrint, setShowPrint] = useState(false);
   const [tripToPrint, setTripToPrint] = useState();
   const [curDealership, setCurDealership] = useState();
   const [init, setInit] = useState(false);
   const [user,] = useGlobalState(g_user);
   const printRef = useRef(null);
   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   useEffect(() => {
      if (!init) {
         setInit(true);
         getAvailableTrips();
      }
   }, []);

   const getAvailableTrips = () => {
      var apiUrl = `${API_Root}api/trip/GetAvailableTripsForUser?userId=${user.user.id}`;
      axios.get(apiUrl).then((values) => {
         if (trips && trips.length < values.data.length) {
            sendNotification(values.data.length);
         }
         console.log('trips', values.data);
         setTrips(values.data);
      });
   }

   const MINUTE_MS = 60000;

   useEffect(() => {
      const interval = setInterval(() => {
         getAvailableTrips();
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
   }, [])

   const sendNotification = (num) => {
      addNotification({
         title: 'Mozo Trip Notification',
         subtitle: 'New trip(s) available',
         message: `There are currently ${num} trips available`,
         theme: 'darkblue',
         icon: { logo },
         native: true // when using native, your OS will handle theming.
      });
   }

   const confirmClaimTrip = (tripId, vehicleId) => {
      confirmDialog({
         message: 'Are you sure you want to claim this trip?',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => claimTrip(tripId, vehicleId),
         reject: null
      });
   }

   const claimTrip = (tripId, vehicleId) => {
      var apiUrl = `${API_Root}api/trip/AssignPorterToVehicle?tripId=${tripId}&porterId=${user.user.id}&vehicleId=${vehicleId}`;
      axios.get(apiUrl).then((values) => {
         getAvailableTrips();
      });
   }

   const printTrip = (t) => {
      setTripToPrint(t);
      setShowPrint(true);
   }

   const itemTemplate = (item) => {
      return <AvailableTrip key={item.id} trip={item} onChange={() => getAvailableTrips()} onPrint={() => printTrip(item)}></AvailableTrip>
   }

   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="grid mt-2 ml-2">
               <div className="col-12 text-lg md:text-3xl">
                  Available Trips
               </div>
            </div>
            <div className="card">
               <DataScroller value={trips} itemTemplate={itemTemplate} rows={5} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Print Trip" visible={showPrint} style={{ width: '65vw' }} onHide={() => setShowPrint(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PrintTrip ref={printRef} trip={tripToPrint} dealer={curDealership}></PrintTrip>
         </Dialog>
      </>
   );
}