import React from 'react';
import 'primeflex/primeflex.css';

export const TripHeader = (props) => {
  const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   };

   return (
      <>
      <div className="grid">
         <div className="col-4 pl-4 font-bold">
            Details
         </div>
            <div className="col-4 pl-4 font-bold">
            From
         </div>
            <div className="col-4 pl-4 font-bold">
            Destination
         </div>
      </div>
      <div className="grid">
         <div className="col-4">
            <div className="w-full">
               <div className="w-full">{formatDate(props.trip.requestedDeliveryDateTime)}</div>
               <div className="w-full">{props.trip.vehicleYear} {props.trip.vehicleMake} {props.trip.vehicleModel}</div>
               <div className="w-full">{props.trip.estimatedDistance} / {props.trip.estimatedDuration}</div>
            </div>
         </div>
         <div className="col-4">
            <div className="w-full">
               <div className="w-full">{props.trip.Dealership2.dealershipName ?? "Private address"}</div>
               <div className="w-full">{props.trip.Dealership2.streetAddress}</div>
               <div className="w-full">{props.trip.Dealership2.city} {props.trip.Dealership2.stateAbbr} {props.trip.Dealership2.zipCode}</div>
            </div>
         </div>
         <div className="col-4">
            <div className="w-full">
               <div className="w-full">{props.trip.Dealership1.dealershipName ?? "Private address"}</div>
               <div className="w-full">{props.trip.Dealership1.streetAddress}</div>
               <div className="w-full">{props.trip.Dealership1.city} {props.trip.Dealership1.stateAbbr} {props.trip.Dealership1.zipCode}</div>
            </div>
         </div>
         </div>
         </>
   );
}