import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { PaymentStub } from './paymentStub';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import { Calendar } from 'primereact/calendar';

export const Payables = () => {
   const [payables, setPayables] = useState([]);
   const [payUser, setPayUser] = useState(0);
   const [payNote, setPayNote] = useState('');
   const [stubToShow, setStubToShow] = useState();
   const [showStub, setShowStub] = useState(false);
   const [showPay, setShowPay] = useState(false);
   const [init, setInit] = useState(false);
   const printRef = useRef(null);

   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   useEffect(() => {
      if (!init) {
         setInit(true);
         getPayables();
      }
   }, []);

   const getPayables = () => {
      var apiUrl = `${API_Root}api/invoice/GetDispersed`;
      axios.get(apiUrl).then((values) => {
         console.log('payables', values.data);
         setPayables(values.data);
      });
   }

   const showMarkPaid = (id) => {
      setPayNote('');
      setPayUser(id);
      setShowPay(true);
   }

   const markAsPaid = () => {
      var apiUrl = `${API_Root}api/invoice/CreatePayment?userId=${payUser}&note=${payNote}`;
      axios.get(apiUrl).then((values) => {
         setShowPay(false);
         getPayables();
      });
   }

   const showStubDialog = (id) => {
      setStubToShow(id);
      setShowStub(true);
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
   }

   const formatLongDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: 'numeric',
         month: 'long',
         year: 'numeric'
      });
   }

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   //const vehicleTemplate = (rowData) => {
   //   return (<div>
   //      <div className="ml-6 mr-6 mt-2"><span className="font-bold">{formatDate(rowData.Trip.requestedDeliveryDateTime)}</span> {v.vehicleYear} {v.vehicleMake} {v.vehicleModel}</div>
   //   </div>);
   //}

   //const driverNameTemplate = (rowData) => {
   //   return (
   //      <div className="w-full">
   //         <div className="w-full text-center">
   //            <div className="w-full">{rowData.User.firstName} {rowData.User.lastName}</div>
   //         </div>
   //      </div>
   //   );
   //};

   //const expenseTemplate = (rowData) => {
   //   let total = rowData.expenseTotal;

   //   return (
   //         <div className="w-full text-center">
   //            <div className="w-full">{formatter.format(total)}</div>
   //         </div>
   //   );
   //};

   //const mileageTemplate = (rowData) => {
   //   let total = rowData.mileageTotal * 0.9;

   //   return (
   //      <div className="w-full text-center">
   //         <div className="w-full">{formatter.format(total)}</div>
   //      </div>
   //   );
   //};

   //const serviceFeeTemplate = (rowData) => {
   //   let total = rowData.mileageTotal * 0.1;

   //   return (
   //      <div className="w-full text-center">
   //         <div className="w-full">{formatter.format(total)}</div>
   //      </div>
   //   );
   //};

   //const actionTemplate = (rowData) => {
   //   let amt = rowData.expenseTotal + (rowData.mileageTotal * 0.9);
   //   return (
   //      <div className="w-full">
   //         <div className="w-full text-right">
   //            <Button className="p-2" icon="pi pi-check-square" link onClick={() => showMarkPaid(rowData.id, amt)} />
   //         </div>
   //      </div>
   //   );
   //};

   //const itemTemplate = (item) => {
   //   return <div className="flex justify-content-between flex-wrap flex-column xl:flex-row xl:align-items-start p-4 gap-4">
   //      <div>
   //         {driverNameTemplate(item)}
   //      </div>
   //      <div>
   //         {vehicleTemplate(item)}
   //      </div>
   //      <div>
   //         {expenseTemplate(item)}
   //      </div>
   //      <div>
   //         {mileageTemplate(item)}
   //      </div>
   //      <div>
   //         {serviceFeeTemplate(item)}
   //      </div>
   //      <div>
   //         {actionTemplate(item)}
   //      </div>
   //   </div>
   //}

   const itemTemplate = (item) => {
      let label = "Pay " + formatter.format(item.selAmount);
      return <div className="">
         <div className="grid m-3 border-round-md border-2 border-50 border-solid surface-400 p-2 font-bold text-xl">
            <div className="col-9">
               {item.firstName} {item.lastName}
            </div>
            <div className="col-3"><Button label={label} onClick={() => showMarkPaid(item.id)}></Button></div>
         </div>
         <div>
            {chargesTemplate("", <span className="font-bold">Expenses</span>, <span className="font-bold">Mileage</span>, <span className="font-bold">Fee</span>, 0)}
            {item.selVehicles?.map((t, i) => {
               return vehicleTemplate(t, i);
            })}
         </div>
      </div>
   }

   const vehicleTemplate = (v, i) => {
      let desc = `${formatDate(v.Trip.requestedDeliveryDateTime)} ${v.Trip.Dealership2.city}, ${v.Trip.Dealership2.stateAbbr} to ${v.Trip.Dealership1.city}, ${v.Trip.Dealership1.stateAbbr}`;
      let exp = formatter.format(v.ExpenseCost);
      let mil = formatter.format(v.MileageCost * 0.9);
      let fee = formatter.format(v.MileageCost * 0.1);

      return (<div key={i}>
         {chargesTemplate(desc, exp, mil, fee, 0)}
      </div>);
   }

   const chargesTemplate = (desc, exp, mil, fee, i) => {
      return (<div key={i} className="grid ml-6 mr-6">
         <div className="col-6">{desc}</div>
         <div className="col-2">{exp}</div>
         <div className="col-2">{mil}</div>
         <div className="col-2">{fee}</div>
      </div>);
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="card">
               <DataScroller value={payables} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Payment Stub" visible={showStub} style={{ width: '65vw' }} onHide={() => setShowStub(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PaymentStub ref={printRef} paymentId={stubToShow}></PaymentStub>
         </Dialog>
         <Dialog header="Pay Driver" visible={showPay} style={{ width: '25vw' }} onHide={() => setShowPay(false)}>
            <div className="grid mt-4">
               <div className="col-5">
                  <input id="paidnote"
                     placeholder="Payment note"
                     value={payNote}
                     onChange={(e) => setPayNote(e.target.value)}
                     className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
               </div>
            </div>
            <div className="w-full text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => markAsPaid()} />
            </div>
         </Dialog>
      </>
   );
}