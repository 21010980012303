import React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { PorterSchedule } from './components/PorterSchedule';
import { CreateTripComponent } from './components/CreateTrip';
import { TripListDealership } from './components/TripList';
import { TripList } from './components/trip/TripList';
import { TripHistory } from './components/trip/TripHistory';
import { DealerAdmin } from './components/DealerAdmin';
import { SuperAdmin } from './components/SuperAdmin';
import { ForgotPassword } from './auth/ForgotPassword';
import { PrivateRoute } from './auth/PrivateRoute';
import { Login } from './auth/Login';
import { UserConfirm } from './components/UserConfirm';
import { SetPassword } from './components/SetPassword';
import { Home } from './components/Home';
import { TripInfo } from './components/TripInfo';
import { TripDetails } from './components/TripDetails';
import { InvoicePrint } from './components/billing/InvoicePrint';
import { AvailableTrips } from './components/driver/AvailableTrips';
import { BillingAdmin } from './components/billing/BillingAdmin';
import useGlobalState, { g_user, API_Root } from "./globalState";
import { useCookies } from "react-cookie";
import { Notifications } from 'react-push-notification';
import axios from 'axios'

import './custom.css'

export default function App() {

        //<Route exact path='/' component={Home} />
        //<Route path='/create-trip' component={CreateTripComponent} />
        //<Route path='/fetch-data' component={FetchData} />
        //<Route path='/dealership-trip-list' component={TripListDealership} />
        //  <PublicRoute restricted={false} component={Home} path="/" exact />
        //  <PublicRoute restricted={true} component={SignIn} path="/signin" exact />
        //  <PrivateRoute component={Dashboard} path="/dashboard" exact />


   return (
       <div>
          <Notifications />
      <Switch>
          <Route component={TripInfo} path="/trip" exact />
          <Route component={TripDetails} path="/td" exact />
            <Route component={InvoicePrint} path="/i" exact />
          <Route component={WithLayout} path="/" />
         </Switch>
         </div>
    )
}

function WithLayout() {
   const [user, setUser] = useGlobalState(g_user);
   const isAuthenticated = (user !== undefined && user !== null); // Replace with actual authentication check
   const [cookies,] = useCookies(["user"]);
   console.log('app user', user);
   console.log('app auth', isAuthenticated);

   const getLoggedInUser = (id) => {
      if (id !== "undefined") {
         var apiUrl = `${API_Root}api/user/GetLoggedInUser?uid=${id}`;

         axios.get(apiUrl).then((values) => {
            var success = values.data != null;
            if (success) {
               setUser(values.data);
            } 
         });
      }
   }

   if (!user && cookies.user) {
      getLoggedInUser(cookies.user);
   }

   return (
      <Layout>
         <Notifications />
         <Route component={Home} path="/" exact />
         <Route component={Login} path="/login" exact />
         <Route component={UserConfirm} path="/confirm" exact />
         <Route component={ForgotPassword} path="/reset" exact />
         <PrivateRoute component={CreateTripComponent} path="/create-trip" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={TripListDealership} path="/dealership-trip-list" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={TripList} path="/trip-list" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={TripHistory} path="/trip-history" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={PorterSchedule} path="/schedule" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={DealerAdmin} path="/dealer-admin" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={SuperAdmin} path="/sa" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={AvailableTrips} path="/available-trips" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={BillingAdmin} path="/billing" isAuthenticated={isAuthenticated} exact />
         <PrivateRoute component={SetPassword} path="/password" isAuthenticated={isAuthenticated} exact />
      </Layout>
   )
}