import React, { useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import { API_Root } from "../../globalState";
import axios from 'axios'

export const TripVehicle = ({ value, onChange }) => {
   const [vin, setVin] = React.useState();
   const [make, setMake] = React.useState();
   const [model, setModel] = React.useState();
   const [year, setYear] = React.useState();
   const [notes, setNotes] = React.useState('');
   const [color, setColor] = React.useState();
   const [vehicleTypes, setVehicleTypes] = React.useState([]);
   const [vehicleType, setVehicleType] = React.useState();
   const [chaseCar, setChaseCar] = React.useState();

   const getVehicleTypes = () => {
      var apiUrl = `${API_Root}api/trip/GetVehicleTypeList`;
      axios.get(apiUrl).then((values) => {
         console.log('vehicle types', values);
         setVehicleTypes(values.data);
      });
   }

   const getVINInfo = () => {
      var apiUrl = `${API_Root}api/trip/GetVehicleInfo?vin=${vin}`;
      axios.get(apiUrl).then((values) => {
         console.log('vehicle info', values);
         var vehicleInfo = values.data;

         setMake(vehicleInfo.make);
         setModel(vehicleInfo.model);
         setYear(vehicleInfo.year);
         setNotes(vehicleInfo.notes);

      });
   }

   const handleVINKeyDown = (event) => {
      if (event.key === 'Enter') {
         getVINInfo();
      }
   }

   useEffect(() => {
      getVehicleTypes();
   }, []);

   useEffect(() => {
      console.log('veh value', value);
      setVin(value.vehicleVIN);
      setMake(value.vehicleMake);
      setModel(value.vehicleModel);
      setYear(value.vehicleYear);
      setNotes(value.vehicleNotes);

      if (value.VehicleType) {
         setVehicleType(value.VehicleType);
      }

   }, [value]);

   useEffect(() => {
      let dto = {
         vehicleVIN: vin,
         vehicleMake: make,
         vehicleModel: model,
         vehicleYear: year,
         vehicleNotes: notes,
         tripVehicleId: vehicleType?.id
      }
      onChange(dto);
   }, [vin, make, model, year, notes, vehicleType]);

   const getVINClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((vin && vin.length > 10) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getMakeClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((make && make.length > 0) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   const getModelClass = () => {
      let doValidate = vehicleType && vehicleType.validateInfo;

      return ((model && model.length > 0) || (!doValidate)) ?
         "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" :
         "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   }

   return (
      <>
         <div className="p-2 border-1 border-400">
            <div className="field w-24rem lg:w-30rem">
               <label htmlFor="vehicletype">Type</label>
               <div className="grid">
                  <div className="field col-12">
                     <Dropdown
                        value={vehicleType}
                        onChange={(e) => setVehicleType(e.value)}
                        options={vehicleTypes}
                        optionLabel="typeName"
                        placeholder="Select a vehicle type"
                        className="w-24rem"
                        panelClassName="w-24rem" />
                  </div>
               </div>
            </div>
            <div className="field w-24rem lg:w-30rem">
               <label htmlFor="vin">VIN</label>
               <div className="grid">
                  <div className="field col-8">
                     <InputText id="vin"
                        value={vin ?? ""}
                        onChange={(e) => setVin(e.target.value)}
                        onKeyDown={handleVINKeyDown}
                        className={getVINClass()} />
                  </div>
                  <div className="col-4">
                     <Button className="p-2" icon="pi pi-search" onClick={() => getVINInfo()} />
                  </div>
               </div>
            </div>
            <div className="grid">
               <div className="col-6">
                  <div className="field">
                     <label htmlFor="make">Make</label>
                     <input id="make"
                        value={make ?? ""}
                        onChange={(e) => setMake(e.target.value)}
                        className={getMakeClass()} />
                  </div>
               </div>
               <div className="col-6">
                  <div className="field">
                     <label htmlFor="model">Model</label>
                     <input id="model"
                        value={model ?? ""}
                        onChange={(e) => setModel(e.target.value)}
                        className={getModelClass()} />
                  </div>
               </div>
            </div>
            <div className="grid">
               <div className="col-6">
                  <div className="field">
                     <label htmlFor="year">Year</label>
                     <input id="year"
                        value={year ?? ""}
                        onChange={(e) => setYear(e.target.value)}
                        className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                  </div>
                  <div className="field">
                     <label htmlFor="color">Color</label>
                     <input id="color"
                        value={color ?? ""}
                        onChange={(e) => setColor(e.target.value)}
                        className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                  </div>
               </div>
               <div className="col-6">
                  <div className="field">
                     <label htmlFor="notes">Notes</label>
                     <div>
                        <InputTextarea autoResize
                           id="notes"
                           value={notes ?? ""}
                           onChange={(e) => setNotes(e.target.value)}
                           rows={5}
                           cols={60} className="w-full" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>);

};