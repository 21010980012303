import React from 'react';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import useGlobalState, { g_user, API_Root } from "../globalState";
import axios from 'axios'
import { useRef } from 'react';


export const SetPassword = (props) => {
   const [password, setPassword] = React.useState('');
   const [passConfirm, setPassConfirm] = React.useState('');
   const [init, setInit] = React.useState(false);
   const [messages, setMessages] = React.useState([]);
   const [showValidation, setShowValidation] = React.useState(false);
   const [user, setUser] = useGlobalState(g_user);

   const toast = useRef(null);

   const saveProfile = () => {

      if (!validateSubmit()) {
         return;
      }

      var temp = user;

      temp.user.pass = password;

      var apiUrl = `${API_Root}api/user/Save`;
      axios.post(apiUrl, temp.user).then((values) => {
         setUser(temp);

         if (props.onClose) {
            props.onClose();
         }
      });
   }

   const validateSubmit = () => {
      var msgs = [];
      if (password.length > 0) {
         if (password.length < 7) {
            msgs.push('Password must be at least 7 characters long');
         }
         if (password !== passConfirm) {
            msgs.push('Confirmation does not match the password entered');
         }
      }

      if (msgs.length > 0) {
         setMessages(msgs);
         setShowValidation(true);
         return false;
      }
      else {
         return true;
      }
   }

   const getMessages = () => {
      if (messages.length > 0) {
         return <>
            <div>Please correct the following issues before saving</div>
            <div>
               <ul className="text-red-400">
                  {messages.map((m) => <li>{m}</li>)}
               </ul>
            </div>
         </>
      }
      else {
         return <></>;
      }
   }

   if (!init) {
      setInit(true);
   }

   const normalStyle = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
   const invalidStyle = "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";

   const getPasswordField = () => {
      if (password && password.length > 7) {
         return (<Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={true} toggleMask />);
      } else {
         return (<Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={true} inputClassName="border-red-400" toggleMask />);
      }
   }

   const getConfirmPasswordField = () => {
      if (passConfirm && passConfirm.length > 7 && passConfirm === password) {
         return (<Password id="confirmpassword" value={passConfirm} onChange={(e) => setPassConfirm(e.target.value)} feedback={false} toggleMask />);
      } else {
         return (<Password id="confirmpassword" value={passConfirm} onChange={(e) => setPassConfirm(e.target.value)} feedback={false} inputClassName="border-red-400" toggleMask />);
      }
   }

   const getPasswordSection = () => {
      return (<>
         <div className="grid mt-2">
            <div className="col-6 field">
               <label htmlFor="password">Password</label>
               <div>
                  {getPasswordField()}
               </div>
            </div>
            <div className="col-6 field">
               <label htmlFor="confirmpassword">Confirm Password</label>
               <div>
                  {getConfirmPasswordField()}
               </div>
            </div>
         </div>
      </>);
   }

   return (
      <>
         <Toast ref={toast} />

         {getPasswordSection()}
         <Divider />
         <div className="grid">
            <div className="col-7">
            </div>
            <div className="col-5 text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => saveProfile()} />
            </div>
         </div>
         <Dialog header="Validation" visible={showValidation} style={{ width: '30vw' }} onHide={() => setShowValidation(false)}>
            {getMessages()}
            <div className="col-5 text-right">
               <Button label="Close" onClick={() => setShowValidation(false)} />
            </div>
         </Dialog>

      </>);
};