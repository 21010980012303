import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { TripEntry } from './TripEntry';
import { TripWizard } from './TripWizard';
import { AssignTrip } from '../AssignTrip';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from '../PrintTrip';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import 'primeicons/primeicons.css';

export const TripList = () => {
   const [trips, setTrips] = useState([]);
   const [showAdd, setShowAdd] = useState(false);
   const [showWizard, setShowWizard] = useState(false);
   const [showEdit, setShowEdit] = useState(false);
   const [tripToEdit, setTripToEdit] = useState(0);
   const [showAssign, setShowAssign] = useState(false);
   const [tripToAssign, setTripToAssign] = useState();
   const [vehicleToAssign, setVehicleToAssign] = useState();
   const [showPrint, setShowPrint] = useState(false);
   const [tripToPrint, setTripToPrint] = useState();
   const [dealerships, setDealerships] = useState(undefined);
   const [curDealership, setCurDealership] = useState(undefined);
   const [init, setInit] = useState(false);
   const [user,] = useGlobalState(g_user);
   const printRef = useRef(null);
   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });

   const getDealerships = () => {
      var dealerArr = [];

      user.adminDealerships.forEach((d) => {
         dealerArr.push({ id: d.id, name: d.dealershipName, admin: true, porter: false });
      });

      user.porterDealerships.forEach((d) => {
         var match = undefined;
         dealerArr.forEach((a) => {
            if (a.id === d.id) {
               match = a;
            }
         });

         if (match !== undefined) {
            match.porter = true;
         } else {
            dealerArr.push({ id: d.id, name: d.dealershipName, admin: false, porter: true });
         }
      });

      setDealerships(dealerArr);
      if (dealerArr.length > 0) {
         setCurDealership(dealerArr[0]);
         getTripsForDealership(dealerArr[0].id);
      }
   }

   useEffect(() => {
      if (!init) {
         setInit(true);
         getDealerships();
      }
   }, []);

   const getDealershipDropdown = () => {
      if (dealerships !== undefined && dealerships.length === 1) {
         return <div className="text-lg md:text-3xl">Trips For {dealerships[0].name}</div>;
      }
      else if (dealerships !== undefined && dealerships.length > 1) {
         return <div className="grid">
            <div className="col-2 text-right mr-3 mt-2">
               <label htmlFor="dealerships">Trips For</label>
            </div>
            <div className="col-8">
            <Dropdown value={curDealership} onChange={(e) => selectDealership(e.value)} options={dealerships} optionLabel="name"
                  placeholder="Select a dealership" className="" />
            </div>
         </div>;
      }
      else {
         return <div></div>;
      }
   }

   const selectDealership = (sel) => {
      setCurDealership(sel);
      getTripsForDealership(sel.id);
   }

   const getTripsForDealership = (id) => {
      var apiUrl = `${API_Root}api/trip/GetTripsForDealership?id=${id}`;
      axios.get(apiUrl).then((values) => {
         console.log('trips', values.data);
         setTrips(values.data);
      });
   }

   const removeTripPorter = (vehicleId) => {
      var apiUrl = `${API_Root}api/trip/RemoveTripPorter?vehicleId=${vehicleId}`;
      axios.get(apiUrl).then((values) => {
         getTripsForDealership(curDealership.id);
      });
   }

   const confirmDeleteTrip = (id) => {
      confirmDialog({
         message: 'Are you sure you want to delete this trip?',
         header: 'Confirmation',
         icon: 'pi pi-exclamation-triangle',
         accept: () => deleteTrip(id),
         reject: null
      });
   }

   const deleteTrip = (id) => {
      var apiUrl = `${API_Root}api/trip/DeleteTrip?id=${id}`;
      axios.get(apiUrl).then((values) => {
         getTripsForDealership(curDealership.id);
      });
   }

   const routeTemplate = (rowData) => {
      return (
         <div className="w-full">
            <div className="w-full text-center">{rowData.Dealership2.city}, {rowData.Dealership2.stateAbbr} to {rowData.Dealership1.city}, {rowData.Dealership1.stateAbbr}</div>
            <div className="w-full text-center">{rowData.estimatedDistance} / {rowData.estimatedDuration}</div>
         </div>
      );
   };

   const newTripAdded = () => {
      setShowAdd(false);
      setShowEdit(false);
      setShowWizard(false);
      getTripsForDealership(curDealership.id);
   }

   const assignPorter = (t, v) => {
      setTripToAssign(t);
      setVehicleToAssign(v);
      setShowAssign(true);
   }

   const porterAssigned = () => {
      setShowAssign(false);
      getTripsForDealership(curDealership.id);
   }

   const printTrip = (t) => {
      setTripToPrint(t);
      setShowPrint(true);
   }

   const requestedDateTimeTemplate = (rowData) => {
      var d = new Date(rowData.requestedDeliveryDateTime);
      var formattedDate = d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric',
         hour: '2-digit',
         minute: '2-digit',
         hour12: true
      });
      let cnt = rowData.TripVehicles.length;

      return (
         <div className="">
            <div className="w-full font-bold">{formattedDate}</div>
            <div className="w-full font-italic font-semibold">{cnt} vehicles</div>
         </div>
      );

   };

   const portersTemplate = (rowData) => {

      return rowData.TripVehicles.map((s, i) => {
         let veh = s.vehicleYear + ' ' + s.vehicleMake + ' ' + s.vehicleModel;
         if (!s.vehicleYear && !s.vehicleMake && !s.vehicleModel) {
            veh = "Unspecified Vehicle";
         }
         let label = s.User != null ? s.User.firstName + ' ' + s.User.lastName : "Driver needed";
         let classes = "w-22rem pr-4 pl-4 relative w-full border-round-xl border-1 text-right mb-1 shadow-2 opacity-80 font-bold text-0 text-sm " + (s.userId != null ? "surface-500" : !s.confirmed ? "bg-yellow-600" : !s.isChase ? "bg-green-600" : "cursor-pointer bg-blue-600");
         let dlink = s.User != null ? <i className="pi pi-times-circle absolute right-0 mt-1 mr-1 text-white cursor-pointer text-sm" onClick={() => removeTripPorter(s.id)}></i> : <></>;

         let icon = <></>;
         if (s.VehicleType?.roundTrip) {
            icon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-right-arrow-left"></i>;
         } else if (s.VehicleType?.fromOrigin) {
            icon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-right"></i>;
         } else {
            icon = <i className="text-800 absolute mt-1 ml-1 left-0 pi pi-arrow-left"></i>;
         }

         if (s.User != null) {
            return (<div key={i} className={classes}>{icon} {veh} - {label}{dlink}</div>)
         }
         else {
            return (<div key={i} className={classes} onClick={() => assignPorter(rowData, s)}>{icon} {veh} - {label}{dlink}</div>)
         }
      });

   }

   const needsPorter = (rowData) => {
      rowData.TripVehicles.forEach((v) => {
         if (v.userId === null) {
            return true;
         }
      });
      return false;
   }

   const editTrip = (id) => {
      setTripToEdit(id);
      setShowEdit(true);
   }

   const actionTemplate = (rowData) => {

      return (
         <div className="w-full">
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-trash" link onClick={() => confirmDeleteTrip(rowData.id)} />
               <Button className="p-2" icon="pi pi-file-edit" link onClick={() => editTrip(rowData.id)} />
               <Button className="p-2" icon="pi pi-print" link onClick={() => printTrip(rowData)} />
            </div>
         </div>
      );
   };

   const addButtonTemplate = () => {
      if (curDealership && curDealership.admin) {
         return <>
               <Button className="p-2 mr-4" label="Add Trip" icon="pi pi-plus-circle" link onClick={() => setShowWizard(true)} />
            </>;
      }
      else {
         return <div></div>;
      }
   }

   const itemTemplate = (item) => {
      return <div className="flex justify-content-between flex-wrap flex-column xl:flex-row xl:align-items-start p-4 gap-4">
         <div>
            {requestedDateTimeTemplate(item)}
         </div>
         <div>
            {routeTemplate(item)}
         </div>
         <div>
            {portersTemplate(item)}
         </div>
         <div>
            {actionTemplate(item)}
         </div>

      </div>
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="grid mt-2 ml-2">
               <div className="col-6">
                  {getDealershipDropdown()}
               </div>
               <div className="col-6 text-right">
                  {addButtonTemplate()}
               </div>
            </div>
            <div className="card">
               <DataScroller value={trips} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Create Trip Wizard" visible={showWizard} style={{ width: '75vw', height: '90vw' }} breakpoints={{ '960px': '90vw', '641px': '100vw' }} onHide={() => setShowWizard(false)}>
            <TripWizard onCancel={() => setShowWizard(false)} onCreated={newTripAdded} dealershipId={curDealership?.id} userId={user?.user?.id} userName={user?.user?.firstName + ' ' + user?.user?.lastName} userPhone={user?.user?.phone}></TripWizard>
         </Dialog>
         <Dialog header="Create Trip" visible={showAdd} style={{ width: '75vw' }} breakpoints={{ '960px': '90vw', '641px': '100vw' }} onHide={() => setShowAdd(false)}>
            <TripEntry onCreated={newTripAdded} dealershipId={curDealership?.id} userId={user?.user?.id} userName={user?.user?.firstName + ' ' + user?.user?.lastName} userPhone={user?.user?.phone}></TripEntry>
         </Dialog>
         <Dialog header="Edit Trip" visible={showEdit} style={{ width: '75vw', height: '90vw' }} breakpoints={{ '960px': '90vw', '641px': '100vw' }} onHide={() => setShowEdit(false)}>
            <TripWizard onCancel={() => setShowEdit(false)} onCreated={newTripAdded} dealershipId={curDealership?.id} userId={user?.user?.id} tripId={tripToEdit}></TripWizard>
         </Dialog>
         <Dialog header="Assign Porter" visible={showAssign} style={{ width: '65vw' }} onHide={() => setShowAssign(false)}>
            <AssignTrip onAssign={porterAssigned} trip={tripToAssign} vehicle={vehicleToAssign}></AssignTrip>
         </Dialog>
         <Dialog header="Print Trip" visible={showPrint} style={{ width: '65vw' }} onHide={() => setShowPrint(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PrintTrip ref={printRef} trip={tripToPrint} dealer={curDealership}></PrintTrip>
         </Dialog>
      </>
   );
}