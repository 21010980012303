import React, { useRef, useState, useEffect } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Tag } from 'primereact/tag';
import { DataScroller } from 'primereact/datascroller';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { PrintTrip } from '../PrintTrip';
import { useReactToPrint } from 'react-to-print';
import axiosInterceptor from '../../AuthIntercept';
import axios from 'axios'
import useGlobalState, { g_user, API_Root } from "../../globalState";
import { Calendar } from 'primereact/calendar';
import { PaymentStub } from './paymentStub';

export const PaymentHistory = () => {
   const [payments, setPayments] = useState([]);
   const [showPrint, setShowPrint] = useState(false);
   const [paymentToPrint, setPaymentToPrint] = useState();
   const [init, setInit] = useState(false);
   const [user,] = useGlobalState(g_user);
   const [viewDate, setViewDate] = useState();
   const printRef = useRef(null);

   const handlePrint = useReactToPrint({
      content: () => printRef.current,
   });


   useEffect(() => {
      if (!init) {
         setInit(true);
         setViewDate(new Date);
      }
   }, []);

   useEffect(() => {
      if (viewDate) {
         getPaymentHistory();
      }
   }, [viewDate]);

   const getPaymentHistory = () => {
      let dt = new Date();
      if (viewDate) {
         dt = viewDate;
      }

      var apiUrl = `${API_Root}api/invoice/GetPayments?month=${dt.getMonth()}&year=${dt.getFullYear()}`;
      axios.get(apiUrl).then((values) => {
         console.log('payments', values.data);
         setPayments(values.data);
      });
   }

   const routeTemplate = (rowData) => {
      return (
         <div className="w-full text-center">{rowData.Dealership2.city}, {rowData.Dealership2.stateAbbr} to {rowData.Dealership1.city}, {rowData.Dealership1.stateAbbr}</div>
      );
   };

   const printPayment = (t) => {
      setPaymentToPrint(t);
      setShowPrint(true);
   }

   const formatDate = (dt) => {
      var d = new Date(dt);
      return d.toLocaleDateString('en-US', {
         day: '2-digit',
         month: '2-digit',
         year: 'numeric'
      });
   }

   const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
   });

   const actionTemplate = (rowData) => {

      return (
         <div className="w-full">
            <div className="w-full text-right">
               <Button className="p-2" icon="pi pi-print" link onClick={() => printPayment(rowData.id)} />
            </div>
         </div>
      );
   };

   const addButtonTemplate = () => {
      return <Calendar className="mr-3" value={viewDate} onChange={(e) => setViewDate(e.value)} view="month" dateFormat="MM yy" />;
   }

   const itemTemplate = (item) => {
      return <div className="grid text-left">
         <div className="font-bold col-2">
            {formatDate(item.paymentDate)}
         </div>
         <div className="font-bold col-6">
            {item.User.firstName} {item.User.lastName}
         </div>
         <div className="col-2">
            {formatter.format(item.paymentTotal)}
         </div>
         <div className="col-2">
            {actionTemplate(item)}
         </div>

      </div>
   }

   //<Column header="Status" style={{ minWidth: '12rem' }} body={statusTemplate} />
   return (
      <>
         <ConfirmDialog />
         <div className="card">
            <div className="grid mt-2 ml-2">
               <div className="col-6">
               </div>
               <div className="col-6 text-right">
                  {addButtonTemplate()}
               </div>
            </div>
            <div className="card">
               <DataScroller value={payments} itemTemplate={itemTemplate} rows={30} buffer={0.4} header="" />
            </div>
         </div>
         <Dialog header="Payment Stub" visible={showPrint} style={{ width: '65vw' }} onHide={() => setShowPrint(false)}>
            <div className="w-full text-right mt-2"><Button className="p-2" label="Print" icon="pi pi-print" onClick={() => handlePrint()} /></div>
            <PaymentStub ref={printRef} paymentId={paymentToPrint}></PaymentStub>
         </Dialog>
      </>
   );
}