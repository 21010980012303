import React from 'react';
import { Divider } from 'primereact/divider';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import 'primeflex/primeflex.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import useGlobalState, { g_user, API_Root } from "../globalState";
import axios from 'axios'
import { useRef } from 'react';


export const UserProfile = (props) => {
   const [firstName, setFirstName] = React.useState();
   const [email, setEmail] = React.useState();
   const [lastName, setLastName] = React.useState();
   const [phone, setPhone] = React.useState();
   const [streetAddress, setStreetAddress] = React.useState();
   const [city, setCity] = React.useState();
   const [state, setState] = React.useState();
   const [zip, setZip] = React.useState();
   const [states, setStates] = React.useState();
   const [changedPassword, setChangePassword] = React.useState(false);
   const [password, setPassword] = React.useState('');
   const [passConfirm, setPassConfirm] = React.useState('');
   const [showValidation, setShowValidation] = React.useState(false);
   const [init, setInit] = React.useState(false);
   const [messages, setMessages] = React.useState([]);
   const [user, setUser] = useGlobalState(g_user);

   const toast = useRef(null);

   const saveProfile = () => {

      if (!validateSubmit()) {
         return;
      }

      var temp = user;

      temp.user.email = email;
      temp.user.firstName = firstName;
      temp.user.lastName = lastName;
      temp.user.phone = phone;
      temp.user.pass = password;
      temp.user.streetAddress = streetAddress;
      temp.user.city = city;
      temp.user.stateAbbr = state.abbr;
      temp.user.zipCode = zip;

      var apiUrl = `${API_Root}api/user/Save`;
      axios.post(apiUrl, temp.user).then((values) => {
         setUser(temp);

         if (props.onClose) {
            props.onClose();
         }
      });
   }

   const validateSubmit = () => {
      var msgs = [];
      if (!validateEmail(email)) {
         msgs.push('Email does not appear to be valid');
      }
      if (password.length > 0) {
         if (password.length < 7) {
            msgs.push('Password must be at least 7 characters long');
         }
         if (password !== passConfirm) {
            msgs.push('Confirmation does not match the password entered');
         }
      }
      if (!firstName || firstName.length < 1) {
         msgs.push('Please provide a first name');
      }
      if (!lastName || lastName.length < 1) {
         msgs.push('Please provide a last name');
      }
      if (!phone || phone.length < 14) {
         msgs.push('Please provide a complete phone number');
      }

      if (!streetAddress || streetAddress.length === 0) {
         msgs.push('Please provide a street address');
      }

      if (!city || city.length === 0) {
         msgs.push('Please provide a city');
      }

      if (!state || state.length === 0) {
         msgs.push('Please select a state');
      }

      if (!zip || zip.length === 0) {
         msgs.push('Please provide a zip code');
      }

      if (msgs.length > 0) {
         setMessages(msgs);
         setShowValidation(true);
         return false;
      }
      else {
         return true;
      }
   }

   const getMessages = () => {
      if (messages.length > 0) {
         return <>
            <div>Please correct the following issues before saving</div>
            <div>
               <ul className="text-red-400">
                  {messages.map((m) => <li>{m}</li>)}
               </ul>
            </div>
         </>
      }
      else {
         return <></>;
      }
   }

   const validateEmail = (e) => {
      return String(e)
         .toLowerCase()
         .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
         );
   };

   const getStates = () => {
      var apiUrl = `${API_Root}api/trip/GetStateList`;
      axios.get(apiUrl).then((value) => {
         let s = value.data;
         setStates(s);
         for (let i = 0; i < s.length; i++) {
            if (s[i].abbr == user.user.stateAbbr) {
               setState(s[i]);
               break;
            }
         }
         
      });
   }

   if (!init) {
      getStates();
      setEmail(user.user.email);
      setFirstName(user.user.firstName);
      setLastName(user.user.lastName);
      setPhone(user.user.phone);
      setStreetAddress(user.user.streetAddress);
      setCity(user.user.city);
      setZip(user.user.zipCode);
      setInit(true);
   }

   const normalStyle = "text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full";
   const invalidStyle = "border-red-400 text-base text-color surface-overlay p-2 border-1 border-solid border-round appearance-none outline-none w-full";
   const getEmailField = () => {
      if (email && email.length > 0 && validateEmail(email)) {
         return (<input id="email" value={email} onChange={(e) => setEmail(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="email" value={email} onChange={(e) => setEmail(e.target.value)} className={invalidStyle} />);
      }
   }

   const getFirstNameField = () => {
      if (firstName && firstName.length > 0) {
         return (<input id="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="firstname" value={firstName} onChange={(e) => setFirstName(e.target.value)} className={invalidStyle} />);
      }
   }

   const getLastNameField = () => {
      if (lastName && lastName.length > 0) {
         return (<input id="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="lastname" value={lastName} onChange={(e) => setLastName(e.target.value)} className={invalidStyle} />);
      }
   }

   const getAddressField = () => {
      if (streetAddress && streetAddress.length > 0) {
         return (<input id="streetaddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="streetaddress" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} className={invalidStyle} />);
      }
   }

   const getCityField = () => {
      if (city && city.length > 0) {
         return (<input id="city" value={city} onChange={(e) => setCity(e.target.value)} className={normalStyle} />);
      } else {
         return (<input id="city" value={city} onChange={(e) => setCity(e.target.value)} className={invalidStyle} />);
      }
   }

   const getStateField = () => {
      if (state) {
         return (<Dropdown value={state} onChange={(e) => setState(e.value)} options={states} optionLabel="abbr" />);
      } else {
         return (<Dropdown value={state} onChange={(e) => setState(e.value)} options={states} optionLabel="abbr" className="border-red-400" />);
      }
   }

   const getZipField = () => {
      if (zip && zip.length === 5) {
         return (<InputMask id="zip" mask="99999" value={zip} onChange={(e) => setZip(e.target.value)} className={normalStyle} />);
      } else {
         return (<InputMask id="zip" mask="99999" value={zip} onChange={(e) => setZip(e.target.value)} className={invalidStyle} />);
      }
   }

   const getPhoneField = () => {
      if (phone && phone.length === 14) {
         return (<InputMask id="phone" mask="(999) 999-9999" value={phone} onChange={(e) => setPhone(e.target.value)} className={normalStyle} />);
      } else {
         return (<InputMask id="phone" mask="(999) 999-9999" value={phone} onChange={(e) => setPhone(e.target.value)} className={invalidStyle} />);
      }
   }

   const getPasswordField = () => {
      if (password && password.length > 7) {
         return (<Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={true} toggleMask />);
      } else {
         return (<Password id="password" value={password} onChange={(e) => setPassword(e.target.value)} feedback={true} inputClassName="border-red-400" toggleMask />);
      }
   }

   const getConfirmPasswordField = () => {
      if (passConfirm && passConfirm.length > 7 && passConfirm === password) {
         return (<Password id="confirmpassword" value={passConfirm} onChange={(e) => setPassConfirm(e.target.value)} feedback={false} toggleMask />);
      } else {
         return (<Password id="confirmpassword" value={passConfirm} onChange={(e) => setPassConfirm(e.target.value)} feedback={false} inputClassName="border-red-400" toggleMask />);
      }
   }

   const getPasswordSection = () => {
      if (changedPassword) {
         return (<>
            <div className="w-full"><Checkbox onChange={e => setChangePassword(e.checked)} checked={changedPassword}></Checkbox><label className="ml-2">Set Password</label></div>
            <div className="grid mt-2">
               <div className="col-6 field">
                  <label htmlFor="password">Password</label>
                  <div>
                     {getPasswordField()}
                  </div>
               </div>
               <div className="col-6 field">
                  <label htmlFor="confirmpassword">Confirm Password</label>
                  <div>
                     {getConfirmPasswordField()}
                  </div>
               </div>
            </div>
         </>);
      }
      else {
         return (<div className="w-full"><Checkbox onChange={e => setChangePassword(e.checked)} checked={changedPassword}></Checkbox><label className="ml-2">Set Password</label></div>);
      }
   }

   return (
      <>
         <Toast ref={toast} />
         <div className="grid mt-3">
            <div className="col-6 field">
               <label htmlFor="firstname">First Name</label>
               {getFirstNameField()}
            </div>
            <div className="col-6 field">
               <label htmlFor="lastname">Last Name</label>
               {getLastNameField()}
            </div>
         </div>
         <div className="grid">
            <div className="col-6 field">
               <label htmlFor="email">Email</label>
               {getEmailField()}
            </div>
            <div className="col-6 field">
               <label htmlFor="phone">Phone</label>
               {getPhoneField()}
            </div>
         </div>
         <div className="grid">
            <div className="col-5 field">
               <label htmlFor="streetaddress">Street Address</label>
               {getAddressField()}
            </div>
            <div className="col-3 field">
               <label htmlFor="city">City</label>
               {getCityField()}
            </div>
            <div className="col-2 field">
               <label htmlFor="state">State</label>
               <div>
               {getStateField()}
               </div>
            </div>
            <div className="col-2 field">
               <label htmlFor="zip">Zip Code</label>
               {getZipField()}
            </div>
         </div>
         <Divider />
         {getPasswordSection()}
         <Divider />
         <div className="grid">
            <div className="col-7">
            </div>
            <div className="col-5 text-right">
               <Button label="Save" icon="pi pi-save" onClick={() => saveProfile()} />
            </div>
         </div>
         <Dialog header="Validation" visible={showValidation} style={{ width: '30vw' }} onHide={() => setShowValidation(false)}>
            {getMessages()}
            <div className="col-5 text-right">
               <Button label="Close" onClick={() => setShowValidation(false)} />
            </div>
         </Dialog>

      </>);
};